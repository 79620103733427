<script setup lang="ts">
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import CensorWordsTextarea from '@/areas/editor/views/effects/sections/curse-words/components/CensorWordsTextarea.vue'
import CensorWordsIcon from '@/components/Icons/CensorWordsIcon.vue'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import logging from '@/logging'
import { InfoIcon, Trash2Icon } from 'lucide-vue-next'
import BleepEffectDropdown from './components/BleepEffectDropdown.vue'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()
const { currentSection } = useEditorStep()

if (!captionsStore.hasGeneratedProfanity && !captionsStore.isGeneratingProfanity) {
  generateProfanity()
}

const bleepCurseWordsModel = computed({
  get: () => captionsStore.bleepCurseWords,
  set: (value: boolean) => {
    if (value) {
      const sound = captionsStore.curseWordSoundEffects
        .find((s) => s.id === captionsStore.bleepCurseWordSoundEffect)
      if (sound) {
        captionsStore.setCurseSoundEffect(sound)
      } else {
        captionsStore.setCurseSoundEffect(null)
      }
      captionsStore.bleepCurseWords = true
    } else {
      captionsStore.removeAllCurseSoundEffects()
      captionsStore.bleepCurseWords = false
    }
  }
})

function generateProfanity() {
  historyStore.transaction('CAPTIONS:TOGGLE_BLEEP_CURSE_WORDS', async () => {
    captionsStore.bleepCurseWords = true

    logging.trackEvent('Enabled Bleep Curse Words')

    if (!captionsStore.hasGeneratedProfanity) {
      await captionsStore.generateProfanity()
    }
  })
}

function turnOffCensorWords() {
  historyStore.transaction('CAPTIONS:TOGGLE_BLEEP_CURSE_WORDS', () => {
    captionsStore.deleteProfanity()
  })

  currentSection.value = null
}
</script>

<template>
  <div class="h-full min-h-0 overflow-y-auto lg:max-h-screen lg:pb-16">
    <div
      v-if="captionsStore.isGeneratingProfanity"
      class="flex h-full flex-col items-center gap-2 p-4 text-center 2xl:p-8"
    >
      <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
      <p>
        <strong>Censoring words...</strong>
      </p>
      <p class="my-2 font-light">This process might take up to 20 seconds depending on the length of your clip.</p>
      <div
        class="flex items-center justify-center gap-4 rounded-lg border border-current bg-emerald-500/5 p-4 text-sm font-light text-emerald-600 dark:bg-emerald-500/5 dark:text-emerald-500"
      >
        You can continue working on your project while we censor your words.
      </div>
    </div>

    <div v-else-if="captionsStore.hasGeneratedProfanity" class="flex flex-col p-4 2xl:p-8">
      <div class="min-h-0">
        <div
          class="flex cursor-pointer items-center gap-2 text-brand-state-link underline-offset-2 transition-transform hover:-translate-x-0.5 hover:underline md:mb-4"
          @click="currentSection = null"
        >
          <IconSaxArrowLeft class="h-4 w-4" />
          <span class="mt-0.5 select-none text-xl lg:text-sm">Back to Effects</span>
        </div>
      </div>

      <div class="flex flex-col gap-4">
        <div class="flex items-center justify-between gap-2">
          <div class="flex items-center gap-2">
            <div class="grid size-12 place-items-center rounded-xl bg-gradient-to-r from-[#FAE6FF] to-[#DAF1FF]">
              <CensorWordsIcon class="size-12" />
            </div>
            <div class="flex flex-col">
              <h3 class="font-semibold">
                AI - Word censoring
                <span class="rounded-full bg-green-700 px-2 py-1 text-xs font-medium text-background">Beta</span>
              </h3>
              <p class="text-xs font-light text-muted-foreground">Make your videos TikTok friendly.</p>
            </div>
          </div>

          <Switch v-model:checked="bleepCurseWordsModel" />
        </div>

        <div
          class="flex flex-row items-center gap-2 rounded-lg border border-blue-400 bg-sky-50 px-3 py-3 dark:border-blue-900 dark:bg-sky-700 dark:text-white"
        >
          <InfoIcon class="size-5 text-blue-600 dark:text-white" />
          <p class="text-sm font-light">AI helps, but review for TikTok safety</p>
        </div>

        <Transition
          enter-active-class="motion-safe:transition-[transform,_opacity]"
          :enter-from-class="captionsStore.bleepCurseWords ? 'opacity-0 -translate-y-6' : 'opacity-0 translate-y-6'"
          leave-active-class="motion-safe:transition-[transform,_opacity]"
          :leave-to-class="captionsStore.bleepCurseWords ? 'opacity-0 translate-y-6' : 'opacity-0 -translate-y-6'"
        >
          <div v-if="captionsStore.bleepCurseWords" class="flex flex-col gap-4">
            <div class="h-px bg-surface-panel-border" />

            <div class="flex w-full flex-col gap-1">
              <p class="text-base font-normal">Censor sound effect</p>
              <BleepEffectDropdown />
            </div>

            <div class="h-px bg-surface-panel-border" />

            <div class="flex flex-col">
              <p class="text-base font-normal">Transcription</p>
              <p class="text-xs font-light text-muted-foreground">Click on the words to censor or un-censor them.</p>
            </div>

            <CensorWordsTextarea />

            <div class="h-px bg-surface-panel-border" />

            <Button
              class="w-full border border-surface-panel-border text-sm font-light text-muted-foreground hover:border-brand-state-error-border hover:text-brand-state-error-text"
              variant="ghostDestructive"
              @click="turnOffCensorWords()"
            >
              Remove AI - censor words
              <Trash2Icon class="w-4" />
            </Button>
          </div>
        </Transition>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
