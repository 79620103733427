<script setup lang="ts">
import { Button } from '@/components/ui/button'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { Input } from '@/components/ui/input'
import DynamicPlanButtonWithTooltip from '@/components/Account/Upgrade/DynamicPlanButtonWithTooltip.vue'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import type { Effect } from '@/areas/editor/@type/Project'
import { v4 as uuid } from 'uuid'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import SoundEffect from '@/areas/editor/views/sounds/SoundEffect.vue'
import { capitalize, computed, onMounted, ref, watch } from 'vue'
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { sounds as importedSounds } from '@/areas/editor/views/sounds/sounds'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { orderBy, uniq } from 'lodash-es'
import IconSaxArrowRight2 from '@/components/Icons/iconsax/IconSaxArrowRight2.vue'
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import logging from '@/logging'
import { formatFileSize, mbToBytes } from '@/helpers/fileSize'
import toastEvents from '@/events/toastEvents'
import { useToast } from '@/Hooks/useToast'
import { canGuardWithPopup } from '@/Hooks/useGuard'
import { useUniverseUploadStore } from '@/areas/editor/store/useUniverseUploadStore'

defineProps<{
  hideBackButton?: boolean
}>();

type SoundEffectElement = {
  name: string;
  url: string;
  tags: string[];
};

const sounds = ref<SoundEffectElement[]>([]);

const foundSounds = ref<Effect<'sound'>[]>([]);

onMounted(async () => {

  sounds.value = importedSounds.map((sound) => {
    return {
      name: sound.title,
      url: sound.url,
      tags: sound.tags,
      maxDurationMs: sound.duration * 1e3
    };
  });

  await uploadStore.fetchListFiles();
});

const userInfoStore = useUserInfoStore();
const effectsStore = useEffectsStore();
const videoStore = useVideoStore();

const editorFocusStore = useEditorFocusStore();
const historyStore = useHistoryStore();

const addSoundEffect = (sound: Effect<'sound'>, durationSeconds: number) => {

  const soundDurationMs = durationSeconds * 1000;

  const startMs = sound.tags.includes('Full songs') ? 0 : videoStore.currentTimeMs;
  const endMs = Math.min(startMs + soundDurationMs, videoStore.durationMs);

  const id = uuid();

  effectsStore.createById<Effect<'sound'>>(id, {
    type: 'sound',
    name: sound.name,
    url: sound.url,
    tags: sound.tags,
    startMs: startMs,
    endMs: endMs,
    maxDurationMs: soundDurationMs,
    volume: 1.0,
  });

  logging.trackEvent('Editor Sound Effect Added', {
    name: sound.name,
    url: sound.url,
    tags: sound.tags,
    soundDurationMs: soundDurationMs,
  });

  editorFocusStore.setFocus(FocusTypes.SOUND, id)
};

const filteredSounds = computed(() => {
  if (selectedTag.value === 'all') {
    return [...sounds.value, ...uploadedFiles.value] as Effect<'sound'>[];
  } else {
    return [...sounds.value, ...uploadedFiles.value].filter(sound => sound.tags.includes(selectedTag.value)) as Effect<'sound'>[];
  }
});

const audioElements = ref<HTMLAudioElement[]>([]);
const stopOtherSounds = (newElement: HTMLAudioElement) => {

  audioElements.value = [
    ...audioElements.value,
    newElement,
  ];

  newElement.addEventListener('playing', () => {
    for (const audio of audioElements.value) {
      if (audio !== newElement) {
        audio.currentTime = 0;
        audio.pause();
      }
    }
  });
};

const tags = computed(() => {
  const allTags = orderBy(uniq(sounds.value.flatMap((sound) => sound.tags)));
  return [
    'all',
    'uploaded',
    ...allTags,
  ];
});

const selectedTag = ref('all');

const soundName = ref('');

const searchSounds = () => {
  const filteredName = soundName.value.toLowerCase().trim();
  if (filteredName.length > 0) {
    selectedTag.value = 'all';
    foundSounds.value = [...sounds.value, ...uploadedFiles.value]
      .filter((sound) => sound.name.toLowerCase().trim().includes(filteredName) || sound.tags.some((tag) => tag.toLowerCase().includes(filteredName))) as Effect<'sound'>[];
  }
};

watch(() => soundName.value, () => {
  searchSounds();
});

const isUploading = ref(false);
const soundsToDisplay = computed(() => (soundName.value.length > 0 ? foundSounds.value : filteredSounds.value));

async function uploadAudioFile(event: Event) {

  isUploading.value = true;
  const inputElement = event.target as HTMLInputElement;

  await tryUploadAudioFile(inputElement.files.item(0));
  await uploadStore.fetchListFiles();

  isUploading.value = false;
}

const { showToast } = useToast();

const uploadStore = useUniverseUploadStore();

const maxFileSize = mbToBytes(10);
async function tryUploadAudioFile(file: File | null) {

  if (!canGuardWithPopup('sounds')) {
    return;
  }

  if (!file) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'No file selected',
      subtitle: 'Please select a file to upload.',
      timeout: 10000,
    });
    return { error: null }
  }

  if (file.size > maxFileSize) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'File too large, please try another file',
      subtitle: `Max file size is ${formatFileSize(maxFileSize, 2)}.`,
      timeout: 10000,
    });
    logging.trackEvent('Editor Sound Effect Upload Failed', {
      reason: 'file too large',
      size: file.size,
      type: file.type,
    });
    return { error: null }
  }

  try {
    await uploadStore.uploadFile(file);
    showToast({
      type: toastEvents.TOAST_SUCCESS,
      title: 'Sound uploaded',
      subtitle: 'The sound has been uploaded successfully.',
      timeout: 4000,
    });
    logging.trackEvent('Editor Sound Effect Upload Success', {
      size: file.size,
      type: file.type,
    });
    return { error: null };
  } catch (e) {
    logging.trackEvent('Editor Sound Effect Upload Failed', {
      reason: 'upload failed',
      size: file.size,
      type: file.type,
    });
    console.error(e);
    Sentry.captureException('Sound Effect Upload Failed', e);
    return { error: null };
  }
}

const deleteSoundEffect = async (sound: Effect<'sound'>) => {
  await uploadStore.deleteFile(sound.id);
  showToast({
    type: toastEvents.TOAST_SUCCESS,
    title: 'Sound deleted',
    subtitle: 'The sound has been deleted.',
    timeout: 4000,
  });
};

const uploadedFiles = computed(() => uploadStore.uploadedSoundEffects);
</script>

<template>
  <div class="flex flex-col gap-4 p-4 pb-0 2xl:p-8 h-full sm:h-screen">

    <header>
      <div class="flex gap-1 items-center">
        <h2 class="text-xl font-semibold leading-snug">Audio</h2>
      </div>
      <p v-if="userInfoStore.tier === tiers.GOLD" class="font-light hidden md:block">Add multiple timed sounds or music to your clip</p>
      <p v-else class="font-light hidden md:flex gap-2">
        Add multiple timed sounds or music to your clip
        <GoldPlanButton v-if="userInfoStore.tier !== tiers.GOLD" class="pointer-events-none -mt-0.5" :can-click="false" :small="true" />
      </p>
    </header>

    <div class="flex flex-col gap-2">
      <div class="w-full min-w-0 overflow-x-auto overflow-y-hidden">
        <div class="flex flex-row gap-1 m-2">
          <RadioToggleButton
            v-for="tag in tags" :key="tag" :value="tag" v-model="selectedTag"
            size="sm" class="flex-col relative font-light data-[state=active]:font-semibold shrink-0"
          >
            <span class="absolute inset-auto">{{ capitalize(tag) }}</span>
            <span class="font-semibold invisible">{{ capitalize(tag) }}</span>
          </RadioToggleButton>
        </div>
      </div>
    </div>

    <Button v-if="selectedTag === 'all' || selectedTag === 'uploaded'" as="label" class="relative gap-2 bg-transparent hover:bg-transparent w-full min-h-20 md:min-h-36 font-light text-current active:scale-90 transition-[transform,_border-color,_background-color] border border-dashed border-brand-state-hover-border rounded-lg flex flex-col justify-center items-center cursor-pointer hover:scale-[101%] group">
      <DynamicPlanButtonWithTooltip feature="sounds" class="absolute right-2 top-2" />
      <IconSaxDocumentUpload v-if="!isUploading" class="w-5 h-5 text-brand-state-hover-border dark:text-white group-hover:scale-110 group-hover:-translate-y-0.5 transition-transform" />
      <div class="flex flex-col items-center justify-center">
        <template v-if="isUploading">
          <div class="flex flex-col gap-2 items-center justify-center py-4">
            <span class="animate-spin h-4 w-4 border border-primary border-t-transparent rounded-full" />
            <span>Uploading audio..</span>
          </div>
        </template>
        <template v-else>
          <span>Upload audio</span>
          <span class="text-xs text-gray-500">Max 10 MB files are allowed</span>
          <span class="text-xs text-gray-500">Supported formats: .mp4, .mp3, .wav, .aac</span>
        </template>
      </div>
      <div class="absolute w-full h-full">
        <Input
          class="hidden" ref="input" id="custom-audio-input"
          type="file" name="custom-audio"
          accept="audio/mp4,audio/mp3,audio/wav,audio/aac"
          @input="uploadAudioFile"
          :disabled="isUploading"
        />
      </div>
    </Button>

    <form
      class="layer-1 flex items-stretch outline outline-2 outline-transparent transition-all rounded-lg focus-within:outline-offset-2 focus-within:outline-cyan-400 text-sm"
      @submit.prevent.stop="searchSounds"
    >
      <input
        ref="input"
        v-model="soundName"
        class="min-w-0 flex-auto rounded-l-lg border border-r-0 border-zinc-900/20 p-3 text-left font-light text-zinc-900 outline-none dark:border-zinc-200/20 bg-white dark:bg-surface-panel-50"
        placeholder="Search for sound effects"
        type="text"
      />
      <button
        :disabled="!soundName?.trim()"
        class="cursor-pointer group flex flex-nowrap items-center justify-center gap-1 whitespace-nowrap rounded-l-none rounded-r-lg bg-purple-700 px-2 py-2 text-white outline-none transition-colors hover:bg-purple-600 focus:bg-purple-600"
        type="submit"
      >
        <span class="hidden md:inline">Search</span>
        <IconSaxArrowRight2 class="h-4 w-4 transition-transform group-hover:translate-x-1 group-focus:translate-x-1" />
      </button>
    </form>

    <div class="relative overflow-y-auto">

      <div class="flex flex-col gap-2">

        <template v-if="selectedTag === 'all'">
          <div class="mb-4" v-for="category in tags.filter(t => soundsToDisplay.some(s => s.tags.includes(t)))" :key="category">
            <h3 class="bg-surface-panel sticky top-0 z-10">{{ capitalize(category) }}</h3>
            <div class="flex flex-col gap-2">
              <template v-for="sound in soundsToDisplay.filter(s => s.tags.includes(category))">
                <SoundEffect
                  v-if="uploadedFiles.some(uploadedFile => uploadedFile.url === sound.url)"
                  @add="(durationSeconds) => historyStore.transaction('SOUND_EFFECT:ADD', () => addSoundEffect(sound, durationSeconds))"
                  @play="stopOtherSounds"
                  :key="sound.id"
                  :sound="sound"
                  :removable="true"
                  @remove="deleteSoundEffect"
                />
                <SoundEffect
                  v-else
                  @add="(durationSeconds) => historyStore.transaction('SOUND_EFFECT:ADD', () => addSoundEffect(sound, durationSeconds))"
                  @play="stopOtherSounds"
                  :key="sound.id"
                  :sound="sound"
                />
              </template>
            </div>
          </div>
        </template>
        <template v-else>
          <template v-for="sound in soundsToDisplay">
            <SoundEffect
              v-if="uploadedFiles.some(uploadedFile => uploadedFile.url === sound.url)"
              @add="(durationSeconds) => historyStore.transaction('SOUND_EFFECT:ADD', () => addSoundEffect(sound, durationSeconds))"
              @play="stopOtherSounds"
              :key="sound.id"
              :sound="sound"
              :removable="true"
              @remove="deleteSoundEffect"
            />
            <SoundEffect
              v-else
              @add="(durationSeconds) => historyStore.transaction('SOUND_EFFECT:ADD', () => addSoundEffect(sound, durationSeconds))"
              @play="stopOtherSounds"
              :key="sound.id"
              :sound="sound"
            />
          </template>
        </template>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
