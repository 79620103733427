<script setup lang="ts">
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { supabase } from '@/authentication/supabase'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import IconSaxEmojiHappy from '@/components/Icons/iconsax/IconSaxEmojiHappy.vue'
import IconSaxHomeTrendUp from '@/components/Icons/iconsax/IconSaxHomeTrendUp.vue'
import IconSaxLoginCurve from '@/components/Icons/iconsax/IconSaxLoginCurve.vue'
import IconSaxLogoutCurve from '@/components/Icons/iconsax/IconSaxLogoutCurve.vue'
import IconSaxStar from '@/components/Icons/iconsax/IconSaxStar.vue'
import { Avatar, AvatarImage } from '@/components/ui/avatar'
import { Button } from '@/components/ui/button'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { tiers } from '@/enums/tiers'
import { useIsMobile } from '@/Hooks/useIsMobile'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import { queryClient } from '@/services/QueryClient'
import { useUserInfoStore, type OutboundSocialAccount } from '@/store/user/userInfo'
import { useRouter } from 'vue-router'
import ChatPluginSlotMobile from '../../slots/ChatPluginSlotMobile.vue'

const router = useRouter()

const userInfoStore = useUserInfoStore()
const isMobile = useIsMobile()

const accountMenuOpen = ref(false)
const account = computed(() => {
  const googleAccount = userInfoStore.googleAccount as OutboundSocialAccount
  const twitchAccount = userInfoStore.twitchAccount as OutboundSocialAccount
  return userInfoStore.allSocials.find((s) => s.profileImageUrl) ?? googleAccount ?? twitchAccount ?? null
})

const isLoggingOut = ref(false)

const onLogout = async () => {
  isLoggingOut.value = true

  await supabase.auth.signOut()

  await userInfoStore.updateUserInfo()
  await queryClient.invalidateQueries()
  window.location.href = router.resolve({ name: 'editor' }).href

  isLoggingOut.value = false
}

async function newProject() {
  await router.push({ name: 'editor' })
}
</script>

<template>
  <template v-if="isMobile">
    <header class="-from-[12.32%] z-[50] flex justify-between bg-gradient-to-l from-[#A000FE] to-[#6080FE] to-[97.88%]">
      <Popover v-model:open="accountMenuOpen" v-if="userInfoStore.isAuthenticated">
        <div class="flex items-center justify-center gap-0.5">
          <PopoverTrigger as-child class="mx-auto mt-auto">
            <Button size="sm" variant="ghost" class="m-0 h-[2.5rem] max-w-[60%] flex-row items-center p-2 text-white">
              <Avatar class="h-7 w-7">
                <AvatarImage :src="account?.profileImageUrl" />
              </Avatar>
            </Button>
          </PopoverTrigger>
          <RouterLink to="/">
            <Button size="sm" variant="ghost" class="flex-col items-center px-0 text-white">
              <span class="text-lg">StreamLadder</span>
            </Button>
          </RouterLink>

          <GoldPlanButton
            v-if="userInfoStore.tier === tiers.GOLD"
            class="ml-1 h-4 w-4"
            :can-click="false"
            :force-show="true"
            :small="true"
          />
          <SilverPlanButton
            v-else-if="userInfoStore.tier === tiers.SILVER"
            class="ml-1 h-4 w-4"
            :can-click="false"
            :force-show="true"
            :small="true"
          />
        </div>

        <PopoverContent :align="'start'" side="bottom" :side-offset="0" class="w-screen rounded-none border-none p-1">
          <nav class="flex flex-col items-stretch gap-1">
            <span
              class="-mt-0.5 flex w-full items-center gap-2 overflow-hidden truncate border-b border-b-accent py-2 pl-4 text-center text-base"
            >
              Hi, {{ userInfoStore.userName }}
            </span>
            <RouterLink :to="{ name: dashboardRouteNames.dashboard }">
              <Button class="w-full justify-start" variant="ghost">
                <IconSaxHomeTrendUp class="h-4 w-4" /> Dashboard
              </Button>
            </RouterLink>
            <RouterLink :to="{ name: dashboardRouteNames.account.settings }">
              <Button class="w-full justify-start" variant="ghost">
                <IconSaxEmojiHappy class="h-4 w-4" /> My Account
              </Button>
            </RouterLink>
            <Button
              class="w-full justify-start"
              @click="
                accountMenuOpen = false;
                newProject();
              "
              variant="ghost"
            >
              <IconSaxStar class="h-4 w-4" /> New project
            </Button>
            <Button class="w-full justify-start" variant="ghost" @click="onLogout">
              <template v-if="isLoggingOut">
                <div class="mr-2.5 h-4 w-4 animate-spin rounded-full border-2 border-current border-t-transparent" />
                Logging out..
              </template>
              <template v-else>
                <IconSaxLogoutCurve class="h-4 w-4 shrink-0 rotate-180" />
                Logout
              </template>
            </Button>
          </nav>
        </PopoverContent>
      </Popover>

      <div v-if="!userInfoStore.isAuthenticated" class="flex items-center justify-between w-full gap-2">
        <div class="flex flex-row items-center gap-1 px-2">
          <ChatPluginSlotMobile class="w-6 shrink-0" />
          <RouterLink to="/">
            <Button
              size="sm"
              variant="ghost"
              class="h-full flex-col items-center px-2 py-0 text-white hover:bg-white/20"
            >
              <span class="text-lg">StreamLadder</span>
            </Button>
          </RouterLink>
        </div>
        <LoginDialog>
          <button
            class="ml-auto flex flex-nowrap items-center justify-center gap-2 whitespace-nowrap rounded-none px-3 py-2 text-white outline-none transition-colors hover:bg-indigo-600 focus:bg-indigo-600"
          >
            <IconSaxLoginCurve class="h-4 w-4 shrink-0 [&>path]:stroke-1" />
            Login
          </button>
        </LoginDialog>
      </div>

      <template v-else>
        <ChatPluginSlotMobile class="ml-auto mr-2 w-6 shrink-0" />
      </template>
    </header>
  </template>
</template>
