<script setup lang="ts">
import { tiers } from '@/enums/tiers';
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import IconSaxExport from '@/components/Icons/iconsax/IconSaxExport.vue';
import IconSaxFlash from '@/components/Icons/iconsax/IconSaxFlash.vue';
import { Button } from '@/components/ui/button';
import type { Feature } from '@/data/features';
import { useUserInfoStore } from '@/store/user/userInfo';
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue';
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import VideoPreview from '@/areas/editor/pages/components/VideoPreview.vue';

interface Props {
  project: EditorProject;
  features: Feature[];
}

defineProps<Props>();

interface Emits {
  (event: 'render-with-watermark'): void;
  (event: 'upgrade'): void;
}

const emit = defineEmits<Emits>();

const isOpen = defineModel<boolean>('open', { required: true });

const labels: Partial<Record<Feature, string>> = {
  'stickers': 'Social Stickers',
  'text': 'Texts or Social Stickers',
  'captions': 'AI-Powered Captions',
  'video-segmentation': 'Multiple Segments',
  'zoom-segments': 'Zoom Effects',
  'sounds': 'Sound Effects',
  'giphy-stickers': 'Animated Giphy Stickers',
  'animated-social-stickers': 'Animated Social Stickers',
  'profanity': 'Profanity Filter',
  'resolution-1080': 'High Quality Export',
  'twitch-emotes': 'Twitch Emotes',
  'upload-custom-stickers': 'Uploaded Stickers',
  'element-animations': 'Animations',
  'mobile': 'Exporting on Mobile',
}

const userInfoStore = useUserInfoStore();
</script>

<template>

  <Dialog v-model:open="isOpen">
    <DialogContent class="flex flex-col items-center lg:gap-6 lg:px-6 lg:py-12 max-w-3xl">

      <div class="flex flex-col lg:gap-2">
        <template v-if="userInfoStore.tier === tiers.SILVER">
          <DialogTitle>
            <h2 class="hidden lg:block text-center text-3xl font-bold dark:text-white">⚡️ You've added Gold Features ⚡️</h2>
            <h2 class="lg:hidden text-center text-lg font-bold dark:text-white">You've added Gold Features</h2>
          </DialogTitle>
          <DialogDescription class="text-center text-sm lg:text-base font-light text-gray-600 dark:text-white">
            You can export this video with watermark or upgrade to Gold to export without watermark.
          </DialogDescription>
        </template>
        <template v-else>
          <DialogTitle>
            <h2 class="hidden lg:block text-center text-3xl font-bold dark:text-white">⚡️ You've added Premium Features ⚡️</h2>
            <h2 class="lg:hidden text-center text-lg font-bold dark:text-white">You've added Premium Features</h2>
          </DialogTitle>
          <DialogDescription class="text-center text-sm lg:text-base font-light text-gray-600 dark:text-white">
            You can export this video for free with watermark or upgrade to export without watermark.
          </DialogDescription>
        </template>
      </div>
      
      <VideoPreview :project="project" with-watermark />

      <div class="flex flex-col gap-2 mt-2">
        <Button @click="emit('upgrade')" size="lg" class="text-black bg-yellow-300 hover:bg-yellow-200">
          <IconSaxFlash class="h-5 w-5" />
          Remove Watermark
        </Button>

        <Button @click="emit('render-with-watermark')" variant="primary" size="lg">
          <IconSaxExport class="h-5 w-5" />
          Export Clip
        </Button>
      </div>

      <div class="flex flex-col gap-4 items-center max-w-full border rounded-lg p-4 bg-slate-100 dark:bg-surface-panel">
        <p class="font-semibold">{{ userInfoStore.tier === tiers.SILVER ? 'Gold Features Used' : 'Premium Features Used' }}</p>
        <div class="flex flex-wrap items-center justify-center w-full gap-2 font-light text-sm">
          <template v-for="feature in features" :key="feature">
            <div class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
              <span class="font-semibold">{{ labels[feature] }}</span>
              <DynamicPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small :feature="feature" />
            </div>
          </template>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
