<script setup lang="ts">
import { computed, ref, watch, onMounted } from 'vue'
import type { Sticker as StickerLibraryItem } from '@/components/Stickers/stickerLibrary/stickerLibrary'
import { useStickersStore } from '@/areas/editor/store/useStickersStore'
import { useEditorFocusStore, FocusTypes } from '@/store/editor/editorFocus'
import type { TextLibraryItem } from '@/components/Stickers/textLibrary'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import ColorPicker from '@/components/Stickers/ColorPicker.vue'
import OverlayBadges from '@/components/OverlayBadges.vue'
import Tile from '@/components/Tile.vue'
import { useFontsStore } from '@/store/fonts'
import { selectStickerFont } from '@/store/entity-system/selectors/selectStickerFont'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import type { TypedSticker } from "@/areas/editor/@type/Project";
const isSafariBasedBrowser = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

const props = withDefaults(defineProps<{
  textContent: string;
  moment?: 'default' | 'intro' | 'outro';
  stickerMeta: TextLibraryItem | StickerLibraryItem;
  activeSocials?: string[];
  editable?: boolean;
  isNew?: boolean;
}>(), {
  editable: false,
  isNew: false,
  moment: 'default',
  activeSocials: () => []
})

const emit = defineEmits<{
  (event: 'select', payload: Omit<TypedSticker<'social' | 'text'>, 'id' | 'z'>): void;
}>()

const focusStore = useEditorFocusStore()
const videoStore = useVideoStore()
const stickersStore = useStickersStore()

const focused = computed(() => {

  const type = focusStore.focus?.type
  const key = focusStore.focus?.key

  if (!key || type !== FocusTypes.TEXTSTICKER) {
    return null
  }

  const sticker = stickersStore.selectById(key)

  if (sticker) {
    const variant = 'variant' in props.stickerMeta ? props.stickerMeta.variant : null
    const focusedVariant = 'variant' in sticker ? sticker.variant : null

    if (focusedVariant && variant) {
      return variant === focusedVariant
    } else {
      return null
    }
  } else {
    return null
  }
})

function onColorChange(newColor: string | null) {
  if (focused.value) {
    stickersStore.state[focusStore.focus!.key].color = newColor
  }
}

const colorOptions = 'colors' in props.stickerMeta ? Object.keys(props.stickerMeta.colors) : []
const selectedColor = ref<string>(colorOptions.length > 0 ? colorOptions[0] : '')
watch(selectedColor, onColorChange)

const containerSize = ref<DOMRect | null>(null)
const containerWidth = computed(() => containerSize.value?.width ?? 0)

function onResize(rect: DOMRect) {
  containerSize.value = rect
}

const ready = ref(false)
const scale = ref(1)

const animateScale = ref(true)
function scaleSticker() {

  setTimeout(() => {

    if (!elementRef.value || !containerSize.value) {
      return
    }

    const offsetWidth = Math.max((elementRef.value.childNodes[0] as HTMLElement).offsetWidth, elementRef.value.offsetWidth, elementRef.value.clientWidth);

    const targetWidth = containerWidth.value * (9 / 10)
    if (offsetWidth > targetWidth) {
      scale.value = targetWidth / offsetWidth
    } else {
      scale.value = 1
    }

    setTimeout(() => {
      ready.value = true
      setTimeout(() => {
        animateScale.value = false
      }, 100)
    }, 100)
  }, 0)
}

watch(containerSize, scaleSticker)
watch(() => props.textContent, scaleSticker)

onMounted(async () => {

  scaleSticker()

  const font = selectStickerFont(props.stickerMeta)

  const fontsStore = useFontsStore()
  await fontsStore.loadFontByLabel(font)

  scaleSticker()
})

const elementRef = ref<HTMLElement | null>(null)
const historyStore = useHistoryStore()

const isMultiSticker = computed(() => 'tags' in props.stickerMeta && props.stickerMeta.tags && props.stickerMeta.tags.includes('multiple'))
const isTextSticker = computed(() => 'variant' in props.stickerMeta)

function selectSticker() {

  const clientRect = elementRef.value?.getBoundingClientRect()
  if (!clientRect) {
    return
  }

  const outputHeight = 1920
  const outputWidth = 1080

  const centerX = 0.5
  const centerY = isTextSticker.value ? 0.4 : 0.25

  const drawWidth = (1 / 3) * outputWidth
  const drawHeight = clientRect.height * (drawWidth / clientRect.width)
  const drawX = (centerX * outputWidth) - (0.5 * drawWidth)
  const drawY = (centerY * outputHeight) - (0.5 * drawHeight)

  const naturalWidth = clientRect.width / scale.value
  const naturalHeight = clientRect.height / scale.value

  const videoDurationInMs = videoStore._duration * 1000
  const startMs = props.moment === 'outro' ? videoDurationInMs - 5000 : 0
  const endMs = props.moment === 'intro' ? 5000 : videoDurationInMs

  const area = {
    x: drawX / outputWidth,
    y: drawY / outputHeight,
    width: drawWidth / outputWidth,
    height: drawHeight / outputHeight,
  }

  if (isTextSticker.value) {
    emit('select', {
      type: 'text',
      key: props.stickerMeta.key,
      variant: props.stickerMeta.variant,
      startMs: startMs,
      endMs: endMs,
      color: selectedColor.value,
      area: area,
      naturalWidth: naturalWidth,
      naturalHeight: naturalHeight,
      textContent: props.textContent,
      editing: true
    } as Omit<TypedSticker<'text'>, 'z' | 'id'>)
  } else {
    emit('select', {
      type: 'social',
      key: props.stickerMeta.key,
      startMs: startMs,
      endMs: endMs,
      color: selectedColor.value,
      icon: isMultiSticker.value
        ? JSON.stringify(props.activeSocials)
        : props.stickerMeta.icon,
      textContent: props.textContent,
      area: area,
      naturalWidth: naturalWidth,
      naturalHeight: naturalHeight,
    } as Omit<TypedSticker<'social'>, 'z' | 'id'>)
  }
}
</script>

<template>
  <Tile
    @click="historyStore.transaction('STICKER:ADD', selectSticker)"
    @resize="onResize"
    :aspect="0.6"
    class="active:scale-90 transition-[transform,_border-color,_background-color]"
    :class="{
      'text-sticker': isTextSticker,
      '!border-brand-state-active-border': focused,
    }"
    feature="text"
  >
    <div ref="elementRef"
      class="light"
      :style="{ transform: `scale(${ready ? scale : scale * 0.75})`, 'transform-origin': isSafariBasedBrowser ? '' : 'left', 'width': 'max-content' }"
      :class="{ 'opacity-0 invisible': !ready, 'transition-[opacity,_transform]': animateScale }"
    >
      <component
        :is="stickerMeta.component"
        :html-content="textContent"
        :color="selectedColor"
        :variant="'variant' in stickerMeta ? stickerMeta.variant : null"
        :icon="isMultiSticker ? JSON.stringify(activeSocials) : stickerMeta.icon"
        :editable="editable"
        @stickerLoaded="scaleSticker"
        class="pointer-events-none [&_*]:!whitespace-pre"
        :class="{ 'scale-75 opacity-0 invisible': !ready }"
        style="break-after: always"
      />
    </div>

    <ColorPicker class="mt-2" v-if="selectedColor" v-model="selectedColor" :options="'colors' in stickerMeta ? stickerMeta.colors : {}" />
    <OverlayBadges :is-new="isNew" :tags="stickerMeta.tags" />
  </Tile>
</template>

<style lang="scss" scoped>
</style>
