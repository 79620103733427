<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import CaptionStylePreview from '@/areas/editor/views/captions/CaptionStylePreview.vue'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import { captionStyleSettingsArray, type CaptionStyleSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { lastUsedCaptionPreset } from '@/components/Captions/useLocalCaptionSettings'
import type { CaptionPreset, CustomCaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import { captionPresets, type CaptionPresetKey } from '@/data/captionPresets'
import { useCustomCaptionPresets } from '@/queries/customCaptionPresetsApi'
import { defaultCaptionOptions } from '@/store/editor/editorCaptions'
import { cloneDeep, intersection, truncate } from 'lodash-es'
import { capitalize, computed, ref } from 'vue'
import { isBefore } from 'date-fns';
import { markCaptionAsUsed, isNewCaptionPreset } from '@/helpers/isNewOverlayElement';

const tags = ['all', 'popular', 'playful', 'simple']
const selectedTag = ref('all')

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()

function toKebabCase(s: string) {
  return s
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/^-+|-+$/g, '')
}

function sortBySeasonal<T>(
  a: [CaptionStyleSettings, T],
  b: [CaptionStyleSettings, T],
  tagKey: (item: T) => string[]
): number {

  const seasonalTags = ['christmas', 'halloween']
  if (intersection(tagKey(a[1]), seasonalTags).length > 0) {
    return 1
  }
  if (intersection(tagKey(b[1]), seasonalTags).length > 0) {
    return -1
  }

  return 1
}

function filterByTag<T>(items: [CaptionStyleSettings, T][], selectedTag: string): [CaptionStyleSettings, T][] {
  if (selectedTag === 'all') {
    return items
  }
  if (selectedTag === 'seasonal') {
    return items.filter(([style]) => intersection(style?.tags, ['christmas', 'halloween']).length > 0)
  }
  return items.filter(([style]) => style?.tags?.includes(selectedTag))
}

const styleSettingsWithPresets = computed(() => {
  const sortedList: [CaptionStyleSettings, CaptionPreset][] = captionStyleSettingsArray
    .reduce((acc, style) => {
      const preset = captionPresets[toKebabCase(style.type) as CaptionPresetKey]
      if (preset) {
        acc.push([style, preset])
      }
      return acc
    }, [] as [CaptionStyleSettings, CaptionPreset][])
    .sort((a, b) => sortBySeasonal(a, b, (item) => item.tags))
    .sort((a, b) => {
      const aIsNew = isNewCaptionPreset(a[1]);
      const bIsNew = isNewCaptionPreset(b[1]);
      if (aIsNew === bIsNew) {
        return 0;
      } else if (aIsNew) {
        return -1;
      } else {
        return 1;
      }
    })

  return filterByTag(sortedList, selectedTag.value)
})

const { data: customCaptionPresets } = useCustomCaptionPresets()
const styleSettingsWithCustomCaptionPresets = computed(() => {
  if (!customCaptionPresets?.value) {
    return []
  }

  const sortedList: [CaptionStyleSettings, CustomCaptionPreset][] = customCaptionPresets.value
    .reduce((acc, userCaption) => {
      const styleSettings = captionStyleSettingsArray.find(
        (style) => toKebabCase(style.type) === userCaption.preset?.key
      )

      if (!styleSettings) {
        return acc
      }

      const previewText = styleSettings.previewSettings?.previewText || []
      const presetName = userCaption.name.split(' ').map((word) => truncate(word, { length: 10, omission: '...' }))
      const previewSettings = {
        ...styleSettings.previewSettings!,
        previewText: [...presetName, ...previewText],
      }
      acc.push([{ ...styleSettings, previewSettings }, userCaption])

      return acc
    }, [] as [CaptionStyleSettings, CustomCaptionPreset][])
    .sort((a, b) => sortBySeasonal(a, b, (item) => item.preset.tags))

  return filterByTag(sortedList, selectedTag.value)
})

async function generateCaptions(preset: CaptionPreset) {
  if (typeof preset.font.highlightColor === 'string') {
    await captionsStore.generateCaptions(preset, preset.font.highlightColor)
  } else {
    await captionsStore.generateCaptions(preset, preset.font.highlightColor?.colorStops[0]?.color)
  }
}

async function onSelectCustomCaptionPreset(
  userCaption: CustomCaptionPreset,
  { navigate = false }: { navigate: boolean }
) {

  const hasGeneratedCaptions = captionsStore.hasGeneratedCaptions
  const isDifferentPreset = userCaption.id !== lastUsedCaptionPreset.value

  if (isDifferentPreset || !captionsStore.baseCaptionPreset || !hasGeneratedCaptions) {
    const transactionType = hasGeneratedCaptions ? 'CAPTIONS:CHANGE_PRESET' : 'CAPTIONS:GENERATE'

    await historyStore.transaction(transactionType, async () => {

      const selectedCaption = cloneDeep(userCaption)

      if (!hasGeneratedCaptions || !captionsStore.entities.length) {
        await generateCaptions(selectedCaption.preset)
      }

      if (captionsStore.bleepCurseWords) {
        await captionsStore.generateProfanity()
      }

      captionsStore.currentUserCaption = selectedCaption
      captionsStore.baseCaptionPreset = selectedCaption.preset
      captionsStore.baseOptions = {
        ...defaultCaptionOptions,
        ...selectedCaption.preset.options,
      }
      lastUsedCaptionPreset.value = selectedCaption.id
    })
  } else {
    const selectedCaption = cloneDeep(userCaption)
    captionsStore.currentUserCaption = selectedCaption
    captionsStore.baseCaptionPreset = selectedCaption.preset
    captionsStore.baseOptions = {
      ...defaultCaptionOptions,
      ...selectedCaption.preset.options,
    }
    lastUsedCaptionPreset.value = selectedCaption.id
  }

  if (navigate) {
    captionsStore.sidebarPage = 'settings'
  } else {
    // Make sure the first time a sure selects a preset, they are taken to the text tab
    if (!hasGeneratedCaptions) {
      captionsStore.captionsTab = 'text'
    }
    captionsStore.sidebarPage = 'captions'
  }
}

async function onSelectDefaultCaptionPreset(preset: CaptionPreset, { navigate = false }: { navigate: boolean }) {

  const hasGeneratedCaptions = captionsStore.hasGeneratedCaptions
  const isDifferentPreset = preset.key !== lastUsedCaptionPreset.value
  markCaptionAsUsed(preset.key);

  if (isDifferentPreset || !captionsStore.baseCaptionPreset || !hasGeneratedCaptions) {
    const transactionType = captionsStore.hasGeneratedCaptions ? 'CAPTIONS:CHANGE_PRESET' : 'CAPTIONS:GENERATE'

    await historyStore.transaction(transactionType, async () => {

      if (!captionsStore.hasGeneratedCaptions || !captionsStore.entities.length) {
        await generateCaptions(preset)
      }

      if (captionsStore.bleepCurseWords) {
        await captionsStore.generateProfanity()
      }

      captionsStore.currentUserCaption = null
      captionsStore.baseCaptionPreset = cloneDeep(preset)
      captionsStore.baseOptions = {
        ...defaultCaptionOptions,
        ...preset.options,
      }
      lastUsedCaptionPreset.value = preset.key
    })
  } else {
    captionsStore.currentUserCaption = null
    captionsStore.baseCaptionPreset = cloneDeep(preset)
    captionsStore.baseOptions = {
      ...defaultCaptionOptions,
      ...preset.options,
    }
    lastUsedCaptionPreset.value = preset.key
  }

  if (navigate) {
    captionsStore.currentUserCaption = {
      name: preset.key,
      preset: {
        ...cloneDeep(preset),
        options: cloneDeep(captionsStore.baseOptions),
      },
    }
    captionsStore.sidebarPage = 'settings'
  } else {
    // Make sure the first time a sure selects a preset, they are taken to the text tab
    if (!hasGeneratedCaptions) {
      captionsStore.captionsTab = 'text'
    }
    captionsStore.sidebarPage = 'captions'
  }
}
</script>

<template>
  <div class="w-full min-w-0 shrink-0 overflow-x-auto overflow-y-hidden py-2">
    <div class="flex flex-row gap-1">
      <RadioToggleButton v-for="tag in tags" :key="tag" :value="tag" v-model="selectedTag" size="sm"
        class="relative shrink-0 flex-col font-light data-[state=active]:font-semibold">
        <span class="absolute inset-auto">{{ capitalize(tag) }}</span>
        <span class="invisible font-semibold">{{ capitalize(tag) }}</span>
      </RadioToggleButton>
    </div>
  </div>

  <template v-if="styleSettingsWithCustomCaptionPresets.length">
    <div class="w-full py-2">
      <p class="text-base font-normal">Custom styles</p>
    </div>

    <div class="grid grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] gap-2">
      <div v-for="[styleSettings, userCaption] in styleSettingsWithCustomCaptionPresets" :key="userCaption.id"
        class="relative">
        <CaptionStylePreview :preset="userCaption.preset" :settings="styleSettings"
          :selected="captionsStore.currentUserCaption?.id === userCaption.id"
          @select="(_, { navigate }) => onSelectCustomCaptionPreset(userCaption, { navigate })" />
      </div>
    </div>
  </template>

  <div class="mt-2 w-full py-2">
    <p class="text-base font-normal">Default styles</p>
  </div>

  <div class="grid grid-cols-[repeat(auto-fill,_minmax(200px,_1fr))] gap-2 pb-4 2xl:pb-8">
    <div class="relative" v-for="([styleSettings, preset], index) in styleSettingsWithPresets" :key="styleSettings.type + index">
      <CaptionStylePreview :preset="preset" :settings="styleSettings"
        :selected="preset.key === captionsStore.baseCaptionPreset?.key && !captionsStore.currentUserCaption"
        @select="onSelectDefaultCaptionPreset"
      />
    </div>
  </div>
</template>

<style scoped></style>
