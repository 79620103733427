
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";
// @ts-ignore
import { createFile } from "mp4box";


export async function createMontageFFmpegWasm(
    urls: string[],
    onProgress: (progress: number) => void
  ): Promise<Blob> {
  
    const ffmpeg = new FFmpeg();
  
    const baseURL = 'https://forever-storage.streamladder.com/video-editor/ffmpeg';
  
    await ffmpeg.load({
      // workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });


    ffmpeg.on('log', (log) => {
        console.log(log);
    })


    const videoInfoPromises = [];

    const ffmpegVideoFiles: string[] = []
    let ix = 0;
    for (const url of urls) {
        const fileName = `input_${ix}.mp4`;
        const videoFile = await fetchFile(url);


        const mp4boxfile = createFile();
        //@ts-ignore
        videoFile.buffer.fileStart = 0;


        const videoInfoPromise = new Promise(resolve => {
          // @ts-ignore
          mp4boxfile.onReady = (info) => {
            resolve(info)
          }
        });

        mp4boxfile.appendBuffer(videoFile.buffer);

        videoInfoPromises.push(videoInfoPromise)


        ffmpeg.writeFile(fileName, videoFile);
        ffmpegVideoFiles.push(fileName);

        ix++;
    }
    
    const allVideoInfo = (await Promise.all(videoInfoPromises)) as any[];
    const firstVideoInfo = allVideoInfo[0];

    const totalTime = allVideoInfo.reduce((a, b) => b.duration + a, 0);

    ffmpeg.on('progress', (progress) => {
      onProgress(progress.time / (totalTime * 1e3));
    })


    const videoWidth = firstVideoInfo.videoTracks[0].track_width;
    const videoHeight = firstVideoInfo.videoTracks[0].track_height;
    const fps = Math.round(1 / ((firstVideoInfo.videoTracks[0].samples_duration / firstVideoInfo.videoTracks[0].nb_samples) / firstVideoInfo.videoTracks[0].timescale));

    let filterComplex = '';
    for (let i = 0; i < ffmpegVideoFiles.length; i++) {
        filterComplex += `[${i}]`
          + `fps=${fps},`
          + `scale=${videoWidth}:${videoHeight}:force_original_aspect_ratio=decrease,`
          + `pad=${videoWidth}:${videoHeight}:(ow-iw)/2:(oh-ih)/2,`
          + `setsar=1[v${i}];`
    }

    for (let i = 0; i < ffmpegVideoFiles.length; i++) {
      filterComplex += `[v${i}][${i}:a:0]`
    }

    // Concat operation
    filterComplex += `concat=n=${ffmpegVideoFiles.length}:v=1:a=1[vout][aout];`;

    // Apply dynamic audio normalization
    filterComplex += `[aout]loudnorm=I=-12:LRA=8:TP=-1[audio_final]`;

    const command = [
      ...ffmpegVideoFiles.flatMap(f => ['-i', f]),
      '-filter_complex', filterComplex,
      '-map', '[vout]',
      '-map', '[audio_final]',
      '-c:a', 'aac',
      '-preset', 'ultrafast',
      'output.mp4'
    ];

    await ffmpeg.exec(command);
  
    const data = await ffmpeg.readFile('output.mp4');

    return new Blob([data], {type: "video/mp4"});
  }