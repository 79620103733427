import templateService from '@/services/templateService';
import type { Template } from '@/store/user/userTemplates';
import { useUserTemplatesStore } from '@/store/user/userTemplates';
import { hasCropperData, savedTemplateToLayoutPreset, layoutWithCropsToLayoutPreset } from '@/areas/editor/@data/layouts';
import { toVideoEditorTemplate, type VideoEditorTemplate } from '@/areas/editor/@data/convert/toVideoEditorTemplate';
import { onUserInfoReadyAsync } from '@/store/user/userInfo';
import { requestUserSignInAsync, supabase, getUser } from '@/authentication/supabase';
import { getApiCustomLayouts } from '@/apis/streamladder-api/custom-layouts/custom-layouts';
import type { LayoutWithCrops } from '@/areas/editor/@type/Project';
import type { Json } from '@/authentication/database.types';
import { v4 as uuid } from 'uuid';
import { streamLadderAxios } from '@/services/axios';
import { omit } from 'lodash-es';
import type { SoundEffect } from '@/areas/editor/@type/editor-project/Effect';
import type { Element } from '@/areas/editor/@type/editor-project/Element';

export default {

  async list({ videoWidth, videoHeight, durationMs }: ListRequestBody) {

    const { isAuthenticated } = await onUserInfoReadyAsync();
    if (!isAuthenticated) {
      return [];
    }

    const [savedTemplates, customLayouts] = await Promise.all([
      await fetchSavedTemplates(),
      await getApiCustomLayouts(),
      await templateService.getTemplates()
    ]);

    const store = useUserTemplatesStore();
    
    const result: VideoEditorTemplate[] = savedTemplates.map((t) => toTemplateWithAbsoluteTimestamps(t, durationMs));

    for (const template of store.savedTemplates as Template[]) {
      try {
        if (hasCropperData(template)) {
          const preset = savedTemplateToLayoutPreset(template, durationMs, { width: videoWidth, height: videoHeight });
          const videoEditorTemplate = toVideoEditorTemplate(preset);
          if (videoEditorTemplate) {
            result.push(toVideoEditorTemplate(preset));
          }
        }
      } catch (e) {
        console.warn('Error while converting template. Skipping...', e);
      }
    }

    for (const layout of customLayouts) {
      try {
        const preset = layoutWithCropsToLayoutPreset(layout as LayoutWithCrops, { width: videoWidth, height: videoHeight }, 'saved-template');
        const videoEditorTemplate = toVideoEditorTemplate(preset);
        if (videoEditorTemplate) {
          result.push(toVideoEditorTemplate(preset));
        }
      } catch (e) {
        console.warn('Error while converting layout. Skipping...', e);
      }
    }

    return result;
  },
  
  async upsert(request: UpsertRequestBody) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      await deleteOldTemplateIfAny(request.template.id);
      await deleteOldLayoutIfAny(request.template.id);
      return postSavedTemplate(request.template, request.videoDurationMs);
    } else {
      throw new Error('User not signed in');
    }
  },

  async remove(request: RemoveRequestBody) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      await deleteOldTemplateIfAny(request.templateId);
      await deleteOldLayoutIfAny(request.templateId);
      return deleteSavedTemplate(request.templateId);
    } else {
      throw new Error('User not signed in');
    }
  }
}

async function deleteOldTemplateIfAny(templateId: string | null) {
  if (templateId !== null) {
    const userTemplatesStore = useUserTemplatesStore();
    if (userTemplatesStore.getTemplateById(templateId)) {
      await streamLadderAxios.delete(`/api/templates/${templateId}`)
    }
  }
}

async function deleteOldLayoutIfAny(layoutId: string | null) {
  if (layoutId !== null) {
    const response = await streamLadderAxios.get(`/api/customlayouts/${layoutId}`).catch((e) => e);
    if (response.status === 200) {
      await streamLadderAxios.delete(`/api/customlayouts/${layoutId}`)
    }
  }
}

async function fetchSavedTemplates() {

  const { data: { user } } = await getUser();
  if (!user?.id) {
    return [];
  }

  const { data } = await supabase.from('SavedTemplates')
    .select(`json:Json`)
    .is('ArchivedAt', null)
    .order('CreatedAt', { ascending: false });

  if (!data) {
    return [];
  } else {
    return data.map((d) => d.json as unknown as VideoEditorRelativeTemplate);
  }
}

function isGuid(id: string) {
  const guidRegex = new RegExp('^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$');
  return guidRegex.test(id);
}

async function postSavedTemplate(template: VideoEditorTemplate, videoDurationMs: number) {

  const { data: { user } } = await getUser();
  if (!user?.id) {
    throw new Error('User not authenticated');
  }

  const templateId = isGuid(template.id) ? template.id : uuid();
  template.id = templateId;

  const json = toTemplateWithRelativeTimestamps(template, videoDurationMs);
  return supabase
    .from('SavedTemplates')
    .upsert({ Id: templateId, SLUserId: user.app_metadata.streamladder_userid, UserId: user.id, Json: json as unknown as Json });
}

async function deleteSavedTemplate(templateId: string) {

  const { data: { user } } = await getUser();
  if (!user?.id) {
    throw new Error('User not authenticated');
  }

  return supabase.from('SavedTemplates')
    .update({ ArchivedAt: new Date().toISOString() })
    .eq('Id', templateId);
}

type VideoEditorRelativeTemplate = Omit<VideoEditorTemplate, 'elements' | 'sounds'> & {
  elements: WithRelativeTimestamps<Element>[];
  sounds: WithRelativeTimestamps<SoundEffect>[];
}

const toTemplateWithRelativeTimestamps = (template: VideoEditorTemplate, videoDurationMs: number) => ({
  ...template,
  elements: template.elements.map((e) => toRelativeTimestamps(e, videoDurationMs)),
  sounds: template.sounds.map((s) => toRelativeTimestamps(s, videoDurationMs)),
})

const toRelativeTimestamps = <T extends { startMs: number, endMs: number }>(element: T, videoDurationMs: number): WithRelativeTimestamps<T> => ({
  ...omit(element, 'startMs', 'endMs'),
  durationMs: element.endMs - element.startMs,
  fromStartRatio: element.startMs / videoDurationMs,
  fromEndRatio: (videoDurationMs - element.endMs) / videoDurationMs,
});

const toTemplateWithAbsoluteTimestamps = (template: VideoEditorRelativeTemplate, videoDurationMs: number): VideoEditorTemplate => ({
  ...template,
  elements: template.elements.map((e) => toAbsoluteTimestamps(e, videoDurationMs) as Element),
  sounds: template.sounds.map((s) => toAbsoluteTimestamps(s, videoDurationMs) as SoundEffect),
});

const toAbsoluteTimestamps = <T extends { durationMs: number, fromStartRatio: number, fromEndRatio: number }>(element: T, videoDurationMs: number): WithAbsoluteTimestamps<T> => {
  
  const base = omit(element, 'durationMs', 'fromStartRatio', 'fromEndRatio');
  
  if (element.fromStartRatio < 0.1 && element.fromEndRatio < 0.1) {
    return {
      ...base,
      startMs: 0,
      endMs: videoDurationMs,
    }
  }
  
  if (Math.abs(element.fromStartRatio - element.fromEndRatio) < 0.25) {
    return {
      ...base,
      startMs: 0.5 * videoDurationMs - 0.5 * element.durationMs,
      endMs: 0.5 * videoDurationMs + 0.5 * element.durationMs,
    }
  }
  
  if (element.fromStartRatio < element.fromEndRatio) {

    const startMs = Math.min(element.fromStartRatio * videoDurationMs, videoDurationMs - element.durationMs);
    const endMs = Math.min(startMs + element.durationMs, videoDurationMs);

    return { ...base, startMs, endMs }
  } else {

    const endMs = Math.max(videoDurationMs - (element.fromEndRatio * videoDurationMs), element.durationMs);
    const startMs = Math.max(endMs - element.durationMs, 0);

    return { ...base, startMs, endMs }
  }
};

type WithRelativeTimestamps<T extends { startMs: number, endMs: number }> = Omit<T, 'startMs' | 'endMs'> & {
  durationMs: number;
  fromStartRatio: number;
  fromEndRatio: number;
}

type WithAbsoluteTimestamps<T extends { durationMs: number, fromStartRatio: number, fromEndRatio: number }> = Omit<T, 'durationMs' | 'fromStartRatio' | 'fromEndRatio'> & {
  startMs: number;
  endMs: number;
}

interface ListRequestBody {
  videoWidth: number;
  videoHeight: number;
  durationMs: number;
}

interface UpsertRequestBody {
  template: VideoEditorTemplate;
  videoDurationMs: number;
}

interface RemoveRequestBody {
  templateId: string;
}
