import { getSession } from '@/authentication/supabase'
import type { SoundEffect } from '@/areas/editor/@type/Project'

export type UploadResponse = {
  success: boolean,
  path: string,
  url: string,
};

export type UploadedFile = {
  id: string,
  name: string,
  size: number,
  uploaded: string,
  path: string,
  mimeType: string,
  url: string,
};

export const useUniverseUploadStore = defineStore('universe-upload', () => {

  const uploadedSoundEffects = ref<SoundEffect[]>([]);
  const allUploadedFiles = ref<UploadedFile[]>([]);

  const uploadFile = async (file: File): Promise<UploadResponse | undefined> => {

    const formData = new FormData();
    formData.append('file', file);

    const { data: { session } } = await getSession();

    if (!session) {
      throw new Error('User is not authenticated');
    }

    const url = import.meta.env.VITE_UNIVERSE_FILE_UPLOAD_URL;

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        },
        body: formData,
      });

      const data = await response.json();

      return data;
    } catch (error) {
      console.error('Error uploading file:', error);
      return {
        success: false,
        path: '',
        url: '',
      };
    }
  };

  const deleteFile = async (id: string) => {

    const { data: { session } } = await getSession();

    if (!session) {
      throw new Error('User is not authenticated');
    }

    const url = import.meta.env.VITE_UNIVERSE_FILE_DELETE_URL + id;

    try {
      await fetch(url, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        },
      });
    } catch (error) {
      console.error('Error deleting file:', error);
    }

    await fetchListFiles();
  };

  const fetchListFiles = async () => {

    const { data: { session } } = await getSession();

    if (!session) {
      throw new Error('User is not authenticated');
    }

    const url = import.meta.env.VITE_UNIVERSE_LIST_FILES_URL;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${session.access_token}`,
        },
      });

      const data = await response.json();

      allUploadedFiles.value = data.files;

      uploadedSoundEffects.value = [];

      const soundFiles = data.files.filter((file: UploadedFile) => file.mimeType.startsWith('audio/')) as UploadedFile[];
      const promises = soundFiles.map(async (soundFile) => {

        const audio = new Audio(soundFile.url);

        const handleMetadataLoaded = () => {

          uploadedSoundEffects.value.push({
            id: soundFile.id,
            name: soundFile.name,
            url: soundFile.url,
            tags: ['uploaded'],
            startMs: 0,
            endMs: audio.duration * 1000,
            maxDurationMs: audio.duration * 1000,
            volume: 1,
          });
          
          // Clean up
          audio.removeEventListener('loadedmetadata', handleMetadataLoaded);
          audio.src = ""; // Release the media resource
        };

        audio.addEventListener('loadedmetadata', handleMetadataLoaded);
      });

      return await Promise.all(promises);
    } catch (error) {
      console.error('Error listing files:', error);
    }
  };

  return {
    uploadFile,
    allUploadedFiles,
    uploadedSoundEffects,
    fetchListFiles,
    deleteFile,
  };
});
