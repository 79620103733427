<script setup lang="ts">
import { useRoute } from 'vue-router';
import EditorExportComponent from './components/EditorExportComponent.vue';

const route = useRoute();

</script>

<template>
  <EditorExportComponent :key="route.fullPath" />
</template>