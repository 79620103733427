<script lang="ts" setup>
import logging from '@/logging'
import DownloadIcon from '../components/Icons/DownloadIcon.vue'
import { useRoute } from 'vue-router'
import { shareOrDownload } from '@/lib/download'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'
import { useHead } from '@unhead/vue'
import { Button } from '@/components/ui/button'

useHead({
  title: 'Download result',
  bodyAttrs: {
    class: 'bg-base-100',
  },
})

const route = useRoute()
const { showToast } = useToast()

const downloading = ref(false);
const downloadSrc = ref(decodeURIComponent(route.query.downloadSrc as string))

function onDownloadClicked() {

  if (downloading.value) {
    return;
  }
  
  downloading.value = true;

  shareOrDownload(downloadSrc.value)
    .then(() => {
      showToast({
        title: 'Clip downloaded successfully',
        type: toastEvents.TOAST,
      });

      logging.trackEvent('Video Result Downloaded', {
        location: 'QR Download Page',
      })
    })
    .finally(() => {
      downloading.value = false;
    });  
}
</script>

<template>
  <div class="container mx-auto flex flex-col items-center gap-4 pt-8 text-center">
    <h1 class="text-xl font-bold">Download result</h1>

    <p>
      <strong>iOS users</strong>: open this url in <strong>Safari</strong>, it's the only way to actually download the
      file.
    </p>

    <input type="text" class="input-bordered input w-full" :value="downloadSrc" />

    <Button
      v-if="downloadSrc"
      id="download-result-page-download-link"
      class="btn-primary btn-block btn"
      @click="onDownloadClicked"
      :disabled="downloading"
    >
      <Spinner v-if="downloading" class="animate-spin h-4 w-4 mr-2" />
      <DownloadIcon v-else class="mr-2 h-4 w-4" />
      Download
    </Button>

    <div class="align-items-center video-wrapper w-full">
      <video :src="downloadSrc" controls loop disableRemotePlayback />
    </div>
  </div>
</template>

<style scoped>
.video-wrapper > video {
  height: 50vh;
  display: block;
  margin: auto;
}

.controls {
  display: flex;
  align-items: center;
}
</style>
