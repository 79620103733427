
import type { CaptionsResponse } from '@/areas/editor/@type/Captions';
import { getSession } from '@/authentication/supabase';
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled';
import axios, { HttpStatusCode } from 'axios';

const UNIVERSE_API_BASE_URL = import.meta.env.VITE_UNIVERSE_API;

const PROFANITY_URL = `${ UNIVERSE_API_BASE_URL }/ai/filter-profanity`;
const SPEECH_TO_TEXT_URL = `${ UNIVERSE_API_BASE_URL }/ai/speech-to-text`;
const EMOJI_URL = `${ UNIVERSE_API_BASE_URL }/ai/emoji-relevance-tagger`;

const TEXT_TO_SPEECH_URL = `${ UNIVERSE_API_BASE_URL }/ai/text-to-speech`;

export const generateTextToSpeech = async (text: string, voice: string) => {

  const { data: { session } } = await getSession();

  if (!session) {
    throw new Error('User not logged in');
  }

  const response = await axios.post<{ audioUrl: string }>(
    TEXT_TO_SPEECH_URL,
    {
      text,
      voice,
    },
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  );

  return response.data;
};

const useElevenlabsTranscription = useFeatureFlagEnabled('elevenlabs-transcription')

export async function getTranscriptFor(mediaUrl: string, languageCode?: string): Promise<CaptionsResponse> {

  const { data: { session } } = await getSession();

  if (!session) {
    throw new Error('User not logged in');
  }

  const provider = useElevenlabsTranscription.value ? 'elevenlabs' : 'assemblyai'

  const response = await axios.post<CaptionsResponse>(
    SPEECH_TO_TEXT_URL,
    {
      url: mediaUrl,
      languageCode,
      provider,
    },
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  );

  if (response.status !== HttpStatusCode.Ok) {
    throw new Error('Failed to generate transcript');
  }

  return response.data;
}

export async function detectProfanity(transcript: string): Promise<string[]> {

  const { data: { session } } = await getSession();

  if (!session) {
    throw new Error('User not logged in');
  }
  
  const response = await axios.post<{ aiResponse: string }>(
    PROFANITY_URL,
    {
      variables: {
        transcription: transcript,
      }
    },
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  );

  if (response.status !== HttpStatusCode.Ok) {
    throw new Error('Failed to detect profanity');
  }

  if (!response.data.aiResponse) {
    throw new Error('Failed to detect profanity');
  }

  const text = response.data.aiResponse;

  const start = text.indexOf('[')
  const end = text.lastIndexOf(']')

  if (start === -1 && end === -1) {
    throw new Error('Failed to detect profanity');
  }

  const censoredWords = text.substring(start, end + 1);

  try {
    return JSON.parse(censoredWords);
  } catch (error) {
    console.error('Failed to parse profanity response', error);
    throw new Error('Failed to parse profanity response');
  }
}


export async function getAnimatedEmojis(text: string[]): Promise<string> {
  const { data: { session } } = await getSession();

  if (!session) {
    throw new Error('User not logged in');
  }  

  const response = await axios.post<{ aiResponse: string }>(
    EMOJI_URL,
    {
      variables: {
        text: JSON.stringify(text)
      }
    },
    {
      headers: {
        Authorization: `Bearer ${session.access_token}`,
      },
    }
  );

  return response.data.aiResponse;
}