import type { RouteRecordRaw } from 'vue-router'
import DashboardTemplatesOverviewPage from '@/areas/dashboard/pages/account/DashboardTemplatesOverviewPage.vue'
import DashboardLayout from '@/areas/dashboard/layout/DashboardLayout.vue'
import DashboardIndex from '@/areas/dashboard/pages/index/DashboardIndex.vue'
import DashboardEditorPage from '@/areas/dashboard/pages/DashboardEditorPage.vue'
import DashboardAnalyticsPage from '@/areas/dashboard/pages/analytics/DashboardAnalyticsPage.vue'
import DashboardContentPublisherPage from '@/areas/dashboard/pages/contentPublishing/DashboardContentPublisherPage.vue'
import DashboardVideosOverviewPage from '@/areas/dashboard/pages/DashboardVideosOverviewPage.vue'
import DashboardSocialsPage from '@/areas/dashboard/pages/account/DashboardSocialsPage.vue'
import DashboardAccountLayout from '@/areas/dashboard/pages/account/DashboardAccountLayout.vue'
import DashboardAccountIndex from '@/areas/dashboard/pages/account/DashboardAccountIndex.vue'
import DashboardBillingPage from '@/areas/dashboard/pages/account/DashboardBillingPage.vue'
import { dashboardRouteNames } from './routeNames'
import DashboardContentPublishPage from '@/areas/dashboard/pages/contentPublishing/DashboardContentPublishPage.vue'
import DashboardMontageMakerPage from '@/areas/dashboard/pages/DashboardMontageMakerPage.vue'
import DashboardEditorResultPage from '@/areas/dashboard/pages/DashboardEditorResultPage.vue'
import DashboardClipGptProjectPage from '@/areas/dashboard/pages/clipGPT/DashboardClipGptProjectPage.vue'
import DashboardClipGptIndex from '@/areas/dashboard/pages/clipGPT/DashboardClipGptIndex.vue'
import DashboardBrandPartnersPage from './pages/account/DashboardBrandPartnersPage.vue'
import DashboardClipDownloaderIndex from '@/areas/dashboard/pages/downloader/DashboardClipDownloaderIndex.vue'
import DashboardClipDownloaderResult from '@/areas/dashboard/pages/downloader/DashboardClipDownloaderResult.vue'
import DashboardProjectsOverviewPage from '@/areas/dashboard/pages/recent-projects/DashboardProjectsOverviewPage.vue';

export const routes: RouteRecordRaw[] = [
  {
    path: '/',
    component: DashboardLayout,
    name: dashboardRouteNames.root,
    meta: { requiresAuth: true },
    children: [
      {
        path: 'dashboard',
        name: dashboardRouteNames.dashboard,
        component: DashboardIndex,
      },
      {
        path: 'edit-clip',
        name: dashboardRouteNames.editor,
        component: DashboardEditorPage,
      },
      {
        path: 'edit-clip-result',
        name: dashboardRouteNames.editorResult,
        component: DashboardEditorResultPage,
      },
      {
        path: 'montage-maker',
        name: dashboardRouteNames.montageMaker,
        component: DashboardMontageMakerPage,
      },
      {
        path: 'analytics',
        name: dashboardRouteNames.analytics,
        component: DashboardAnalyticsPage,
      },
      {
        path: 'content-publisher',
        name: dashboardRouteNames.contentPublisher.root,
        component: DashboardContentPublisherPage,
      },
      {
        path: 'content-publisher/render/:renderId?',
        name: dashboardRouteNames.contentPublisher.render,
        component: DashboardContentPublishPage,
      },
      {
        path: 'content-publisher/post/:postId?',
        name: dashboardRouteNames.contentPublisher.post,
        component: DashboardContentPublishPage,
      },
      {
        path: 'content-publisher/video/:videoId?',
        name: dashboardRouteNames.contentPublisher.video,
        component: DashboardContentPublishPage,
      },
      {
        path: 'clip-gpt',
        name: dashboardRouteNames.clipGpt.root,
        component: DashboardClipGptIndex
      },
      {
        path: 'download',
        name: dashboardRouteNames.downloader.root,
        component: DashboardClipDownloaderIndex,
      },
      {
        path: 'download/:source(twitch-clip|youtube-clip)/:id',
        name: dashboardRouteNames.downloader.result,
        component: DashboardClipDownloaderResult,
      },
      {
        path: 'videos',
        name: dashboardRouteNames.videos,
        component: DashboardVideosOverviewPage,
      },
      {
        path: 'projects',
        name: dashboardRouteNames.projects,
        component: DashboardProjectsOverviewPage,
      },
      {
        path: 'socials',
        name: dashboardRouteNames.socials,
        component: DashboardSocialsPage,
      },
      {
        path: 'support',
        name: dashboardRouteNames.support,
        redirect: () => {
          window.location.href = 'https://guides.streamladder.com/';
          return '/';
        }
      },
      {
        path: 'account',
        name: dashboardRouteNames.account.root,
        component: DashboardAccountLayout,
        children: [
          {
            path: '',
            name: dashboardRouteNames.account.settings,
            component: DashboardAccountIndex,
          },
          {
            path: 'invoicing',
            name: dashboardRouteNames.account.invoicing,
            component: DashboardBillingPage,
          },
          {
            path: 'templates',
            name: dashboardRouteNames.account.templates,
            component: DashboardTemplatesOverviewPage,
          },
          {
            path: 'brand-partners',
            name: dashboardRouteNames.account.brandPartners,
            component: DashboardBrandPartnersPage,
          }
        ],
      },
    ],
  },
  {
    path: '/clip-gpt/:projectId([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})',
    name: dashboardRouteNames.clipGpt.project,
    meta: { requiresAuth: true },
    component: DashboardClipGptProjectPage,
  },
]
