<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import CaptionEditorV2 from '@/areas/editor/views/captions/v2/CaptionEditorV2.vue'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { Button } from "@/components/ui/button"
import { usePrevious } from '@vueuse/core'
import { PaletteIcon, SettingsIcon, SwatchBook, TextCursor } from 'lucide-vue-next'
import { computed, watch } from 'vue'
import CaptionV2StyleSelector from '../v2/CaptionV2StyleSelector.vue'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

const captionsStore = useCaptionsStore()

const tabs = ['text', 'presets']
const previous = usePrevious(captionsStore.captionsTab, 'text')
const direction = computed(() => Math.sign(tabs.indexOf(captionsStore.captionsTab) - tabs.indexOf(previous.value)))

watch(
  () => captionsStore.captionIdBeingEdited,
  (editingCaption) => {
    if (editingCaption) {
      captionsStore.captionsTab = 'text'
    }
  },
  { immediate: true }
)
</script>

<template>
  <TooltipProvider>
    <section class="flex flex-col 2xl:gap-2">
      <div class="flex items-center gap-2">
        <h2 class="font-title text-xl font-semibold leading-snug">Captions</h2>
      </div>

      <Tabs v-model="captionsStore.captionsTab">
        <div class="flex flex-row gap-1 items-center">
          <TabsList class="mt-2 flex w-full flex-row gap-1">
            <TabsTrigger key="text" value="text"
              class="w-full rounded-md hover:bg-white dark:hover:bg-black hover:text-foreground hover:shadow-md data-[state=active]:shadow-md">
              <TextCursor class="w-4" />
              Edit
            </TabsTrigger>
            <TabsTrigger key="presets" value="presets"
              class="w-full rounded-md hover:bg-white dark:hover:bg-black hover:text-foreground hover:shadow-md data-[state=active]:shadow-md">
              <SwatchBook class="w-4" />
              Styles
            </TabsTrigger>
          </TabsList>
          <div class="inline-flex items-center justify-center rounded-md bg-muted p-1 mt-2" v-if="captionsStore.baseCaptionPreset">
            <Tooltip>
              <TooltipTrigger as-child>
                <Button type="button"
                  class="inline-flex border-none items-center justify-center px-3 py-1.5 h-auto text-sm font-medium bg-muted ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 text-muted-foreground w-full rounded-md hover:bg-white dark:hover:bg-black hover:text-foreground hover:shadow-md"
                  @click="captionsStore.sidebarPage = 'settings'">
                  <span>
                    <PaletteIcon class="w-4" />
                    <span class="sr-only">Customize your style settings</span>
                  </span>
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                Customize your style settings
              </TooltipContent>
            </Tooltip>
          </div>
        </div>

        <Transition appear
          :enter-from-class="direction === -1 ? 'opacity-0 -translate-x-12' : 'opacity-0 translate-x-12'"
          :leave-to-class="direction === -1 ? 'opacity-0 translate-x-12' : 'opacity-0 -translate-x-12'"
          enter-active-class="motion-safe:transition-[transform,_opacity]"
          leave-active-class="motion-safe:transition-[transform,_opacity]">
          <section :key="captionsStore.captionsTab" class="absolute mt-2 w-full pb-4">
            <TabsContent value="text">
              <CaptionEditorV2 />
            </TabsContent>
            <TabsContent value="presets">
              <div class="my-1 h-px bg-surface-panel-border" />
              <CaptionV2StyleSelector />
            </TabsContent>
          </section>
        </Transition>
      </Tabs>
    </section>
  </TooltipProvider>
</template>

<style scoped></style>
