import type { RenderInstructions } from '@/areas/editor/@type/render-instructions';
import { debounce, merge } from 'lodash-es';
import localStorageApi from '@/areas/editor/pages/api/localStorageApi';
import { storeProject } from '@/queries/projects/projectsApi';
import type { EditorProject } from '@/areas/editor/@type/editor-project';

let previous: EditorProject | null = null;

export default {
  async save(request: HistoryStateChange, projectId: string) {

    await debouncedStoreRenderInstructions(request.snapshot, projectId);

    if (request.transaction === 'CROP:MOVE' || request.transaction === 'CROP:RESIZE') {
      localStorageApi.saveCropAreaPreferences(request.snapshot, request.videoWidth, request.videoHeight);
    }
    
    if (request.transaction === 'LAYOUT:CHANGE') {
      localStorageApi.saveLayoutPreference(request.snapshot, previous);
    }

    previous = request.snapshot;
  }
}

const debouncedStoreRenderInstructions = debounce(async (snapshot: EditorProject, projectId: string) => {
  await storeProject(merge(snapshot, { id: projectId }));
}, 1000, { leading: true })

export interface HistoryStateChange {
  transaction: string;
  instructions: RenderInstructions;
  snapshot: EditorProject;
  videoWidth: number;
  videoHeight: number;
}
