<script setup lang="ts">
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import { IframeMessageBus } from '@/areas/editor/pages/api/IframeMessageBus';
import { useUserInfoStore } from '@/store/user/userInfo';
import { useVideoEditorTheme } from '@/areas/editor/pages/config/theme';
import { merge } from 'lodash-es';

interface Props {
  project: EditorProject;
  withWatermark?: boolean;
}

const props = defineProps<Props>();

const iframe = ref<HTMLIFrameElement | null>(null);
const iframeMessageBus = new IframeMessageBus(iframe, 'preview', () => startProject(props.project));

function startProject(project: EditorProject) {

  const editorProject: EditorProject = JSON.parse(JSON.stringify(project));
  const config = {
    appId: 'b70d6ef4-7c85-44ef-adf5-41107d032417',
    baseUrl: window.location.origin,
    editorProject: props.withWatermark
      ? merge(editorProject, { watermarkUrl: 'https://app.streamladder.com/images/watermark.png' })
      : editorProject,
    theme: theme.value,
    user: { tier: userInfoStore.tier }
  };

  iframeMessageBus.emit('MOUNT_VIDEO_PREVIEW', { config: config });
}

const userInfoStore = useUserInfoStore();
const theme = useVideoEditorTheme();

watch(() => props.project, (project) => startProject(project), { immediate: true });

watch(theme, (theme) => iframeMessageBus.emit('SET_THEME', { theme }));
watch(() => userInfoStore.tier, (tier) => iframeMessageBus.emit('UPDATE_USER', { user: { tier: tier } }));

</script>

<template>
  <div class="border-4 border-black rounded-2xl bg-black">
    <div class="min-h-[300px] lg:min-h-[400px] aspect-[9/16]">
      <iframe ref="iframe" class="col-start-1 row-start-1 h-full w-full rounded-xl overflow-hidden" />
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
