<script setup lang="ts">
import { ScrollArea } from '@/components/ui/scroll-area'
import ZoomsPageSection from '@/areas/editor/views/effects/sections/ZoomsPageSection.vue'
import { useFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled'
import CensorWordsEffect from '@/areas/editor/views/effects/sections/CensorWordsEffect.vue'

const canBleepCurseWords = useFeatureFlagEnabled('bleep-curse-words')
</script>

<template>
  <ScrollArea class="h-full overflow-y-auto md:min-h-screen lg:max-h-screen lg:pb-24">
    <div class="flex flex-col gap-4 p-4 pb-0 lg:pb-4 2xl:p-8">
      <header>
        <h2 class="font-title text-xl font-semibold leading-snug">Effects</h2>
        <p class="font-light">Add effects to enhance your clip</p>
      </header>

      <div class="h-px bg-surface-panel-border" />
      <ZoomsPageSection />

      <template v-if="canBleepCurseWords">
        <CensorWordsEffect />
      </template>
    </div>
  </ScrollArea>
</template>

<style scoped lang="scss"></style>
