<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useFontsStore } from '@/store/fonts'
import BackgroundStylesSelector from './_components/BackgroundStylesSelector.vue'
import FontSizeSelector from './_components/FontSizeSelector.vue'
import GlowStylesSelector from './_components/GlowStylesSelector.vue'
import OutlineStylesSelector from './_components/OutlineStylesSelector.vue'
import ShadowStylesSelector from './_components/ShadowStylesSelector.vue'
import FillStylesSelector from '@/areas/editor/views/captions/tabs/settings/_components/FillStylesSelector.vue'
import type { FontLabel } from '@/data/fonts'

const captionsStore = useCaptionsStore()

const fontsStore = useFontsStore()
const isLoadingFonts = ref(true)

onMounted(async () => {
  await fontsStore.initialize()
  isLoadingFonts.value = false
})

const fontFamilyModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.font.fontFamily
  },
  set(value: FontLabel) {
    emit('changed')
    captionsStore.baseCaptionPreset.font.fontFamily = value
  },
})

const emit = defineEmits<{ (e: 'changed'): void }>()
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <section v-if="captionsStore.baseCaptionPreset.font.editable">
    <div class="flex flex-col gap-3 py-2">
      <h6 class="m-0 mt-4 text-sm font-semibold">Text</h6>

      <div v-if="!isLoadingFonts" class="flex flex-col gap-3">
        <Select v-model="fontFamilyModel">
          <SelectTrigger class="hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg">
            <SelectValue
              placeholder="Choose a font"
              :style="{ fontFamily: fontFamilyModel }"
            />
          </SelectTrigger>

          <SelectContent>
            <SelectItem
              v-for="font in fontsStore.fonts"
              :key="font.url"
              :value="font.label"
              :style="{ fontFamily: font.label }"
            >
              {{ font.label }}
            </SelectItem>
          </SelectContent>
        </Select>

        <FontSizeSelector @changed="emit('changed')" />
      </div>

      <FillStylesSelector v-if="captionsStore.baseCaptionPreset.font.editable" @changed="emit('changed')" />
      <OutlineStylesSelector v-if="captionsStore.baseCaptionPreset.stroke.editable" @changed="emit('changed')" />
      <ShadowStylesSelector v-if="captionsStore.baseCaptionPreset.shadow.editable" @changed="emit('changed')" />
      <GlowStylesSelector v-if="captionsStore.baseCaptionPreset.glow.editable" @changed="emit('changed')" />
      <BackgroundStylesSelector v-if="captionsStore.baseCaptionPreset.background.editable" @changed="emit('changed')" />
    </div>
  </section>
</template>

<style scoped></style>
