<script setup lang="ts">
import { ColorInput } from '@/components/colors'
import { Button } from '@/components/ui/button'
import type { Gradient } from '@/components/Captions/v3/CaptionPreset'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'

const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors()

const captionsStore = useCaptionsStore()
const historyStore = useHistoryStore()

const emit = defineEmits<{ (e: 'changed'): void }>()

const fontColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.font.color
  },
  set(color: string | Gradient) {
    captionsStore.baseCaptionPreset.font.color = color
    emit('changed')
  },
})

const isGradient = computed(() => typeof fontColorModel.value !== 'string')
const gradientData = computed(() => fontColorModel.value as Gradient)

const cssGradient = computed(() => {
  if (!isGradient.value) {
    return fontColorModel.value as string
  }

  const sortedStops = [...gradientData.value.colorStops].sort((a, b) => a.stop - b.stop)
  const stopsString = sortedStops.map((stop) => `${stop.color} ${stop.stop * 100}%`).join(', ')

  if (gradientData.value.variant === 'linear') {
    return `linear-gradient(${gradientData.value.angle + 90}deg, ${stopsString})`
  }
  if (gradientData.value.variant === 'radial') {
    return `radial-gradient(${stopsString})`
  }
  return ''
})

function updateFontColorCallback(color: string | Gradient) {
  if (typeof color === 'string') {
    setRecentlyUsedColor(color)
  } else {
    for (const stop of color.colorStops) {
      setRecentlyUsedColor(stop.color)
    }
  }
  captionsStore.savePreset(captionsStore.currentUserCaption!)
}
</script>

<template>
  <div class="w-full items-center rounded-lg border border-input bg-background p-3">
    <div class="flex flex-row items-center gap-3">
      <ColorInput
        v-model="fontColorModel"
        class="w-full shrink-0"
        side="right"
        allow-gradient
        @commit-value="historyStore.transaction('CAPTIONS:CHANGE_COLOR')"
        @close="updateFontColorCallback"
        :recently-used-colors="recentlyUsedCaptionColors"
        :recommended-colors="recommendedColors.LIGHT"
      >
        <Button variant="toggle" class="h-auto border-none bg-transparent p-0 shadow-none">
          <span :style="{ background: cssGradient }" class="h-7 w-7 rounded border border-border" />
        </Button>
      </ColorInput>

      <span class="text-sm font-semibold">Fill</span>
    </div>
  </div>
</template>

<style scoped lang="scss"></style>
