<script setup lang="ts">
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { RadioGroupItem, RadioGroupRoot } from 'radix-vue'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()

const animationModel = computed({
  get() {
    return captionsStore.baseOptions.animation
  },
  set(value) {
    historyStore.transaction('CAPTIONS:CHANGE_ANIMATION', () => {
      captionsStore.baseOptions.animation = value
    })
  }
})
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="flex flex-col gap-2 mt-4">

    <section class="z-20 flex flex-col gap-2">

      <footer class="text-black dark:text-white">
        <RadioGroupRoot
          v-model="animationModel" 
          default-value="default"
          aria-label="View density"
        >
          <div class="grid grid-cols-3 gap-2.5">
            <RadioGroupItem
              id="none"
              value=""
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === '' }"
              >
                <span class="font-bold">None</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="reveal"
              value="reveal"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'reveal' }"
              >
                <div class="reveal font-bold">Reveal</div>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="fade-in"
              value="fade-in"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'fade-in' }"
              >
                <div class="fade-in font-bold">Fade in</div>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="fade-right"
              value="fade-right"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square relative group overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'fade-right' }"
              >
                <div
                  class="absolute z-1 top-0 left-0 w-full h-full overlay fade-right bg-surface-panel group-hover:bg-gray-100 dark:group-hover:bg-zinc-500"
                  :class="{ '!bg-primary': captionsStore.baseOptions.animation === 'fade-right' }"
                />
                <span class="font-bold">Fade right</span>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="slide-right"
              value="slide-right"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'slide-right' }"
              >
                <div class="slide-right font-bold">Slide right</div>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="slide-top"
              value="slide-top"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'slide-top' }"
              >
                <div class="slide-top font-bold">Slide top</div>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="shrink"
              value="shrink"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'shrink' }"
              >
                <div class="shrink font-bold">Shrink</div>
              </div>
            </RadioGroupItem>
            <RadioGroupItem
              id="bounce"
              value="bounce"
            >
              <div
                class="flex items-center justify-center text-lg 2xl:text-2xl aspect-square overflow-hidden border border-surface-panel-border bg-surface-panel rounded-lg font-light transition-colors hover:bg-gray-100 dark:hover:bg-zinc-500"
                :class="{ '!bg-primary text-white font-semibold cursor-default': captionsStore.baseOptions.animation === 'bounce' }"
              >
                <div class="bounce font-bold">Bounce</div>
              </div>
            </RadioGroupItem>
          </div>
        </RadioGroupRoot>
      </footer>
    </section>
  </div>
</template>

<style scoped lang="scss">

@keyframes reveal {
  0% {
    opacity: 0;
    transform: scale(0.2);
  }
  15% {
    opacity: 1;
    transform: scale(1.2);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-right {
  0% {
    opacity: 0;
    transform: translateX(-50%);
  }
  40% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes slide-top {
  0% {
    opacity: 0;
    transform: translateY(75%);
  }
  20% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
  }
}

@keyframes shrink {
  0% {
    opacity: 0;
    transform: scale(2.5);
  }
  15% {
    opacity: 1;
    transform: scale(1);
  }
  30% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bounce {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  15% {
    opacity: 1;
    transform: scale(0.8);
  }
  30% {
    transform: scale(1.2);
  }
  40% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fade-right {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(100%);
  }
}

/* Attach animations */
.reveal {
  animation: reveal 1.1s linear infinite;
}
.fade-in {
  animation: fade-in 1.2s linear infinite;
}
.slide-right {
  animation: slide-right 1.1s ease-out infinite;
}
.slide-top {
  animation: slide-top 1.3s ease infinite;
}
.shrink {
  animation: shrink 1.25s ease-out infinite;
}
.bounce {
  animation: bounce 1.1s ease-out infinite;
}
.fade-right {
  animation: fade-right 1s linear infinite;
}
</style>
