<script setup lang="ts">
import { Button } from '@/components/ui/button';
import IconSaxScissor from '@/components/Icons/iconsax/IconSaxScissor.vue';
import { cn } from '@/lib/utils';
import type { ProjectOverviewItem } from '@/areas/dashboard/pages/recent-projects/ProjectOverviewItem';
import { useRouter } from 'vue-router'
import logging from '@/logging'
import type { RenderEntity } from '@/areas/editor/@type/render-instructions/RenderEntity';
import { getApiTwitchClipsClipId } from '@/apis/streamladder-api/twitch-clips/twitch-clips';
import { sumBy } from 'lodash-es';
import type { RenderInstructions } from '@/areas/editor/@type/render-instructions';
import { getClipSlug } from '@/areas/editor/startup/repairTwitchClip';

interface Props {
  project: ProjectOverviewItem
}


const props = defineProps<Props>();

function formatTimeAgo(ms: number) {
  if (ms < 2 * 60 * 1000) {
    return 'Just now';
  } else if (ms < 60 * 60 * 1000) {
    return `${Math.floor(ms / (60 * 1000))} minutes ago`;
  } else if (ms < 24 * 60 * 60 * 1000) {
    return `${Math.floor(ms / (60 * 60 * 1000))} hours ago`;
  } else {
    return `${Math.floor(ms / (24 * 60 * 60 * 1000))} days ago`;
  }
}

function formatTimeSpan(ms: number) {
  const hours = Math.floor(ms / (60 * 60 * 1000));
  const minutes = Math.floor((ms % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((ms % (60 * 1000)) / 1000);
  if (hours > 0) {
    return `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  } else {
    return `${minutes}:${String(seconds).padStart(2, '0')}`;
  }
}

const isLoaded = ref(false);
const isError = ref(false);

const router = useRouter();

const onClick = async () => {
  logging.trackEvent('Dashboard Project Clicked', { projectId: props.project.id });
  await router.push({ name: 'editor/[projectId]', params: { projectId: props.project.id } });
};


const isRepairing = ref(false);
async function onVideoError() {
  if (isRepairing.value) return;
  isRepairing.value = true;

  isError.value = true;

  if (videoUrl.value?.includes('twitch-clips-v2.b-cdn.net')) {
    const clipId = getClipSlug(videoUrl.value);
    if (clipId) {
      const result = await getApiTwitchClipsClipId(clipId);
      if (result.mp4Url) {  
        isError.value = false;
        videoUrl.value = result.mp4Url as string; 
        return;
      }
    }
  }
}

const videoUrl = ref<string | undefined>(undefined);

watch(() => props.project, () => {
  if ('mp4Url' in props.project.json) {
    videoUrl.value = props.project.json.mp4Url;
  } else if ('sources' in props.project.json) {
    videoUrl.value = props.project.json.sources?.[0]?.url;
  } else if (props.project.json.segments?.[0]?.sourceUrl) {
    videoUrl.value = props.project.json.segments?.[0]?.sourceUrl;
  } else {
    videoUrl.value = (props.project.json as unknown as RenderInstructions)?.entities
      ?.find((e): e is RenderEntity<'video'> => e.type === 'video')
      ?.url;
  }
}, { deep: true, immediate: true, once: true });

const durationMs = computed(() => {
  const json = props.project.json;
  if ('durationMs' in json) {
    return json.durationMs ?? 0;
  } else {
    return sumBy(json.segments, s => s.endMs - s.startMs);
  }
});
</script>

<template>
  <Button
    as-child
    class="relative p-0 h-auto flex flex-col items-start gap-1 !bg-transparent !border-transparent"
    variant="toggle"
  >
    <div @click="onClick" :class="{ 'pointer-events-none': isError }">
      <div class="w-full aspect-video rounded-lg overflow-hidden grid grid-cols-1 grid-rows-1 bg-zinc-200 relative" data-video-container>
        <div 
          :class="cn('col-start-1 row-start-1 text-muted-foreground size-full bg-gradient-to-br from-[hsl(285,100%,93%)] to-[hsla(240,100%,84%)] font-light grid place-items-center transition opacity-0 scale-90', {
            'opacity-100 scale-100': isError
          })"
        >
          <p class="text-wrap text-center p-4 text-black">Source video does not exist anymore. Project is unavailable 😔</p>
        </div>
        <video
          :key="videoUrl"
          :src="videoUrl"
          @error="onVideoError()"
          @loadeddata="isLoaded = true"
          :class="cn('col-start-1 row-start-1 size-full object-cover group-hover:scale-110 transition opacity-0 scale-90', {
            'opacity-100 scale-100': isLoaded
          })"
        />

        <template v-if="!isError">
          <div class="absolute bottom-1 right-1 leading-1 bg-black/75 text-sm text-white py-px px-1 rounded">
            {{ formatTimeSpan(durationMs) }}
          </div>

          <div class="absolute opacity-0 group-hover:opacity-100 backdrop-blur bg-black/50 transition inset-0 grid place-items-center text-white">
            <Button>
              <IconSaxScissor />
              Edit project
            </Button>
          </div>
        </template>
      </div>

      <h3 class="font-bold font-title block w-full line-clamp-1 overflow-ellipsis">{{ project.json.title || 'Untitled' }}</h3>
      <span class="leading-[1] text-sm font-light text-muted-foreground">
        Last edited {{ formatTimeAgo(project.time_ago_ms).toLowerCase() }}
      </span>
    </div>
  </Button>
</template>

<style scoped lang="scss">

</style>
