import { getApiCustomStickers, deleteApiCustomStickersCustomStickerId, postApiCustomStickers } from '@/apis/streamladder-api/custom-stickers/custom-stickers';
import uploadService from '@/services/uploadService';
import { requestUserSignInAsync } from '@/authentication/supabase';
import { onUserInfoReadyAsync } from '@/store/user/userInfo';
import { canGuard } from '@/Hooks/useGuard';

function dateStringToMs(dateString: string | undefined) {
  return new Date(dateString ?? 0).getTime();
}

export default {

  async list() {

    const { isAuthenticated } = await onUserInfoReadyAsync();
    if (!isAuthenticated) {
      return [];
    }

    return (await getApiCustomStickers())
      .sort((a, b) => dateStringToMs(b.createdDate) - dateStringToMs(a.createdDate))
      .map(s => ({
        type: 'image',
        fileUrl: s.fileUrl,
      }));
  },

  async upload({ buffer, fileName }: UploadRequestBody, onProgress: (progress: number) => void) {

    const isSignedIn = await requestUserSignInAsync();
    if (!isSignedIn) {
      throw new Error('User not signed in');
    }
    
    if (!canGuard('stickers')) {
      throw new Error('User not allowed to upload stickers');
    }
    
    const result = await uploadService.getUploadCustomStickerSignedUrl()
    const file = new File([new Blob([buffer])], fileName);
    await uploadService.uploadFileS3(result.signedUrl, file, onProgress);
    await postApiCustomStickers({ fileUrl: result.resultUrl });
  },
  
  async delete(request: { fileUrl: string }) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      const stickers = await getApiCustomStickers();
      const sticker = stickers.find(s => s.fileUrl === request.fileUrl);
      if (!sticker?.id) {
        throw new Error('Sticker not found');
      }

      await deleteApiCustomStickersCustomStickerId(sticker.id);
    }
  }
}

interface UploadRequestBody {
  buffer: ArrayBuffer;
  fileName: string;
}
