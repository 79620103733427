import type { Snapshot } from '@/areas/editor/@type/editor-project/Snapshot';
import type { Crop } from '@/areas/editor/@type/Project';
import type { CropSource } from '@/areas/editor/@type/editor-project/CropSource';

export default {

  saveLayoutPreference(next: Snapshot, previous: Snapshot | null) {
    if (!previous) {
      const presetId = next.layouts?.[0]?.presetId;
      if (presetId) {
        localStorage.setItem('defaultPreset', presetId);
      }
    } else {
      for (const segment of next.segments ?? []) {
        const layout = next.layouts?.find((l) => l.id === segment.layoutId);
        const previousSegment = previous.segments?.find((s) => s.id === segment.id);
        const previousLayout = previous.layouts?.find((l) => l.id === previousSegment?.layoutId);
        if (previousLayout && layout?.presetId && layout?.presetId !== previousLayout.presetId) {
          localStorage.setItem('defaultPreset', layout.presetId);
        }
      }
    }
  },

  saveCropAreaPreferences(snapshot: Snapshot, videoWidth: number, videoHeight: number) {
    for (const layout of snapshot.layouts ?? []) {
      const presetId = layout.presetId;
      const crops = (snapshot.crops ?? []).filter((c) => c.layoutId === layout.id);
      for (let i = 0; i < crops.length; i++) {
        const crop = crops[i];
        const cropSource = snapshot.cropSources?.find((s) => s.cropId === crop.id);
        if (cropSource) {
          this.saveCropAreaPreference(presetId, i, crop, cropSource, videoWidth, videoHeight);
        }
      }
    }

    for (const segment of snapshot.segments ?? []) {
      const crops = (snapshot.crops ?? []).filter((c) => c.segmentId === segment.id);
      for (let i = 0; i < crops.length; i++) {
        const crop = crops[i];
        const cropSource = snapshot.cropSources?.find((s) => s.cropId === crop.id);
        if (cropSource) {
          this.saveCropAreaPreference(segment.presetId, i, crop, cropSource, videoWidth, videoHeight);
        }
      }
    }
  },

  saveCropAreaPreference(presetId: string, index: number, crop: Crop, source: CropSource, videoWidth: number, videoHeight: number) {
    localStorage.setItem(`last-used-crop-area:${presetId}:${index}`, JSON.stringify({
      feedData: crop.feedData,
      input: crop.input,
      x: source.x, y: source.y,
      width: source.width, height: source.height,
      videoWidth: videoWidth,
      videoHeight: videoHeight,
    }));
  },
  
  loadCropAreaPreferences(presetId: string, index: number) {
    const cropAreaPreference = localStorage.getItem(`last-used-crop-area:${presetId}:${index}`);
    if (cropAreaPreference) {
      return JSON.parse(cropAreaPreference);
    } else {
      return null;
    }
  },
}
