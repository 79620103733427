<script lang="ts" setup>
import { onUserInfoReady, useUserInfoStore } from '@/store/user/userInfo'
import { useTheme } from '@/Hooks/useTheme';
import { useRouter } from 'vue-router'
import { compatibilityChecker } from '@/lib/compatibilityChecker'
import { usePutApiUserUpdateDisplayName } from '@/apis/streamladder-accounts/user/user';
import logging from '@/logging';
import { requestUserSignInAsync } from '@/authentication/supabase';
import { useEventListener } from '@vueuse/core';

const iframeUrl = ref('');
const isLoading = ref(true);
const { colorMode } = useTheme();
const userInfoStore = useUserInfoStore();
const router = useRouter();
const host: string = 'streamladder-schedulemaker.pages.dev';

useHead({
  title: 'ScheduleMaker',
  meta: [
    {
      name: 'description',
      content: 'Welcome to ScheduleMaker! Share your twitch schedule to your socials with ease!'
    }
  ]
});

onUserInfoReady(() => {
  updateIframe();
})

function updateIframe(){
  iframeUrl.value = `https://${host}/?username=${userInfoStore.userName}&loggedin=${userInfoStore.isAuthenticated}&theme=${colorMode.value}`
}

function onFrameLoad() {
  setTimeout(() => {
    isLoading.value = false;
  }, 100);
}

const {
  mutate: updateDisplayName,
} = usePutApiUserUpdateDisplayName({
  mutation: {
    onSuccess(data) {
      const { userName } = data as unknown as { userName: string }
      userInfoStore.updateDisplayName(userName)
      updateIframe()
    },
    onError(error) {
      Sentry.captureException(error)
    },
  },
})

async function handleMessage(event: MessageEvent) {
  switch(event.data.command) {
    case "LOG":
      if (event.data.logType && event.data.logData){
        logging.trackEvent(event.data.logType, event.data.logData);
      }
      break;
    case "REDIRECT":
      if (event.data.to){
        router.push(event.data.to);
      }
      break;
    case "DOWNLOAD":
      if (event.data.dataUrl && event.data.username){
        downloadOrShare(event.data.dataUrl, event.data.username);
      }
      break;
    case "SIGNIN":
      if (await requestUserSignInAsync()){
        updateIframe()
      }
      break;
    case "NEWUSER":
      if (event.data.username){
        updateDisplayName({ data: { displayName: event.data.username } });
      }
      break;
  }
}

async function downloadOrShare(dataUrl: string, username: string){
  const response = await fetch(dataUrl);
  const blob = await response.blob();

  const filename = `ScheduleMaker ${ username } ${ new Date().toLocaleDateString() }.png`;
  const file = new File([blob], filename, { type: 'image/png' });
  const canShare = navigator.canShare?.({ files: [file] });

  if (canShare && compatibilityChecker.isMobile){
    await navigator.share({
      files: [file],
      text: 'Stream Schedule',
    });
  }
  else{
    const link = document.createElement('a');
    link.download = filename;
    link.href = dataUrl;
    link.click();
  }
}

useEventListener('message', handleMessage);
</script>

<template>
  <main class='fixed top-0 left-0 w-full h-full m-0 p-0 overflow-hidden bg-gradient-to-b from-[#E8F6FF] to-[#FBE8FF] bg-fixed'>
    <iframe
      :src="iframeUrl"
      class="w-full h-full border-none" :style="{ visibility: isLoading ? 'hidden' : 'visible' }"
      frameborder="0"
      allowfullscreen
      @load="onFrameLoad"
    ></iframe>
  </main>
</template>

<style scoped>
</style>