<script setup lang="ts">
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import { editorRouteNames } from '@/areas/editor/routeNames'
import CensorWordsIcon from '@/components/Icons/CensorWordsIcon.vue'
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'

const userInfoStore = useUserInfoStore()
const { currentSection } = useEditorStep()

const videoLoaded = ref(false)
</script>

<template>
  <div
    class="flex cursor-pointer flex-col gap-4 rounded-xl border border-surface-panel-border bg-surface-panel p-3 hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
    @click="currentSection = editorRouteNames.censorWords"
  >
    <section class="flex flex-row items-center justify-between gap-2">
      <header class="flex flex-row items-center gap-2">
        <div class="grid size-12 place-items-center rounded-xl bg-gradient-to-r from-[#FAE6FF] to-[#DAF1FF]">
          <CensorWordsIcon class="size-12" />
        </div>
        <div class="flex flex-col">
          <h3 class="text-lg font-semibold">
            AI - Word censoring
            <span class="rounded-full bg-green-700 px-2 py-1 text-xs font-medium text-background"> Beta </span>
          </h3>
          <p class="text-sm font-light text-muted-foreground">Make your videos TikTok friendly.</p>
        </div>
      </header>

      <GoldPlanButton
        v-if="userInfoStore.tier !== tiers.GOLD"
        class="pointer-events-none -mt-0.5"
        :can-click="false"
        :small="true"
      />
    </section>

    <div class="hidden max-h-[35vh] max-w-[calc(35vh*calc(16/9))] place-items-center md:grid">
      <div class="relative w-full">
        <div
          v-if="!videoLoaded"
          :class="{ 'opacity-0': videoLoaded }"
          class="skeleton !absolute inset-0 rounded-lg bg-white/50 transition-opacity"
        />
        <video
          class="relative w-full rounded-xl"
          :class="{ 'opacity-0': !videoLoaded }"
          @canplay.once="videoLoaded = true"
          :src="'/videos/new-spotlight/ai-word-censoring.webm'"
          :width="1784"
          :height="1080"
          autoplay
          loop
          muted
          playsinline
          disablePictureInPicture
          disableRemotePlayback
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
