<template>
  <Tile
    :aspect="0.6"
    :selected="selected"
    class="group !p-0"
    feature="captions"
    tabindex="0"
    @mouseleave="stopAnimation"
    @mouseover="startAnimation"
    @resize="resize"
    @click="onClick({ navigate: false })"
  >
    <div class="h-full w-full">
      <div
        class="grid h-full w-full place-items-center p-2 transition-[transform,_opacity]"
        :class="{ 'scale-75 opacity-0': !ready }"
      >
        <CaptionElement
          v-if="!isActive"
          key="key-34234"
          :caption="captions[0]"
          :preset="preset"
          :caption-style-definition="captionSettings"
          :enable-time-line-animation="false"
          class="text !relative"
          @ready="ready = true"
        />
        <CaptionElement
          v-for="(caption, index) in captions"
          v-else
          v-show="index === counter + 1"
          :key="caption.id"
          :caption="caption"
          :preset="preset"
          :enable-time-line-animation="false"
          :caption-style-definition="captionSettings"
          class="text !relative"
        />
      </div>
    </div>

    <OverlayBadges :is-new="isNewCaptionPreset(preset)" :tags="captionSettings.tags" />

    <LoginDialog v-if="!userInfoStore.isLoggedIn" :open="openLoginDialog">
      <Button
        variant="primary"
        class="absolute right-2 top-2 z-10 h-auto px-2 py-1 text-sm group-hover:block md:hidden"
        @click.prevent.stop="openLoginDialog = true"
      >
        Edit style
      </Button>
    </LoginDialog>
    <Tooltip v-else disable-hoverable-content>
      <TooltipTrigger as-child>
        <Button
          class="absolute right-2 top-2 z-10 h-auto px-2 py-1 text-sm group-hover:block md:hidden"
          variant="primary"
          @click.prevent.stop="onClick({ navigate: true })"
        >
          Edit style
        </Button>
      </TooltipTrigger>
      <TooltipContent>Edit this style</TooltipContent>
    </Tooltip>
  </Tile>
</template>

<script lang="ts" setup>
import CaptionElement from '@/components/Captions/CaptionElement.vue'
import type { CaptionStyleSettings } from '@/components/Captions/styles/CaptionStyleManager'
import { useCaptionSettings } from '@/components/Captions/useCaptionSettings'
import type { CaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import OverlayBadges from '@/components/OverlayBadges.vue'
import Tile from '@/components/Tile.vue'
import { Button } from '@/components/ui/button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/tooltip'
import { isNewCaption, isNewCaptionPreset } from '@/helpers/isNewOverlayElement';
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useInterval } from '@vueuse/core'
import { ref } from 'vue'

const props = defineProps<{
  preset: CaptionPreset
  settings: CaptionStyleSettings
  selected?: boolean
}>()

const emit = defineEmits<{ (e: 'select', preset: CaptionPreset, { navigate }: { navigate: boolean }): void }>()

const userInfoStore = useUserInfoStore()

const { resize, captionSettings, captions, animationSpeed } = useCaptionSettings(props.settings, {
  width: 0, // Changing this value does not seem to have any effect
  height: 200,
})

const { counter, reset, pause, resume, isActive } = useInterval(animationSpeed, {
  controls: true,
  immediate: false,
  callback: () => {
    if (captions.length - 1 === counter.value) {
      reset()
    }
  },
})

const ready = ref(false)
const openLoginDialog = ref(false)

const startAnimation = () => {
  resume()
}

const stopAnimation = () => {
  pause()
  reset()
}

function onClick({ navigate }: { navigate: boolean }) {
  if (navigate && !userInfoStore.isLoggedIn) {
    openLoginDialog.value = true
  } else {
    emit('select', props.preset, { navigate })
  }
}
</script>
