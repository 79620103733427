import { getSession, requestUserSignInAsync } from '@/authentication/supabase';
import type { UploadedFile } from '@/areas/editor/store/useUniverseUploadStore';
import { onUserInfoReadyAsync } from '@/store/user/userInfo';

async function list() {

  const { isAuthenticated } = await onUserInfoReadyAsync();
  if (!isAuthenticated) {
    return [];
  }

  const { data: { session } } = await getSession();
  if (!session) {
    throw new Error('User is not authenticated');
  }

  const url = import.meta.env.VITE_UNIVERSE_LIST_FILES_URL;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${session.access_token}`,
    },
  });

  const data = await response.json();
  return data.files as UploadedFile[];
}

async function upload(request: UploadSoundEffectRequestBody, onProgress: (progress: number) => void) {

  const isSignedIn = await requestUserSignInAsync();
  if (isSignedIn) {

    const { data: { session } } = await getSession();
    if (!session) {
      throw new Error('User is not authenticated');
    }

    const formData = new FormData();
    formData.append('file', new File([new Blob([request.buffer])], request.fileName));
    const url = import.meta.env.VITE_UNIVERSE_FILE_UPLOAD_URL;

    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${session.access_token}`);

    xhr.upload.addEventListener('progress', (event: ProgressEvent) => {
      if (event.lengthComputable) {
        onProgress((event.loaded / event.total) * 100);
      }
    });

    xhr.send(formData);

    const response = await new Promise<XMLHttpRequest>((resolve, reject) => {
      xhr.onload = () => resolve(xhr);
      xhr.onerror = () => reject(xhr);
    });

    return JSON.parse(response.responseText);
  } else {
    throw new Error('User is not signed in');
  }
}

async function remove(request: RemoveSoundEffectRequestBody) {

  const isSignedIn = await requestUserSignInAsync();
  if (isSignedIn) {

    const { data: { session } } = await getSession();
    if (!session) {
      throw new Error('User is not authenticated');
    }

    const files = await list();
    const file = files.find(f => f.url === request.fileUrl);
    if (!file) {
      throw new Error('File not found');
    }

    const url = import.meta.env.VITE_UNIVERSE_FILE_DELETE_URL + file.id!;

    await fetch(url, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${session.access_token}`,
      },
    });
  } else {
    throw new Error('User is not signed in');
  }
}

export default {
  list: list,
  upload: upload,
  delete: remove,
}

export interface UploadSoundEffectRequestBody {
  buffer: ArrayBuffer;
  fileName: string;
}

export interface RemoveSoundEffectRequestBody {
  fileUrl: string;
}
