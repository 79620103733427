<script setup lang="ts">
import type { Effect } from '@/areas/editor/@type/Project'
import IconSaxPause from '@/components/Icons/iconsax/IconSaxPause.vue'
import IconSaxPlay from '@/components/Icons/iconsax/IconSaxPlay.vue'

const props = defineProps<{
  sound: Effect<'sound'>
}>()

const audio = new Audio()
const isPlaying = ref(false)

onMounted(() => {
  audio.src = props.sound.url
  audio.preload = 'none'
  audio.load()

  audio.addEventListener('pause', reset)
  audio.addEventListener('ended', reset)
})
onUnmounted(() => resetAudio())

function togglePlay() {
  if (isPlaying.value) {
    audio.pause()
  } else {
    audio.currentTime = 0
    audio.play()
    isPlaying.value = true
  }
}

function resetAudio() {
  reset()

  audio.pause()
  audio.currentTime = 0
  audio.src = ''

  audio.removeEventListener('pause', reset)
  audio.removeEventListener('ended', reset)
}

function reset() {
  isPlaying.value = false
}
</script>

<template>
  <div class="flex h-full items-center justify-center gap-3">
    <div
      class="bg-gradient flex cursor-pointer items-center justify-center rounded-full p-2.5 transition-[background-color,transform] hover:scale-105"
      @click.stop.prevent="togglePlay()"
    >
      <IconSaxPause v-if="isPlaying" class="h-5 w-5 fill-white text-white" />
      <IconSaxPlay v-else class="h-5 w-5 fill-white text-white" />
    </div>
    <div class="flex select-none flex-col gap-0 text-sm">
      <span class="max-w-full overflow-hidden truncate font-bold">{{ sound.name }}</span>
    </div>
  </div>
</template>

<style scoped></style>
