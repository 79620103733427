
import { fetchFile, toBlobURL } from "@ffmpeg/util";
import { FFmpeg } from "@ffmpeg/ffmpeg";


type OnProgress = (progress: number) => void

export async function runFFmpegSingleThread(videoUrl: string, command: string[], onProgress: OnProgress) {
    const ffmpeg = new FFmpeg();
  
    const baseURL = 'https://forever-storage.streamladder.com/video-editor/ffmpeg';
  
    await ffmpeg.load({
      // workerURL: await toBlobURL(`${baseURL}/ffmpeg-core.worker.js`, 'text/javascript'),
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, 'text/javascript'),
      wasmURL: await toBlobURL(`${baseURL}/ffmpeg-core.wasm`, 'application/wasm'),
    });

    ffmpeg.on('log', console.log);
    ffmpeg.on('progress', (p) => onProgress(p.progress));

    const videoFile = await fetchFile(videoUrl);
  
    ffmpeg.writeFile('input.mp4', videoFile);

    command.unshift('-i', 'input.mp4')
    command.push('output.mp4')

    await ffmpeg.exec(command);
  
    const data = await ffmpeg.readFile('output.mp4');
  
    const blob = new Blob([data], {type: "video/mp4"});

    return blob;
}


export async function changeVideoBitrateFFmpeg(videoUrl: string, maxBitRate = '25M', onProgress: OnProgress) {
    const command = `-c:v libx264 -b:v ${maxBitRate} -maxrate ${maxBitRate} -bufsize 1M -preset ultrafast`.split(' ');
  
    const blob = await runFFmpegSingleThread(videoUrl, command, onProgress);
    
    return blob;
}