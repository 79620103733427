<script setup lang="ts">
import { Button } from '@/components/ui/button'
import type { ClipGptClipDto, ClipGptProjectDto } from '@/apis/streamladder-api/model'
import { useClipboard } from '@vueuse/core'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import IconSaxEdit from '@/components/Icons/iconsax/IconSaxEdit.vue'
import { noop } from 'lodash-es'
import { posthog } from 'posthog-js'
import { computed } from 'vue'
import { useRouter } from 'vue-router'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { ensureValidSnapshot } from '@/helpers/ensureValidSnapshot';
import logging from '@/logging'
import Popover from '@/components/ui/popover/Popover.vue'
import PopoverTrigger from '@/components/ui/popover/PopoverTrigger.vue'
import PopoverContent from '@/components/ui/popover/PopoverContent.vue'
import IconSaxDocumentDownload from '@/components/Icons/iconsax/IconSaxDocumentDownload.vue'
import IconSaxExportSquare from '@/components/Icons/iconsax/IconSaxExportSquare.vue'
import { useTheme } from '@/Hooks/useTheme'

const props = defineProps<{ project: ClipGptProjectDto, clip: ClipGptClipDto, clips: ClipGptClipDto[] }>()
const emit = defineEmits<{
  (event: 'play', url: string): void
}>()

const { copy, copied, isSupported } = useClipboard()
const { theme } = useTheme();

function encodeToBase64(str: string) {
  const encoder = new TextEncoder();
  const encodedData = encoder.encode(str);
  return btoa(String.fromCharCode(...encodedData));
}

function jsonToRouteQuery<TKey extends string>(json: Record<TKey, unknown>) {
  return encodeToBase64(encodeURIComponent(JSON.stringify(json)))
}

const query = computed(() => {
  return jsonToRouteQuery(ensureValidSnapshot({
    id: props.clip.id!,
    title: props.clip.title ?? 'StreamLadder AI Clip',
    mp4Url: props.clip.videoUrl!,
    language: props.project.language,
  }));
})

async function trackClipEdit() {
  const index = props.clips.findIndex(c => c.id === props.clip.id)
  logging.trackEvent('ClipGPT Clip Edit', {
    item: (index + 1) + '/' + props.clips.length,
    score: props.clip.score,
  })
}

function onClick(event: MouseEvent) {
  if (event.target instanceof HTMLVideoElement) {
    event.preventDefault()
    if (event.target.paused) {
      event.target.play()
    } else {
      event.target.pause()
    }
  }
}

function urlTimestamp(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;
  
  return `${hours}h${minutes}m${seconds}s`;
}
</script>

<template>
  <article class="layer-2 bg-surface-panel-bg border-surface-panel-border rounded-2xl p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-5 gap-6 shadow transition-colors">
    <div class="flex flex-col gap-2 lg:col-span-3">
      <video
        :poster="clip.videoUrl + '.webp'"
        preload="none"
        :src="clip.videoUrl!" ref="videos" @play="emit('play', clip.videoUrl!)"
        @click="onClick"
        class="rounded-lg cursor-pointer"
        disablePictureInPicture disableRemotePlayback
        loop playsinline controls
      />
    </div>

    <div class="flex flex-col gap-2 justify-between lg:col-span-2">
      <header class="flex flex-col gap-4">
        <span class="inline-flex font-medium px-3 py-1 text-green-800 bg-green-200 self-start rounded-full whitespace-nowrap" v-if="clip.score! > 75">
          🔥 {{ clip.score }}/100
        </span>
        <span class="inline-flex font-medium px-3 py-1 text-orange-800 bg-orange-200 self-start rounded-full whitespace-nowrap" v-else-if="clip.score! > 70">
          🚀️ {{ clip.score }}/100
        </span>
        <span class="inline-flex font-medium px-3 py-1 text-white text-yellow-800 bg-yellow-200 self-start rounded-full whitespace-nowrap" v-else>
          🗣 {{ clip.score }}/100
        </span>
        <div class="flex items-center gap-2">
          <h3 class="text-xl md:text-2xl lg:text-3xl font-semibold text-pretty">
            {{ clip.title }}
          </h3>
        </div>
        <p class="p-2 rounded-xl border border-border bg-background flex flex-col gap-2"  @click="() => isSupported ? copy(`${clip.hook} ${clip.hashtags?.join(' ')} #StreamLadderAI`) : noop">
          <span class="font-light p-2 select-all">
            {{ clip.hook }} {{ clip.hashtags?.join(' ') }} #StreamLadderAI
          </span>
          <Button class="self-end" size="sm" variant="ghost" v-if="isSupported">
            <template v-if="copied">✓ Copied</template>
            <template v-else>Copy</template>
          </Button>
        </p>
      </header>

      <footer class="flex flex-wrap gap-2">
        <Button as-child @click="trackClipEdit" class="w-auto flex-1" size="lg">
          <RouterLink :to="{ name: 'editor/[clipSource]/[clipId]', params: { clipSource: 'twitch-vod', clipId: props.clip.id }, query: { s: query } }" target="_blank">
            Edit as vertical clip <IconSaxEdit class="w-4 h-4" />
          </RouterLink>
        </Button>
        <Popover>
            <PopoverTrigger as-child>
              <Button as-child class="w-auto flex-1 cursor-pointer" variant="depressed" size="lg">
                <p>More options <IconSaxArrowDown2 class="w-4 h-4" /></p>
              </Button>
            </PopoverTrigger>
            <PopoverContent
              :class="theme" class="mt-2 p-4 flex flex-col gap-2 layer-2 rounded-xl w-auto max-w-[100dvw]"
              side="bottom"
              trap-focus
            >
              <Button variant="ghost" class="w-full justify-start" as="a" :href="clip.videoUrl!" download target="_self">
                <p class="flex w-full items-center justify-between gap-3">
                  Download <IconSaxDocumentDownload class="ml-auto w-4 h-4" />
                </p>
              </Button>
              <Button variant="ghost" class="w-full justify-start" as="a" :href="`https://www.twitch.tv/videos/${project.videoId}?t=${urlTimestamp(clip.start || 0)}`" target="_blank">
                <p class="flex w-full items-center justify-between gap-3">
                  View on Twitch <IconSaxExportSquare class="ml-auto h-4 w-4" />
                </p>
              </Button>
            </PopoverContent>
          </Popover>
      </footer>
    </div>
  </article>
</template>

<style scoped lang="scss">

</style>
