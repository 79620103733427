<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { ColorInput } from '@/components/colors'
import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { NumberField, NumberFieldContent, NumberFieldInput } from '@/components/ui/number-field'
import { Slider } from '@/components/ui/slider'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { Settings2Icon, Trash2Icon, SunIcon } from 'lucide-vue-next'
import { cn } from '@/lib/utils'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()
const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors()

const isOpen = ref(false)

const shadowColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.color
  },
  set(value: string) {
    emit('changed')
    captionsStore.baseCaptionPreset.shadow.color = value
  },
})

const shadowBlurModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.blur
  },
  set(value: number) {
    emit('changed')
    captionsStore.baseCaptionPreset.shadow.blur = value
  },
})

const shadowBlurSliderModel = computed({
  get() {
    return [shadowBlurModel.value]
  },
  set(value: number[]) {
    emit('changed')
    shadowBlurModel.value = value[0]
  },
})

const shadowOpacityModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.opacity
  },
  set(value: number) {
    emit('changed')
    captionsStore.baseCaptionPreset.shadow.opacity = value
  },
})

const shadowOffsetXModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.offsetX || 0
  },
  set(value: number) {
    emit('changed')
    captionsStore.baseCaptionPreset.shadow.offsetX = value
  },
})

const shadowOffsetXSliderModel = computed({
  get() {
    return [shadowOffsetXModel.value]
  },
  set(value: number[]) {
    emit('changed')
    shadowOffsetXModel.value = value[0]
  },
})

const shadowOffsetYModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.offsetY || 0
  },
  set(value: number) {
    emit('changed')
    captionsStore.baseCaptionPreset.shadow.offsetY = value
  },
})

const shadowOffsetYSliderModel = computed({
  get() {
    return [shadowOffsetYModel.value]
  },
  set(value: number[]) {
    emit('changed')
    shadowOffsetYModel.value = value[0]
  },
})

const emit = defineEmits<{ (e: 'changed'): void }>()

function colorPickerClicked(event: Event) {
  if (isOpen.value) {
    event.stopPropagation()
  }
}

function onOpenChanged(value: boolean) {
  if (value && shadowOpacityModel.value === 0) {
    historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH', () => {
      shadowOpacityModel.value = 1
    })
  }
}
</script>

<template>
  <Collapsible
    v-model:open="isOpen"
    @update:open="onOpenChanged"
    class="flex w-full flex-col items-center rounded-lg border border-input bg-background"
  >
    <CollapsibleTrigger class="relative flex w-full flex-row items-center gap-3 p-3">
      <ColorInput
        v-model="shadowColorModel"
        side="right"
        @commit-value="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_COLOR')"
        @close="setRecentlyUsedColor(shadowColorModel)"
        :recently-used-colors="recentlyUsedCaptionColors"
        :recommended-colors="recommendedColors.LIGHT"
      >
        <Button
          variant="toggle"
          @click="colorPickerClicked"
          :class="
            cn('h-auto border-none bg-transparent p-0 shadow-none', {
              'bg-transparent-grid': shadowOpacityModel === 0,
            })
          "
        >
          <span
            class="h-7 w-7 rounded border border-border"
            :style="{ backgroundColor: shadowOpacityModel > 0 ? shadowColorModel : undefined }"
          ></span>
        </Button>
      </ColorInput>

      <div class="flex w-full cursor-pointer flex-row items-center justify-between gap-2">
        <span class="text-sm font-semibold">Shadow</span>
        <Settings2Icon class="w-5" />
      </div>
    </CollapsibleTrigger>

    <CollapsibleContent class="w-full">
      <div class="flex w-full flex-col gap-3 p-3">
        <div class="grid grid-cols-[75px_1fr_auto] items-center gap-4">
          <span class="text-sm font-normal">Blur</span>
          <Slider
            v-model="shadowBlurSliderModel"
            :min="1"
            :max="50"
            :step="1"
            @value-commit="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')"
          />
          <NumberField
            :min="1"
            :max="50"
            v-model="shadowBlurModel"
            @change="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')"
            class="w-20"
          >
            <NumberFieldContent>
              <NumberFieldInput
                class="pl-5"
                @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')"
                @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_BLUR')"
              />
              <div class="absolute left-2 top-1/2 -translate-y-1/2 p-2 text-muted-foreground">
                <SunIcon class="h-4 w-4" />
              </div>
            </NumberFieldContent>
          </NumberField>
        </div>

        <div class="grid grid-cols-[75px_1fr_auto] items-center gap-4">
          <span class="text-sm font-normal">Horizontal Position</span>
          <Slider
            v-model="shadowOffsetXSliderModel"
            :min="-50"
            :max="50"
            :step="1"
            @value-commit="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_X')"
          />
          <NumberField
            :min="-50"
            :max="50"
            v-model="shadowOffsetXModel"
            @change="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_X')"
            class="w-20"
          >
            <NumberFieldContent>
              <NumberFieldInput
                class="pr-5"
                @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_X')"
                @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_X')"
              />
              <span class="absolute right-0 top-1/2 -translate-y-1/2 p-3 text-muted-foreground">px</span>
            </NumberFieldContent>
          </NumberField>
        </div>

        <div class="grid grid-cols-[75px_1fr_auto] items-center gap-4">
          <span class="text-sm font-normal">Vertical Position</span>
          <Slider
            v-model="shadowOffsetYSliderModel"
            :min="-50"
            :max="50"
            :step="1"
            @value-commit="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_Y')"
          />
          <NumberField
            :min="-50"
            :max="50"
            v-model="shadowOffsetYModel"
            @change="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_Y')"
            class="w-20"
          >
            <NumberFieldContent>
              <NumberFieldInput
                class="pr-5"
                @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_Y')"
                @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_SHADOW_OFFSET_Y')"
              />
              <span class="absolute right-0 top-1/2 -translate-y-1/2 p-3 text-muted-foreground">px</span>
            </NumberFieldContent>
          </NumberField>
        </div>

        <CollapsibleTrigger as-child>
          <Button
            variant="outline"
            class="flex flex-row items-center gap-2"
            :disabled="shadowOpacityModel === 0"
            @click="
              historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH', () => {
                shadowOpacityModel = 0
              })
            "
          >
            Remove shadow
            <Trash2Icon class="h-4 w-4" />
          </Button>
        </CollapsibleTrigger>
      </div>
    </CollapsibleContent>
  </Collapsible>
</template>

<style scoped>
.bg-transparent-grid {
  background-color: #ccc; /* Light grey background color */
  background-size: 10px 10px; /* Adjust size to control the size of each square */
  background-position: 0 0, 5px 5px; /* Offset the second layer to create the checkerboard */
  background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff),
    linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
}
</style>
