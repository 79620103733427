import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import { useCropsStore } from '@/areas/editor/store/useCropsStore'
import { computed } from 'vue'
import type { Crop, Segment } from '@/areas/editor/@type/Project'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import type { CropData as ApiCropData } from '@/apis/streamladder-api/model'
import { usePresets } from '@/areas/editor/@data/layouts'
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore'
import * as Sentry from '@sentry/vue'
import type { VideoEffect } from '@/modules/SLVideoplayer/types'
import type { Area } from '@/modules/SLMovable/@types/Movable'
import { pick } from 'lodash-es';

export function useRendererData() {

  const segmentsStore = useSegmentsStore()
  const cropsStore = useCropsStore()
  const videoStore = useVideoStore()
  const layoutsStore = useLayoutsStore()
  const { layouts } = usePresets()

  const flattenedSegments = segmentsStore.flat()

  const rendererCrops = computed(() => {
    const result = []
    for (const segment of flattenedSegments.value) {
      const crops = [...cropsStore.entities]
        .filter(crop => crop.layoutId === segment.layoutId)
        .sort((a, b) => a.z - b.z);
      for (const crop of crops) {
        const renderCrop = toRenderCrop(crop, segment, videoStore.videoElement!)
        if (renderCrop) {
          result.push(renderCrop)
        }
      }
    }
    return result
  })

  const rendererSegments = computed(() => {
    const result = [];
    for (const segment of flattenedSegments.value) {
      const layout = layoutsStore.selectById(segment.layoutId)
      const crops = cropsStore.orderByZ(layout.id).value
      const layoutName = layouts.value.find((l) => l.presetId === layout.presetId)?.id ?? 'custom'
      result.push({
        startMs: segment.startMs,
        endMs: segment.endMs,
        layout: toApiLayoutName(layoutName),
        cropData: crops.map(toApiCropData).filter(Boolean) as ApiCropData[]
      })
    }
    return result
  })

  return { rendererCrops, rendererSegments }
}

function toValidNumberOrDefault(value: unknown, defaultValue: number): number {
  if (typeof value === 'number' && !isNaN(value)) {
    return value;
  } else {
    return defaultValue;
  }
}

const toValidArea = (area: Record<keyof Area, unknown>): Area => {
  return {
    x: toValidNumberOrDefault(area.x, 0),
    y: toValidNumberOrDefault(area.y, 0),
    width: toValidNumberOrDefault(area.width, 1),
    height: toValidNumberOrDefault(area.height, 1),
  }
}

function toApiCropData(crop: Crop): ApiCropData | null {
  const cropArea = toValidArea(crop);
  const feedArea = toValidArea(crop.feedData);
  return {
    x: cropArea.x,
    y: cropArea.y,
    width: cropArea.width,
    height: cropArea.height,
    feedData: {
      x: feedArea.x,
      y: feedArea.y,
      width: feedArea.width,
      height: feedArea.height,
      effects: crop.input.shape === 'circle'
        ? [{ type: 'rounded' }]
        : [],
    },
  }
}

function toRenderCrop(crop: Crop, segment: Segment, videoElement: HTMLVideoElement | undefined): RendererCrop | null {
  if (!videoElement) {
    return null
  } else {
    return {
      key: segment.id + ':' + crop.id,
      videoUrl: videoElement.src,
      videoDurationMs: videoElement.duration * 1000,
      startMs: segment.startMs,
      endMs: segment.endMs,
      z: crop.z,
      shape: crop.input.shape === 'circle' ? 'circle' : 'rectangle',
      effects: [],
      cropData: {
        x: crop.x,
        y: crop.y,
        width: crop.width,
        height: crop.height,
        feedData: {
          x: crop.feedData.x,
          y: crop.feedData.y,
          width: crop.feedData.width,
          height: crop.feedData.height,
        },
      },
    }
  }
}

function toApiLayoutName(layoutName: string) {
  if (layoutName === 'full') {
    return 'Fullscreen'
  } else if (layoutName === 'split' || layoutName == 'basecam') {
    return 'Split'
  } else {
    return 'Custom'
  }
}

export type RendererCrop = {
  key: string;
  videoUrl: string;
  videoDurationMs: number;
  startMs: number;
  endMs: number;
  z: number;
  shape: 'rectangle' | 'circle';
  effects: VideoEffect[];
  cropData: {
    x: number;
    y: number;
    width: number;
    height: number;
    feedData: {
      x: number;
      y: number;
      width: number;
      height: number;
    },
  }
};
