import type { RouteRecordRaw } from 'vue-router'
import ScheduleMaker from '@/areas/schedule-maker/pages/index.vue';

export const routes: RouteRecordRaw[] = [
  {
    name: 'schedule-maker',
    path: '/schedule-maker',
    component: ScheduleMaker,
  }
]
