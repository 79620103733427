export interface DeviceState {
    isDesktop: boolean
    desktopOS: DesktopOS | undefined
    isWindowsDesktop: boolean
    isLinuxOrUnixDesktop: boolean
  
    isMobile: boolean
    mobileOS: MobileOS | undefined
    isAndroidDevice: boolean
    isAppleDevice: boolean
    isUnknownMobileDevice: boolean
  
    isSafari: boolean
  
    isTablet: boolean
  }
  
  const userAgent: string = navigator.userAgent || navigator.vendor || window.opera
  
  const isMobileDevice = (): boolean => {
    const regexs = [/(Android)(.+)(Mobile)/i, /BlackBerry/i, /iPhone|iPod/i, /Opera Mini/i, /IEMobile/i]
    return regexs.some((b) => userAgent.match(b))
  }
  
  const isTabletDevice = (): boolean => {
    const regex =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/
    return regex.test(userAgent.toLowerCase())
  }
  
  const isDesktopDevice = (): boolean => !isMobileDevice() && !isTabletDevice()
  
  const isDesktop = isDesktopDevice()
  const isMobile = isMobileDevice()
  const isTablet = isTabletDevice()
  
  enum MobileOS {
    Android = 'android',
    iOS = 'ios',
    Unknown = 'unknown',
    WindowsPhone = 'Windows Phone',
  }
  
  /**
   * https://stackoverflow.com/a/21742107
   * Determine the mobile operating system.
   *
   * @returns {MobileOS}
   */
  const getMobileOS = (): MobileOS | undefined => {
    if (isMobileDevice()) {
      // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) return MobileOS.WindowsPhone
      else if (/android/i.test(userAgent)) return MobileOS.Android
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) return MobileOS.iOS
  
      return MobileOS.Unknown
    } else return undefined
  }
  
  enum DesktopOS {
    Linux = 'linux',
    MacOS = 'mac_os',
    Unix = 'unix',
    Unknown = 'unknown',
    Windows = 'windows',
  }
  
  const getDesktopOS = (): DesktopOS | undefined => {
    if (isDesktopDevice()) {
      if (userAgent.indexOf('Win') !== -1) return DesktopOS.Windows
      else if (userAgent.indexOf('Mac') !== -1) return DesktopOS.MacOS
      else if (userAgent.indexOf('X11') !== -1) return DesktopOS.Unix
      else if (userAgent.indexOf('Linux') !== -1) return DesktopOS.Linux
  
      return DesktopOS.Unknown
    } else return undefined
  }
  
  type DeviceOS = DesktopOS | MobileOS
  
  const getDeviceOS = (): DeviceOS | undefined => getMobileOS() ?? getDesktopOS()
  
  const mobileOS: MobileOS | undefined = getMobileOS()
  const isAndroidDevice = getDeviceOS() === MobileOS.Android
  const isAppleDevice = getDeviceOS() === MobileOS.iOS || getDeviceOS() === DesktopOS.MacOS
  const isUnknownMobileDevice = getDeviceOS() === MobileOS.Unknown
  
  const desktopOS: DesktopOS | undefined = getDesktopOS()
  const isWindowsDesktop = getDeviceOS() === DesktopOS.Windows
  const isLinuxOrUnixDesktop = getDeviceOS() === DesktopOS.Linux || getDeviceOS() === DesktopOS.Unix
  
  const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent)
  
  export const compatibilityChecker: DeviceState = {
    isDesktop,
    desktopOS,
    isWindowsDesktop,
    isLinuxOrUnixDesktop,
    isMobile,
    mobileOS,
    isAndroidDevice,
    isAppleDevice,
    isUnknownMobileDevice,
    isTablet,
    isSafari,
  }