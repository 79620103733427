<script lang="ts" setup>
import { useUserInfoStore } from '@/store/user/userInfo';
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import ResultVideosList from '@/areas/dashboard/components/ResultVideos/ResultVideosList.vue'
import { useTimeBasedGreetings } from '@/areas/dashboard/hooks/useTimeBasedGreetings'
import ScheduledPostsList from '@/areas/dashboard/components/ScheduledPosts/ScheduledPostsList.vue'
import { videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import { useUpcomingPosts } from '@/areas/dashboard/hooks/useUpcomingPosts'
import { useHead } from '@unhead/vue'
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'
import settings from '@/data/settings'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import BlogPostsSection from '@/areas/dashboard/components/BlogPosts/BlogPostsList.vue'
import PatchNotesDialog from '@/components-v2/data-display/PatchNotesDialog.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { Button } from "@/components/ui/button";
import { useMyClips } from '@/queries/useClips'
import { Skeleton } from '@/components/ui/skeleton'
import ClipPreviewCard from '@/components/Clips/ClipPreviewCard.vue'
import { useTwitchClipsFilter } from '@/Hooks/useTwitchClipsFilter'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import ClipEditorMultiUploadDialog from '@/components/Dialog/MultiUploadDialog/ClipEditorMultiUploadDialog.vue'
import TwitchClipPreviewDialog from '@/components-v2/modules/HomePage/TwitchClipPreviewDialog.vue'
import ClipUrlError from '@/Hooks/clip-form/ClipUrlError.vue'
import { useScreenName } from '@/Hooks/useScreen'
import { useClipFormManager } from '@/Hooks/clip-form/useClipFormManager'
import { computed, ref, onMounted } from 'vue';
import type { Clip } from '@/Hooks/useTwitchClips'
import { useResetEditor } from '@/Hooks/useResetEditor'
import { useRouter, type RouteRecordName } from 'vue-router'
import { areHalloweenStickersAvailable } from '@/components/Stickers/stickerLibrary/seasonal/halloween/halloweenStickers'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import { useBlackFriday } from '@/Hooks/dates/useBlackFriday'
import { upgradeDialog } from '@/helpers/upgradeDialog';
import { CountdownBannerLandscapeTall } from '@/components/ui/countdown';
import logging from '@/logging'
import DashboardProjectsOverviewSection from '@/areas/dashboard/pages/recent-projects/DashboardProjectsOverviewSection.vue';
import ToolsOverview from '@/areas/dashboard/components/ToolsOverview.vue';

const userInfo = useUserInfoStore()

const greeting = useTimeBasedGreetings()
const { isLoadingPosts, upcomingPosts } = useUpcomingPosts()

useHead({
  title: 'Dashboard',
  meta: [
    {
      name: 'description',
      content: 'Keep track of your clips, schedule posts and more.',
    },
  ],
})

const { myClips } = useMyClips()
const { twitchClipsOptions, twitchClipFilterKey } = useTwitchClipsFilter()

onMounted(() => {
  myClips.fetchIfNull()
})

const { error } = useClipFormManager()
const previewId = ref<string | null>(null)
const resetEditor = useResetEditor()
const router = useRouter()
async function onClipSelected(clip: Clip) {
  resetEditor()
  await router.push({ name: 'editor/[clipSource]/[clipId]', params: { clipSource: 'twitch-clip', clipId: clip.id } });
}

function preview(clip: Clip) {
  previewId.value = clip.id
}

// Everything below is part of the different dashboard layout AB test.
function onButtonClicked(text: string, routeName: RouteRecordName | string) {
  logging.trackEvent('Dashboard Button Clicked', {
    button: text, 
    route: typeof routeName === 'string' && routeName.startsWith('https://') 
      ? routeName 
      : router.resolve({ name: routeName }).href 
  });
}

const screenName = useScreenName()
// grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4
const clipsInView = computed(() => {
  const rows = 2
  switch (screenName.value) {
    case 'lg':
    case 'xl':
      return rows * 3;
    case '2xl':
      return rows * 4;
    default:
      return rows * 2
  }
})

const showAllClips = ref(false)

const clips = computed(() => {
  if (!myClips.ordered) {
    return []
  } else if (showAllClips.value) {
    return myClips.ordered
  } else {
    return myClips.ordered.slice(0, clipsInView.value)
  }
})

const { showBlackFridayDeal, hours, minutes, seconds } = useBlackFriday()
</script>

<template>
  <main class="relative flex flex-col gap-8">
    <div class="absolute bg-gradient w-full h-64 rounded-t-xl z-0" />
    <div class="flex flex-col p-4 lg:gap-16 lg:p-12 z-10">
      <div class="flex flex-wrap items-stretch gap-6 z-1 mb-8">
        <header class="flex w-full shrink-0 flex-col p-4">
          <h1 class="text-3xl lg:text-5xl text-white">
            {{ greeting }},
            <span class="font-bold">{{ userInfo.userName }}!</span>
            <span v-if="areHalloweenStickersAvailable()"> 🎃</span>
          </h1>
          <p class="text-style-base text-white">
            How will you climb the StreamLadder today? 🚀
          </p>
        </header>
        
        <ToolsOverview @button-clicked="onButtonClicked" />
      </div>

      <CountdownBannerLandscapeTall v-if="showBlackFridayDeal" :days="hours" :hours="minutes" :minutes="seconds"
        class="my-4 lg:-my-8 cursor-pointer hover:scale-105 active:scale-90 hover:shadow-xl active:shadow-none transition-all" 
        @click="upgradeDialog.open({ intentional: true, reason: 'Black Friday Dashboard Banner' })"
      >
        <img
          class="h-full w-full"
          src="/black-friday/banner_lg_countdown.jpg" 
          alt="black friday banner" 
        />
      </CountdownBannerLandscapeTall>
      
      <DashboardProjectsOverviewSection />

      <section class="flex flex-col gap-2">
        <header class="flex flex-col sm:flex-row items-center justify-between">
          <h2 class="mb-0 text-3xl font-normal text-brand-state-text-primary flex-auto">
            <template v-if="myClips.isFetching">
              Finding clips from <span class="font-bold">{{ userInfo.userName }}</span>
            </template>
            <template v-else>
              Showing clips from <span class="font-bold">{{ userInfo.userName }}</span>
            </template>
          </h2>

          <SelectDropdown v-model="twitchClipFilterKey" :options="twitchClipsOptions" class="ml-auto" />
        </header>

        <div class="p-4 mt-0 -m-4 h-full flex-auto" v-if="myClips.isFetching || (myClips.ordered && myClips.amountOfClips > 0)">
          <div class="grid grid-cols-2 gap-2 md:gap-4 lg:grid-cols-3 2xl:grid-cols-4">
            <template v-if="myClips.isFetching">
              <article class="flex flex-col gap-1" v-for="i in clipsInView" :key="i">
                <Skeleton class="w-full aspect-video rounded-xl" />
                <Skeleton class="h-6 w-3/4" />
              </article>
            </template>
            <template v-else-if="myClips.ordered">
              <ClipPreviewCard v-for="clip in clips"
                :key="clip.id!" :clip="clip!"
                @select="onClipSelected" @preview="preview"
              />
            </template>
          </div>
          <div class="flex justify-center" v-if="myClips.ordered && myClips.amountOfClips > clipsInView">
            <Button @click="showAllClips = !showAllClips" variant="depressed" class="mt-4">
              {{ showAllClips ? 'Show fewer clips' : 'Show more clips' }}
              <IconSaxArrowDown2 class="w-4 h-4" :class="{ 'rotate-180': showAllClips }" />
            </Button>
          </div>
        </div>

        <NoContentSection
          v-else-if="twitchClipFilterKey === 'views-all'"
          subtitle="Try uploading a local file"
          title="The selected channel does not have any clips yet"
        >
          <ClipEditorMultiUploadDialog>
            <Button variant="gradient" size="lg">Upload a local file</Button>
          </ClipEditorMultiUploadDialog>
          <ClipUrlError :error="error" />
        </NoContentSection>

        <NoContentSection
          v-else
          subtitle="Check out all clips from this channel instead"
          title="No clips were created in this time period"
        >
          <Button variant="gradient" size="lg" @click="twitchClipFilterKey = 'views-all'">Show all</Button>
        </NoContentSection>

        <TwitchClipPreviewDialog v-model="previewId" :clips="clips" @confirm="onClipSelected" />
      </section>

      <WithUpgradeOverlay
        feature="scheduler"
        subtitle="Effortlessly plan and schedule your content with a simple click. It's that straightforward!"
        title="Plan and schedule your content"
      >
        <div class="flex flex-col gap-4">
          <section class="flex flex-wrap items-center justify-between gap-2">
            <header class="flex flex-col">
              <h2 class="text-2xl lg:text-3xl">Scheduled posts</h2>
              <p class="font-light text-opacity-50">These posts are scheduled and waiting to be published.</p>
            </header>

            <RouterLink
              v-if="!isLoadingPosts && upcomingPosts.length > 0"
              :to="{ name: dashboardRouteNames.contentPublisher.root }"
              class="shadow-lg"
            >
              <Button variant="gradient" size="lg">
                New post
                <IconSaxAdd />
              </Button>
            </RouterLink>
          </section>

          <ScheduledPostsList />
        </div>
      </WithUpgradeOverlay>

      <WithUpgradeOverlay
        feature="save-video"
        subtitle="Unlock extended clip storage. With Silver & Gold Membership, your clips are safe for a whole week!"
        title="Upgrade today and save your clips for 30 days"
      >
        <div class="flex flex-col gap-4">
          <section class="flex flex-wrap items-center justify-between gap-2">
            <header class="flex flex-col">
              <h2 class="text-2xl lg:text-3xl">My Videos</h2>
              <p class="font-light text-opacity-50">Below are the videos you created in the last 30 days.</p>
            </header>

            <label class="flex items-center gap-2">
              <span class="text-sm font-light">Filter:</span>
              <SelectDropdown v-model="videosFilter" :options="videosFilterOptions" class="ml-auto" />
            </label>
          </section>

          <ResultVideosList single-row />
        </div>
      </WithUpgradeOverlay>

      <section
        class="flex flex-col justify-between gap-12 rounded-xl bg-gradient-to-br from-[#F66565] to-[#B665F6] px-12 py-8 text-white lg:flex-row-reverse lg:justify-center lg:px-16 lg:py-12"
      >
        <div class="flex flex-col justify-between gap-3 sm:flex-row lg:flex-col">
          <div class="flex flex-col">
            <p class="text-5xl">300k+</p>
            <p class="whitespace-nowrap text-sm font-light uppercase">in our community</p>
          </div>
          <div class="flex flex-col">
            <p class="text-5xl">200k+</p>
            <p class="whitespace-nowrap text-sm font-light uppercase">monthly clips created</p>
          </div>
          <div class="flex flex-col">
            <p class="text-5xl">968M+</p>
            <p class="whitespace-nowrap text-sm font-light uppercase">views on social media</p>
          </div>
        </div>

        <div class="flex flex-col gap-4">
          <ol class="avatar-group -space-x-6">
            <li class="layer-1 avatar aspect-square h-16 w-16">
              <img alt="Ninja" height="72" loading="lazy" src="/images/user-avatars/ninja.png" width="72" />
            </li>
            <li class="layer-1 avatar aspect-square h-16 w-16">
              <img alt="gmhikaru" height="70" loading="lazy" src="/images/user-avatars/gmhikaru.png" width="70" />
            </li>
            <li class="layer-1 avatar aspect-square h-16 w-16">
              <img alt="phonecats" height="70" loading="lazy" src="/images/user-avatars/phonecats.png" width="70" />
            </li>
            <li class="layer-1 avatar aspect-square h-16 w-16">
              <img alt="Teddy127" height="70" loading="lazy" src="/images/user-avatars/teddy-127.png" width="70" />
            </li>
          </ol>

          <header class="flex max-w-[50ch] flex-col gap-2">
            <h3 class="m-0 p-0 text-3xl leading-10 !text-white">Join the community of Streamers that wants to grow.</h3>
            <p class="font-light !text-white">
              Connect with fellow Streamers! Explore creative insights, share ideas, and grow your channel.
            </p>
          </header>

          <a
            :href="settings.discordInviteUrl"
            class="flex items-center gap-2 self-start rounded-xl bg-white px-6 py-2 font-bold text-indigo-500 transition-all hover:text-indigo-600 active:scale-90"
            target="_blank"
          >
            <DiscordLogo class="h-6 w-6 fill-current" />
            Join our Discord
          </a>
        </div>
      </section>

      <BlogPostsSection />

      <PatchNotesDialog v-if="false" />
    </div>
  </main>
</template>

<style lang="scss" scoped>
.elgato {
  box-shadow: 0 0 5px 2px #8032dd;
}
</style>
