import { useTheme } from '@/Hooks/useTheme';

export function useVideoEditorTheme() {
  const { theme } = useTheme();
  return computed(() => themes[theme.value]);
}

const themes = {
  light: {
    logoUrl: window.location.origin + '/images/logo.svg',
    navigation: 'linear-gradient(to top left, #A000FE -12.32%, #6080FE 97.88%)',
    navigationForeground: '#fff',
    border: '#d9d9e0',
    foreground: '#000',
    background: '#fcfcfd',
    workspace: '#fcfcfd',
    card: '#fcfcfd',
    cardForeground: '#000',
    cardBorder: '#d9d9e0',
    neutral: '#f9f9fb',
    neutralBorder: '#cdced6',
    neutralForeground: '#1c2024',
    input: '#e4e4e7',
    active: '#eff4fe',
    activeBorder: '#3b82f6',
    activeForeground: '#000',
    accent: 'linear-gradient(to right, #FAE5FF, #DAF1FF)',
    accentBorder: '0 0% 0% / 0',
    accentForeground: '#000',
    timeline: '#f3f4f6',
    primary: '#531AFF',
  },
  dark: {
    logoUrl: window.location.origin + '/images/logo.svg',
    navigation: 'linear-gradient(to top left, #A000FE -12.32%, #6080FE 97.88%)',
    navigationForeground: '#fff',
    foreground: '#fff',
    border: '#363a3f',
    background: '#212225',
    workspace: '#0A0A08',
    card: '#000000',
    cardForeground: '#fcfcfd',
    cardBorder: '#363a3f',
    muted: '#18191b',
    mutedForeground: '#b5b5bf',
    mutedBorder: '#edeef0',
    neutral: '#18191b',
    neutralBorder: '#43484e',
    neutralForeground: '#edeef0',
    input: '#404044',
    active: '#083344',
    activeBorder: '#67e8f9',
    activeForeground: '#fcfcfd',
    accent: 'linear-gradient(270deg, #a000fe, rgba(96, 128, 254, 0.98))',
    accentBorder: '0 0% 0% / 0',
    accentForeground: '#fff',
    timeline: '#18191b',
    primary: '#531AFF',
  },
} as const;
