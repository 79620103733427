<script lang="ts" setup>
import { Button } from '@/components/ui/button';
import { Skeleton } from '@/components/ui/skeleton';
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue';
import { useQuery } from '@tanstack/vue-query';
import { getUser, supabase } from '@/authentication/supabase';
import { useUserInfoStore } from '@/store/user/userInfo';
import type { ProjectOverviewItem } from '@/areas/dashboard/pages/recent-projects/ProjectOverviewItem';
import { range } from 'lodash-es';
import ProjectPreviewButton from '@/areas/dashboard/pages/recent-projects/ProjectPreviewButton.vue';
import { useScreenName } from '@/Hooks/useScreen';
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import type { Project } from '@/areas/editor/@type/Project';

const userInfoStore = useUserInfoStore();
const userId = computed(() => userInfoStore.userId);
const total = ref(0);

const screenSizeName = useScreenName();
const itemsInPreviewForScreenSize = computed(() => {
  switch (screenSizeName.value) {
    case 'md':
    case 'lg':
    case 'xl':
      return 6;
    case '2xl':
      return 8;
    default:
      return 4;
  }
});

const { data: projects, isPending } = useQuery({

  queryKey: ['projects', userId],
  queryFn: async (): Promise<ProjectOverviewItem[]> => {

    const { data: { user } } = await getUser();
    if (!user?.id) {
      return [];
    }

    const { data, count } = await supabase.from('ClipEditorProjects')
      .select(`
        id:           Id,
        created_at:   CreatedAt,
        version:      Version,
        json:         Json
      `, { count: 'exact' })
      .eq('UserId', user.id)
      .filter('CreatedAt', 'gt', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString())
      .order('CreatedAt', { ascending: false })
      .range(0, 16);

    if (count) {
      total.value = count;
    }

    return (data ?? []).map<ProjectOverviewItem>(project => ({
      ...project,
      json: project.json as unknown as EditorProject | Project,
      time_ago_ms: Date.now() - new Date(project.created_at).getTime(),
    }));
  },
  enabled: !!userId,
});

const showAll = ref(false);
const itemsOnPage = computed(() => {
  const showAllModifier = showAll.value ? 2 : 1;
  const itemsOnPage = showAllModifier * itemsInPreviewForScreenSize.value;
  const maxItems = (projects.value?.length ?? Infinity);
  return Math.min(itemsOnPage, maxItems);
});
</script>

<template>
  <section class="flex flex-col gap-2" v-if="itemsOnPage > 0">
    <header class="flex flex-col sm:flex-row items-center justify-between">
      <h2 class="mb-0 text-3xl font-normal text-brand-state-text-primary flex-auto">
        Your projects from the last 7 days
      </h2>
    </header>
    <div class="p-4 mt-0 -m-4 h-full flex-auto">
      <ol class="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4">
        <li
          v-for="i in range(itemsOnPage)"
          :key="'project-' + i"
          class="group"
        >
          <div v-if="isPending" class="flex flex-col gap-1">
            <Skeleton class="w-full aspect-video" />
            <Skeleton class="w-3/4 h-6" />
            <Skeleton class="w-1/2 h-4" />
          </div>

          <ProjectPreviewButton v-else-if="projects?.[i]" :project="projects[i]" />
        </li>
      </ol>
      <div v-if="!isPending && total > itemsInPreviewForScreenSize" class="flex justify-center">
        <Button class="mt-4 mb-10 lg:mb-0" variant="depressed" @click="showAll = !showAll">
          {{ showAll ? 'Show fewer projects' : 'Show more projects' }}
          <IconSaxArrowDown2 :class="{ 'rotate-180': showAll }" class="w-4 h-4" />
        </Button>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>

</style>
