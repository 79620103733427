<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { ColorInput } from '@/components/colors'
import { Button } from '@/components/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { NumberField, NumberFieldContent, NumberFieldInput } from '@/components/ui/number-field'
import { Slider } from '@/components/ui/slider'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { cn } from '@/lib/utils'
import { Settings2Icon, Trash2Icon } from 'lucide-vue-next'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()
const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors()

const isOpen = ref(false)

const strokeColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.stroke.color
  },
  set(color: string) {
    emit('changed')
    captionsStore.baseCaptionPreset.stroke.color = color
  },
})

const strokeWidthModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.stroke.width
  },
  set(value: number) {
    emit('changed')
    captionsStore.baseCaptionPreset.stroke.width = value
  },
})

const strokeWidthSliderModel = computed({
  get() {
    return [strokeWidthModel.value]
  },
  set(value: number[]) {
    emit('changed')
    strokeWidthModel.value = value[0]
  },
})

const emit = defineEmits<{ (e: 'changed'): void }>()

function colorPickerClicked(event: Event) {
  if (isOpen.value) {
    event.stopPropagation()
  }
}

function onOpenChanged(value: boolean) {
  if (value && strokeWidthModel.value === 0) {
    historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH', () => {
      strokeWidthModel.value = 1
    })
  }
}

function onCloseColorPicker() {
  setRecentlyUsedColor(strokeColorModel.value)
  captionsStore.savePreset(captionsStore.currentUserCaption!)
}
</script>

<template>
  <Collapsible
    v-model:open="isOpen"
    @update:open="onOpenChanged"
    class="flex w-full flex-col items-center rounded-lg border border-input bg-background"
  >
    <CollapsibleTrigger class="relative flex w-full flex-row items-center gap-3 p-3">
      <ColorInput
        v-model="strokeColorModel"
        side="right"
        @commit-value="historyStore.transaction('CAPTIONS:CHANGE_STROKE_COLOR')"
        @close="onCloseColorPicker"
        :recently-used-colors="recentlyUsedCaptionColors"
        :recommended-colors="recommendedColors.DARK"
      >
        <Button
          variant="toggle"
          @click="colorPickerClicked"
          :class="
            cn('h-auto border-none bg-transparent p-0 shadow-none', {
              'bg-transparent-grid': strokeWidthModel === 0,
            })
          "
        >
          <span
            class="h-7 w-7 rounded border border-border"
            :style="{ backgroundColor: strokeWidthModel > 0 ? strokeColorModel : undefined }"
          ></span>
        </Button>
      </ColorInput>

      <div class="flex w-full cursor-pointer flex-row items-center justify-between gap-2">
        <span class="text-sm font-semibold">Outline</span>
        <Settings2Icon class="w-5" />
      </div>
    </CollapsibleTrigger>

    <CollapsibleContent class="w-full">
      <div class="flex w-full flex-col gap-3 p-3">
        <div class="grid grid-cols-[1fr_auto] items-center gap-4">
          <Slider
            v-model="strokeWidthSliderModel"
            :min="1"
            :max="25"
            :step="1"
            @value-commit="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')"
          />
          <NumberField
            :min="1"
            :max="25"
            v-model="captionsStore.baseCaptionPreset.stroke.width"
            @change="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')"
            class="w-20"
          >
            <NumberFieldContent>
              <NumberFieldInput
                class="pr-5"
                @keydown.up="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')"
                @keydown.down="historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH')"
              />
              <span class="absolute right-0 top-1/2 -translate-y-1/2 p-3 text-muted-foreground">px</span>
            </NumberFieldContent>
          </NumberField>
        </div>
        <CollapsibleTrigger as-child>
          <Button
            variant="outline"
            class="flex flex-row items-center gap-2"
            :disabled="strokeWidthModel === 0"
            @click="
              historyStore.transaction('CAPTIONS:CHANGE_STROKE_WIDTH', () => {
                strokeWidthModel = 0
              })
            "
          >
            Remove outline
            <Trash2Icon class="h-4 w-4" />
          </Button>
        </CollapsibleTrigger>
      </div>
    </CollapsibleContent>
  </Collapsible>
</template>

<style scoped>
.bg-transparent-grid {
  background-color: #ccc; /* Light grey background color */
  background-size: 10px 10px; /* Adjust size to control the size of each square */
  background-position: 0 0, 5px 5px; /* Offset the second layer to create the checkerboard */
  background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff),
    linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
}
</style>
