<script setup lang="ts">
import { dashboardRouteNames } from '@/areas/dashboard/routeNames';
import HighlightPartialABTest from '@/areas/dashboard/pages/index/partial/HighlightPartialABTest.vue';

interface Emits {
  (event: 'button-clicked', value: string, route: string): void;
}

const emit = defineEmits<Emits>();
function onButtonClicked(value: string, route: string) {
  emit('button-clicked', value, route);
}
</script>

<template>
  <nav class="grid md:grid-cols-2 2xl:grid-cols-3 gap-4">
    <RouterLink active-class="hidden" :to="{ name: 'editor' }" @click="onButtonClicked('Create new clip', 'editor')">
      <HighlightPartialABTest title="Create new clip" subtitle="Convert any clip to TikTok, Instagram or YouTube">
        <template #visual>
          <img src="/images/dashboard/create-clips.png" alt="Highlight image" class="w-full h-full object-contain scale-[1.4]" />
        </template>
      </HighlightPartialABTest>
    </RouterLink>

    <RouterLink active-class="hidden" :to="{ name: dashboardRouteNames.clipGpt.root }" @click="onButtonClicked('Create clips from Twitch stream', dashboardRouteNames.clipGpt.root)">
      <HighlightPartialABTest title="Generate clips automatically from your Twitch Stream" subtitle="Generate up to 10 clips from a Twitch stream using AI">
        <template #visual>
          <img src="/images/dashboard/stream-to-clips.png" alt="Highlight image" class="w-full h-full object-contain scale-[1.5] origin-right" />
        </template>

      </HighlightPartialABTest>
    </RouterLink>

    <RouterLink active-class="hidden" :to="{ name: dashboardRouteNames.montageMaker }" @click="onButtonClicked('Create montage', dashboardRouteNames.montageMaker)">
      <HighlightPartialABTest title="Create montage" subtitle="Turn multiple clips or videos into a single montage">
        <template #visual>
          <img src="/images/dashboard/montage-maker.png" alt="Highlight image" class="w-full h-full object-contain scale-[1.5] origin-top" />
        </template>
      </HighlightPartialABTest>
    </RouterLink>

    <RouterLink active-class="hidden" :to="{ name: dashboardRouteNames.contentPublisher.post }" @click="onButtonClicked('Create post', dashboardRouteNames.contentPublisher.post)">
      <HighlightPartialABTest title="Create post" subtitle="Post or plan directly to TikTok or YouTube">
        <template #visual>
          <img src="/images/dashboard/calendar.png" alt="Highlight image" class="w-full h-full object-contain" />
        </template>
      </HighlightPartialABTest>
    </RouterLink>

    <a href="https://emotes.streamladder.com/" target="_blank" @click="onButtonClicked('Create emote', 'https://emotes.streamladder.com/')">
      <HighlightPartialABTest title="Create emote" subtitle="Convert a clip to an animated emote">
        <template #visual>
          <img src="/images/dashboard/create-emote.png" alt="Highlight image" class="w-full h-full object-contain" />
        </template>
      </HighlightPartialABTest>
    </a>

    <RouterLink active-class="hidden" :to="{ name: 'schedule-maker' }" @click="onButtonClicked('Create Stream Schedule', 'schedule-maker')">
      <HighlightPartialABTest badge="NEW" title="Create Stream Schedule" subtitle="Turn your Twitch schedule into a social media post">
        <template #visual>
          <img src="/images/dashboard/schedule-maker.png" alt="Highlight image" class="w-full h-full object-contain scale-[1.3] origin-top" />
        </template>
      </HighlightPartialABTest>
    </RouterLink>

    <RouterLink active-class="hidden" :to="{ name: dashboardRouteNames.downloader.root }" @click="onButtonClicked('Download clips', dashboardRouteNames.downloader.root)">
      <HighlightPartialABTest title="Download clips" subtitle="Download YouTube Clips or Twitch Clips to your device">
        <template #visual>
          <img src="/images/dashboard/download-clip.png" alt="Highlight image" class="w-full h-full object-contain pr-4" />
        </template>
      </HighlightPartialABTest>
    </RouterLink>
  </nav>
</template>

<style scoped lang="scss">

</style>
