<script setup lang="ts">
import { type Transcript, useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { computed, onUnmounted, ref } from 'vue'
import { Button } from '@/components/ui/button'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import IconSaxRefresh2 from '@/components/Icons/iconsax/IconSaxRefresh2.vue'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogTitle
} from '@/components/ui/alert-dialog'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import CaptionEditorLineV2 from '@/areas/editor/views/captions/v2/CaptionEditorLineV2.vue'
import CaptionEditorLineAddOrMerge from '@/areas/editor/views/captions/v2/CaptionEditorLineAddOrMerge.vue'
import IconSaxEdit2 from '@/components/Icons/iconsax/IconSaxEdit2.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'

const showResetAllConfirm = ref(false);
const showRemoveAllConfirm = ref(false);

const captionsStore = useCaptionsStore();
const historyStore = useHistoryStore();
const videoStore = useVideoStore();

const resetAll = async () => {
  showResetAllConfirm.value = false;
  await historyStore.transaction('CAPTIONS:REGENERATE', captionsStore.regenerateCaptions);
};

const removeAll = async () => {
  showRemoveAllConfirm.value = false;
  await historyStore.transaction('CAPTIONS:DELETE_ALL', captionsStore.removeAllCaptions);
};

const currentTimeMs = computed(() => {
  return videoStore.currentTimeMs;
});

const activeCaptionId = computed(() => {
  const { value } = currentTimeMs;
  const caption = captionsStore.entities.find((caption) => {
    if (!caption.words.length) {
      return false;
    } else if (caption.words.length === 1) {
      return caption.words[0].start <= value && caption.words[0].end >= value;
    } else {
      return caption.words[0].start <= value && caption.words[caption.words.length - 1].end >= value;
    }
  });
  return caption?.id ?? null;
});

const activeWordText = computed(() => {

  if (!activeCaptionId.value) {
    return '';
  }

  const caption = captionsStore.selectById(activeCaptionId.value) as Transcript;

  if (caption) {
    const word = caption.words.find((word) => word.start <= currentTimeMs.value && word.end >= currentTimeMs.value);
    return word?.text ?? '';
  }

  return '';
});

const setEditing = (captionId: string | null, wordId: string | null) => {

  captionsStore.captionIdBeingEdited = captionId;
  captionsStore.captionIdHighlighted = captionId;
  captionsStore.wordIdBeingEdited = wordId;

  if (captionId) {
    const caption = captionsStore.selectById(captionId);
    if (caption && wordId) {
      const word = captionsStore.findWordById(wordId) ?? caption.words[caption.words.length - 1];
      if (word) {
        videoStore.playing = false;
        videoStore._currentTime = (word.start + (word.end - word.start) / 2) / 1000;
      }
    }
  }
};

const addEmptyCaption = () => {
  historyStore.transaction('CAPTIONS:ADD', () => { captionsStore.addCaption() }); 
};

const removeEmptyCaption = (captionId: string) => {
  historyStore.transaction('CAPTIONS:DELETE', () => captionsStore.removeById(captionId));
};

onUnmounted(() => {
  setEditing(null, null);
});

const captions = computed(() => {
  return captionsStore.entities;
});
</script>

<template>

  <div class="my-2 h-px bg-surface-panel-border" />

  <div class="flex flex-col items-start py-4">

    <div v-if="captions.length === 0" class="flex flex-col w-full items-center justify-center px-10">
      <LottieAnimation class="w-40 h-40" url="/lottie/astronaut-light-theme.json" :auto-play="true" :loop="true" />
      <p class="text-center font-light">Start editing captions by pressing the button below.</p>
      <Button @click="addEmptyCaption" class="mt-4">Add a Caption</Button>
    </div>
    
    <div
      v-else
      v-for="caption in captions"
      :key="caption.id"
      class="flex flex-col items-start w-full"
    >
      <div class="pl-2 flex gap-2 flex-auto w-full relative">

        <div
          v-if="activeCaptionId === caption.id || captionsStore.captionIdHovered === caption.id"
          class="absolute top-0 left-0 w-[2px] h-full bg-company-primary-50 transition-[opacity,transform]"
        />

        <CaptionEditorLineV2
          :caption="caption"
          :isNew="activeWordText === ''"
          :isActive="activeCaptionId === caption.id"
        />

        <div
          @click="setEditing(caption.id, caption.words[caption.words.length - 1].id)"
          class="relative h-full w-8 2xl:w-10 flex items-center justify-center cursor-pointer transition-[transform]"
          :class="'before:font-light before:content-[attr(data-tooltip)] before:whitespace-nowrap before:absolute before:pointer-events-none before:top-1/2 before:-translate-y-1/2 before:right-full before:opacity-0 hover:before:opacity-100 hover:before:-translate-x-3  before:w-min before:h-6 before:flex before:items-center before:justify-center before:cursor-pointer before:transition-[transform,_opacity] before:z-50 before:overflow-hidden before:rounded-md before:border before:layer-2 before:px-3 before:py-1.5 before:text-sm before:text-popover-foreground before:bg-surface-panel before:border-surface-panel-border before:shadow-md before:animate-in before:fade-in-0 before:zoom-in-95 before:data-[state=closed]:animate-out before:data-[state=closed]:fade-out-0 before:data-[state=closed]:zoom-out-95 before:data-[side=bottom]:slide-in-from-top-2 before:data-[side=left]:slide-in-from-right-2 before:data-[side=right]:slide-in-from-left-2 before:data-[side=top]:slide-in-from-bottom-2'"
          :data-tooltip="'Select sentence'"
        >
          <div class="w-7 h-7 2xl:text-lg 2xl:w-10 2xl:h-10 rounded-lg bg-gray-200 hover:bg-gray-300 dark:bg-surface-panel-50 dark:hover:bg-surface-panel-100 transition-[background-color] flex items-center justify-center select-none">
            <IconSaxEdit2 class="w-4 h-4" />
          </div>
        </div>
      </div>

      <CaptionEditorLineAddOrMerge :caption="caption" />
    </div>
  </div>

  <div class="my-2 h-px bg-surface-panel-border" />

  <div class="flex gap-2 pt-4 w-full" v-if="captions.length !== 0">

    <Button variant="outline" class="text-black dark:text-white hover:bg-red-400 hover:text-white font-light w-full" @click="showRemoveAllConfirm = true">
      <span class="mt-0.5">Delete all captions</span>
      <IconSaxTrash class="w-4 h-4" />
    </Button>

    <Button variant="outline" class="text-black dark:text-white font-light" @click="showResetAllConfirm = true">
      <IconSaxRefresh2 class="w-4 h-4" />
    </Button>
  </div>

  <AlertDialog v-model:open="showResetAllConfirm">
    <AlertDialogContent>
      <AlertDialogTitle class="text-lg font-bold">Reset captions</AlertDialogTitle>
      <AlertDialogDescription>
        Are you sure you wish to undo all changes and regenerate the captions?
      </AlertDialogDescription>
      <AlertDialogFooter>
        <Button variant="outline" @click="showResetAllConfirm = false">Cancel</Button>
        <Button variant="destructive" @click="resetAll">Reset</Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>

  <AlertDialog v-model:open="showRemoveAllConfirm">
    <AlertDialogContent>
      <AlertDialogTitle class="text-lg font-bold">Delete all captions</AlertDialogTitle>
      <AlertDialogDescription>
        Are you sure you wish to undo all changes and delete all captions?
      </AlertDialogDescription>
      <AlertDialogFooter>
        <Button variant="outline" @click="showRemoveAllConfirm = false">Cancel</Button>
        <Button variant="destructive" @click="removeAll">Delete All</Button>
      </AlertDialogFooter>
    </AlertDialogContent>
  </AlertDialog>
</template>

<style scoped lang="scss">

</style>
