import {ref, computed} from 'vue'
import type { PostDto, UpdatePostDto, CreatePostDto } from '@/apis/streamladder-publisher/model'
import unwrap from '@/helpers/unwrap'
import { defineStore } from 'pinia'
import { toPostCalendarEvent, getValidRange, orderByDate, addGridRowProperty } from './_helpers'
import {
  getPosts,
  putPostsId,
  deletePostsId,
  getPostsId,
  postPosts,
} from '@/apis/streamladder-publisher/streamladder-publisher/streamladder-publisher'
import * as Sentry from '@sentry/browser'
import { onUserInfoReadyAsync } from '@/store/user/userInfo'
import toastEvents from '@/events/toastEvents'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import { useToast } from '@/Hooks/useToast'
import { patchPostsIdRetry } from '@/apis/streamladder-publisher/posts/posts'
import { debounce } from 'lodash-es'

export const useContentPublisherStore = defineStore('content-publisher', () => {

  const router = useRouter()
  const entities = ref<Record<string, Required<PostDto>>>({})

  const posts = computed(() => orderByDate(unwrap.values(entities.value)))
  const ids = computed(() => unwrap.keys(entities.value))
  
  const accountFilter = ref<string[]>([])
  function byAccount(post: Required<PostDto>) {

    if (accountFilter.value.length === 0) {
      return true
    }

    const targets = post.targets ?? []
    return targets.some(t => accountFilter.value.includes(t.accountId!)) 
  }

  const events = computed(() => {

    const postsAsCalendarEvents = posts.value
      .filter(byAccount)
      .map(toPostCalendarEvent)

    return addGridRowProperty(postsAsCalendarEvents)
  })

  function upsertPost(post: PostDto) {
    if (post && post.id) {
      entities.value[post.id] = post as Required<PostDto>
    }
  }

  const isFetching = ref(false)

  async function fetchPosts(from: Date, to: Date) {

    if (isFetching.value) {
      return
    }

    isFetching.value = true

    const { isAuthenticated } = await onUserInfoReadyAsync()

    if (!isAuthenticated) {
      Sentry.captureException(new Error('User is not authenticated'))
      return
    }

    const response = await getPosts({
      from: from.toISOString(),
      to: to.toISOString(),
      top: 200,
    })

    for (const post of response.items as PostDto[]) {
      if (!backgroundActions.value.includes(post.id as string)) {
        upsertPost(post)
      }
    }

    isFetching.value = false

    return posts.value
  }

  async function createPost(post: CreatePostDto) {
    const response = await postPosts(post)
    upsertPost(response)
    return response
  }

  const updatePromises = ref<Record<string, Promise<PostDto | void>>>({})
  const isUpdating = computed(() => unwrap.keys(updatePromises.value))

  async function reschedulePost(id: string, payload: UpdatePostDto) {
    // eslint-disable-next-line no-async-promise-executor
    const thisPromise = new Promise<PostDto>(async (resolve, reject) => {
      const post = entities.value[id]
      if (!post?.id) {
        throw new Error('Could not update post without id')
      }

      const newPost = { ...post, scheduledAt: payload.publishAt } as PostDto
      upsertPost(newPost)

      const response = await putPostsId(id, payload)

      if (thisPromise !== updatePromises.value[id]) {
        reject('This promise was replaced by a newer one')
      } else {
        upsertPost(response)
        delete updatePromises.value[id]

        const { showToast } = useToast()

        showToast({
          type: toastEvents.TOAST_SUCCESS,
          title: `Clip Rescheduled!`,
          subtitle: 'Your awesome clip has been rescheduled successfully!',
          view: () => {
            router.push({
              name: dashboardRouteNames.contentPublisher.root,
              query: {
                post: id,
              },
            })
          },
          viewTitle: 'View post'
        })

        resolve(response)
      }

      // ignore rejection, it is handled internally
    }).catch(console.warn)

    updatePromises.value[id] = thisPromise
    return thisPromise
  }

  const isSyncing = ref<string[]>([])

  async function syncPost(postId: string) {
    if (!postId) {
      throw new Error('Could not sync post without id')
    }

    isSyncing.value.push(postId)
    const response = await getPostsId(postId)
    upsertPost(response)
    isSyncing.value = isSyncing.value.filter((id) => id !== postId)
  }

  const isRemoving = ref<string[]>([])

  async function removePost(id: string) {
    isRemoving.value.push(id)
    const post = { ...entities.value[id] }

    try {
      delete entities.value[id]
      await deletePostsId(id)
    } catch (e) {
      upsertPost(post)
    }

    isRemoving.value = isRemoving.value.filter((postId) => postId !== id)
  }

  async function refetch() {
    const { start, end } = getValidRange()
    await fetchPosts(start, end)
  }
  
  const debouncedRefetch = debounce(refetch, 100)

  async function retryPost(id: string) {
    const post = entities.value[id]
    if (!post) {
      throw new Error('Could not retry post without id')
    }
    await patchPostsIdRetry(id);
  }

  const backgroundActions = computed(() => [...isRemoving.value, ...isUpdating.value, ...isSyncing.value])
  const isPerformingBackgroundAction = computed(() => backgroundActions.value.length > 0)

  return {
    entities,
    posts,
    ids,
    events,
    backgroundActions,
    isPerformingBackgroundAction,
    removePost,
    syncPost,
    reschedulePost,
    isFetching,
    refetch: debouncedRefetch,
    fetch: debouncedRefetch,
    createPost,
    retryPost,
    fetchByDateRange: fetchPosts,
    accountFilter
  }
})
