import { deleteCustomCaptionPreset, fetchCustomCaptionPresets, storeCustomCaptionPreset } from '@/queries/customCaptionPresetsApi'
import type { CustomCaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import { requestUserSignInAsync } from '@/authentication/supabase';
import { onUserInfoReadyAsync } from '@/store/user/userInfo';

export default {
  async list() {
    const { isAuthenticated } = await onUserInfoReadyAsync();
    if (isAuthenticated) {
      return await fetchCustomCaptionPresets();
    } else {
      return [];
    }
  },

  async save(request: CustomCaptionPreset) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      await storeCustomCaptionPreset(request);
    } else {
      throw new Error('User is not signed in');
    }
  },

  async remove(request: { presetId: string }) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      await deleteCustomCaptionPreset(request.presetId);
    } else {
      throw new Error('User is not signed in');
    }  
  },
}
