<script setup lang="ts">
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import { expandHexColor, hexToHsb, hexToRgb, hsbToHex, rgbToHex } from '@/components/colors/helpers'
import { Button } from '@/components/ui/button'
import { useTheme } from '@/Hooks/useTheme'
import { cn } from '@/lib/utils'
import { useClipboard } from '@vueuse/core'
import { clamp, throttle } from 'lodash-es'
import { LucideCopy, LucideCopyCheck } from 'lucide-vue-next'
import tinycolor from 'tinycolor2'
import { z } from 'zod'

interface Props {
  recentlyUsedColors?: readonly string[]
  recommendedColors?: readonly string[]
}

const colorCodes = ['hex', 'rgb'] as const

defineProps<Props>()

const hexColor = defineModel<string>('hex', { required: true })

const { theme } = useTheme()

const emit = defineEmits<{
  (e: 'commit-value'): void
  (e: 'isInteracting', value: boolean): void
}>()

const hex = ref<string>(hexColor.value)
const rgb = ref<{ r: number; g: number; b: number }>({ r: 0, g: 0, b: 0 })

const colorCode = ref<(typeof colorCodes)[number]>('hex')

const hue = ref<number>(0)
const saturation = ref<number>(0)
const brightness = ref<number>(0)

const invalidHexForm = ref(false)
const hexForm = z.object({
  hex: z.string().refine((value) => /^#?[0-9a-fA-F]{2,6}$/i.test(value), {
    message: 'Invalid hex code',
  }),
})

const invalidRgbForm = ref(false)
const rgbForm = z.object({
  r: z.number().int().min(0).max(255),
  g: z.number().int().min(0).max(255),
  b: z.number().int().min(0).max(255),
})

const isDragging = ref(false)

const grid = ref<HTMLElement | null>(null)
const updateSaturationBrightness = (event: MouseEvent | TouchEvent) => {
  if (!isDragging.value) {
    return
  }

  if (grid.value instanceof HTMLElement) {
    const rect = grid.value.getBoundingClientRect()

    const clientX = event instanceof MouseEvent ? event.clientX : event.touches[0].clientX
    const clientY = event instanceof MouseEvent ? event.clientY : event.touches[0].clientY

    const x = clamp(clientX - rect.left, 0, rect.width)
    const y = clamp(clientY - rect.top, 0, rect.height)

    saturation.value = Math.round((x / rect.width) * 100)
    brightness.value = Math.round(((rect.height - y) / rect.height) * 100)

    updateColorFromHsb()
  }
}

function onMouseDown(event: MouseEvent | TouchEvent) {
  isDragging.value = true
  document.getElementById('app')?.classList.add('pointer-events-none', 'select-none')
  window.addEventListener('mouseup', onMouseUp)
  window.addEventListener('touchend', onMouseUp)
  window.addEventListener('mousemove', updateSaturationBrightness)
  window.addEventListener('touchmove', updateSaturationBrightness)
  updateSaturationBrightness(event)
  emit('isInteracting', true)
}

function onMouseUp() {
  isDragging.value = false
  document.getElementById('app')?.classList.remove('pointer-events-none', 'select-none')
  window.removeEventListener('mouseup', onMouseUp)
  window.removeEventListener('touchend', onMouseUp)
  window.removeEventListener('mousemove', updateSaturationBrightness)
  window.removeEventListener('touchmove', updateSaturationBrightness)
  emit('commit-value')
  emit('isInteracting', false)
}

function updateColorFromHex() {
  invalidHexForm.value = false
  invalidRgbForm.value = false

  const normalized = hex.value.toLowerCase()

  const validate = hexForm.safeParse({ hex: normalized })
  if (!validate.success) {
    invalidHexForm.value = true
    return
  }

  hex.value = expandHexColor(normalized)
  rgb.value = { ...hexToRgb(hex.value) }

  const hsb = hexToHsb(hex.value)
  hue.value = hsb.h
  saturation.value = hsb.s
  brightness.value = hsb.b

  if (hexColor.value !== hex.value) {
    hexColor.value = hex.value
  }
}

function updateColorFromRgb() {
  invalidHexForm.value = false
  invalidRgbForm.value = false

  const validate = rgbForm.safeParse(rgb.value)
  if (!validate.success) {
    invalidRgbForm.value = true
    return
  }

  rgb.value = { r: validate.data.r, g: validate.data.g, b: validate.data.b }
  hex.value = rgbToHex(rgb.value.r, rgb.value.g, rgb.value.b)

  const hsb = hexToHsb(hex.value)
  hue.value = hsb.h
  saturation.value = hsb.s
  brightness.value = hsb.b

  if (hexColor.value !== hex.value) {
    hexColor.value = hex.value
  }
}

function updateColorFromHsb() {
  hex.value = hsbToHex(hue.value, saturation.value, brightness.value)
  rgb.value = { ...hexToRgb(hex.value) }
  if (hexColor.value !== hex.value) {
    hexColor.value = hex.value
  }
}

function onClickColor(color: string) {
  hex.value = color
  updateColorFromHex()
  emit('commit-value')
}

function pasteRgb(event: ClipboardEvent) {
  const value = event.clipboardData?.getData('text') ?? ''
  const match = value.match(/(\d{1,3})[^\d]+(\d{1,3})[^\d]+(\d{1,3})/)

  if (!match) {
    return
  }

  event.preventDefault()

  const [_, r, g, b] = match.map(Number)

  rgb.value = { r, g, b }
  updateColorFromRgb()
}

const { copy, copied, isSupported } = useClipboard()

function copyToClipboard() {
  if (colorCode.value === 'hex') {
    copy(hex.value)
  } else if (colorCode.value === 'rgb') {
    copy(`rgb(${rgb.value.r}, ${rgb.value.g}, ${rgb.value.b})`)
  }
}

updateColorFromHex()
updateColorFromRgb()
updateColorFromHsb()

watch(
  [hue, saturation, brightness],
  throttle(([h, s, b]) => {
    const hex = hsbToHex(h, s, b)
    if (hexColor.value !== hex) {
      hexColor.value = hex
    }
  }, 100)
)

function toBorderColor(color: string, theme: 'dark' | 'light') {
  switch (theme) {
    case 'dark':
      return tinycolor(color).lighten(20).toString()
    case 'light':
      return tinycolor(color).darken(20).toString()
  }
}

const borderColorOf = computed(() => {
  return (color: string) => {
    return toBorderColor(color, theme.value)
  }
})
</script>

<template>
  <div class="relative h-0 w-full pb-[100%]">
    <div
      ref="grid"
      class="sb-grid absolute inset-0 select-none overflow-hidden rounded-lg border border-input"
      @mousedown="onMouseDown"
      @touchstart="onMouseDown"
      :style="{ backgroundColor: `hsl(${hue}, 100%, 50%)` }"
    />
    <div
      class="pointer-events-none absolute h-6 w-6 -translate-x-1/2 -translate-y-1/2 select-none rounded-full border-2 border-white shadow"
      :style="{ left: saturation + '%', top: 100 - brightness + '%', backgroundColor: hexColor }"
    />
  </div>

  <div class="relative mt-4 flex h-6 items-center" :data-hue="hue">
    <div class="hue-slider absolute inset-0 h-6 w-full select-none rounded-md">
      <div
        class="absolute top-1/2 box-content h-6 w-3 -translate-x-1/2 -translate-y-1/2 rounded-md border-2 border-white shadow"
        :style="{ left: (hue / 359) * 100 + '%', background: `hsl(${hue}, 100%, 50%)` }"
      />
    </div>

    <input type="range" :min="0" :max="359" v-model="hue" class="h-6 w-full cursor-pointer select-none opacity-0" />
  </div>

  <div class="mt-3 flex gap-1">
    <SelectDropdown
      v-model="colorCode"
      :options="colorCodes.map((code) => ({ label: code.toUpperCase(), value: code }))"
      class="h-auto !min-w-min px-2 py-1 text-sm font-normal md:text-xs"
    />

    <template v-if="colorCode === 'hex'">
      <form @submit.prevent="updateColorFromHex" class="w-full">
        <input
          v-model="hex"
          name="hex"
          type="text"
          placeholder="a000fe"
          @blur.prevent="updateColorFromHex"
          :class="
            cn(
              'h-full w-full rounded-md border border-input bg-background px-2 py-1 text-sm font-normal md:text-xs',
              {
                'border-error': invalidHexForm,
              }
            )
          "
        />
      </form>
    </template>

    <template v-else-if="colorCode === 'rgb'">
      <div class="grid w-full grid-cols-3 gap-1">
        <input
          v-model.number="rgb.r"
          type="number"
          min="0"
          max="256"
          placeholder="r"
          @input="updateColorFromRgb"
          @paste="pasteRgb"
          :class="
            cn(
              'h-full w-full rounded-md border border-input bg-background px-2 py-1 text-sm font-normal md:text-xs',
              {
                'border-error': invalidRgbForm,
              }
            )
          "
        />
        <input
          v-model.number="rgb.g"
          type="number"
          min="0"
          max="256"
          placeholder="g"
          @input="updateColorFromRgb"
          @paste="pasteRgb"
          :class="
            cn(
              'h-full w-full rounded-md border border-input bg-background px-2 py-1 text-sm font-normal md:text-xs',
              {
                'border-error': invalidRgbForm,
              }
            )
          "
        />
        <input
          v-model.number="rgb.b"
          type="number"
          min="0"
          max="256"
          placeholder="b"
          @input="updateColorFromRgb"
          @paste="pasteRgb"
          :class="
            cn(
              'h-full w-full rounded-md border border-input bg-background px-2 py-1 text-sm font-normal md:text-xs',
              {
                'border-error': invalidRgbForm,
              }
            )
          "
        />
      </div>
    </template>

    <Button
      v-if="isSupported"
      @click="copyToClipboard"
      variant="ghost"
      size="sm"
      class="h-auto p-1 text-xs font-normal lowercase"
    >
      <LucideCopy v-if="!copied" class="h-4 w-4" />
      <LucideCopyCheck v-else class="h-4 w-4" />
    </Button>
  </div>

  <div v-if="recentlyUsedColors?.length || recommendedColors?.length" class="mt-2"></div>

  <template v-if="recentlyUsedColors?.length">
    <p class="mb-1 text-base font-normal text-muted-foreground md:text-sm">Recently used</p>
    <div class="grid grid-cols-8 gap-1">
      <template v-for="color in recentlyUsedColors" :key="color">
        <div
          :style="{ backgroundColor: color, borderColor: borderColorOf(color) }"
          class="grid aspect-square w-full cursor-pointer place-items-center rounded border-2 border-transparent shadow-sm"
          @click="onClickColor(color)"
        >
          <IconCheck
            v-if="hexColor.toLowerCase() === color.toLowerCase()"
            :class="cn('size-6 text-white md:size-4', { 'text-black': !tinycolor.isReadable('#ffffff', color) })"
          />
        </div>
      </template>
    </div>

    <hr v-if="recommendedColors?.length" class="my-2" />
  </template>

  <template v-if="recommendedColors?.length">
    <p class="mb-1 text-base font-normal text-muted-foreground md:text-sm">Recommended</p>
    <div class="grid grid-cols-8 gap-1">
      <template v-for="color in recommendedColors" :key="color">
        <div
          :style="{ backgroundColor: color, borderColor: borderColorOf(color) }"
          class="grid aspect-square w-full cursor-pointer place-items-center rounded border-2 border-transparent shadow-sm"
          @click="onClickColor(color)"
        >
          <IconCheck
            v-if="hexColor.toLowerCase() === color.toLowerCase()"
            :class="cn('size-6 text-white md:size-4', { 'text-black': !tinycolor.isReadable('#ffffff', color) })"
          />
        </div>
      </template>
    </div>
  </template>
</template>

<style scoped>
.sb-grid {
  cursor: crosshair;
  background-image: linear-gradient(0deg, hsl(0, 0%, 0%), transparent),
    linear-gradient(90deg, hsl(0, 0%, 100%), transparent);
}

.hue-slider {
  background: linear-gradient(to right, #ff0000, #ffff00, #00ff00, #00ffff, #0000ff, #ff00ff, #ff0000);
}
</style>
