<script lang="ts" setup>
import { useHead } from '@unhead/vue'
import { useRoute } from 'vue-router'
import { onMounted, ref, computed, onUnmounted } from 'vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Button } from '@/components/ui/button'
import { getApiClipGptProjectsProjectId } from '@/apis/streamladder-api/clip-gpt-projects/clip-gpt-projects'
import type { ClipGptProjectDto } from '@/apis/streamladder-api/model'
import settings from '@/data/settings'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import Spinner from '@/components/Icons/Spinner.vue'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { useTheme } from '@/Hooks/useTheme'
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import SilverPlanButton from '@/components/Account/Upgrade/SilverPlanButton.vue'
import { useUserInfoStore, onUserInfoReadyAsync } from '@/store/user/userInfo'
import ClipGptClipCard from '@/areas/dashboard/pages/clipGPT/ClipGptClipCard.vue'
import { orderBy } from 'lodash-es'

useHead({
  title: 'Clip GPT',
  meta: [
    {
      name: 'description',
      content: 'Generate clips from your twitch VODs',
    },
  ],
})

const route = useRoute()

onMounted(async () => {
  if (typeof route.params.projectId === 'string') {
    await onUserInfoReadyAsync()
    await pollProjectStatus(route.params.projectId)
  }
})

const abortController = new AbortController()
const project = ref<ClipGptProjectDto | null>(null)
const isFetchError = ref<boolean>(false)
async function pollProjectStatus(projectId: string | undefined, options = { wait: 0 }) {

  if (projectId) {

    await new Promise(resolve => setTimeout(resolve, options.wait))
    if (abortController.signal.aborted) {
      return;
    }

    console.log('Polling project status', projectId)
    try {
      const result = await getApiClipGptProjectsProjectId(projectId, { signal: abortController.signal })
      project.value = result
      if (result.status !== 'succeeded' && result.status !== 'failed') {
        await pollProjectStatus(result.id, { wait: 20_000 })
      }
    } catch (e) {
      isFetchError.value = true
    }
  }
}

onUnmounted(() => {
  abortController.abort()
})

const clips = computed(() => project.value?.clips ?? [])
function pauseAllOthers(url?: string) {
  for (const video of document.querySelectorAll('video')) {
    if (video.src !== url) {
      video.pause()
    }
  }
}

const steps = [ 'Sending your stream to AI', 'Identifying key moments', 'Cooking up your epic clips' ]

const currentStepIndex = computed(() => {
  switch (project.value?.stage) {
    case 'downloadAudio':
    case 'transcribeAudio':
      return 0
    case 'promptAiForClips':
      return 1
    case 'downloadClips':
      return 2
    default:
      return -1
  }
})

const error = computed(() => {

  if (isFetchError.value) {
    return { title: 'Project not found 🚫', description: 'The project you are looking for does not exist. Please try again with a different project or contact support if the issue persists.' }
  } else if (project.value?.errorMessage === 'audio_download_failed') {
    return { title: 'Audio download failed 🔇', description: 'Our AI couldn\'t find any highlights in your video. Please verify whether your video has audio, is not copyright blocked in parts of your video and has clear speech. Try again with a different stream or contact support!' }
  } else if (project.value?.errorMessage === 'transcription_failed') {
    return { title: 'Our AI couldn\'t transcript the video 🗣️', description: 'Our AI couldn\'t find any highlights in your video. Please verify whether your video has audio, is not copyright blocked in parts of your video and has clear speech. Try again with a different stream or contact support!' }
  } else if (project.value?.errorMessage === 'prompt_ai_failed') {
    return { title: 'Failed creating an AI prompt for your video 📝', description: 'Our AI couldn\'t find any highlights in your video. Please contact our support or try a different video.' }
  } else if (project.value?.errorMessage === 'no_clips_found') {
    return { title: 'No clips found 🚫', description: 'Our AI couldn\'t find any highlights in your video. Please verify whether your video has audio, is not copyright blocked in parts of your video and has clear speech. Try again with a different stream or contact support!' }
  } else if (project.value?.status === 'failed') {
    return { title: 'Something went wrong 😕', description: 'Oops! Something didn’t go as planned. Please try again later or contact support if the issue persists.' }
  } else if (project.value?.status === 'succeeded' && clips.value.length === 0) {
    return { title: 'No results found 🚫', description: 'It seems there were no clips generated from your stream. Try again with a different stream or contact support!' }
  } else if (project.value?.status === 'succeeded' && clips.value.filter(clip => clip.status !== 'failed').length === 0) {
    return { title: 'All clips failed to process ❌', description: 'Unfortunately, all generated clips failed to process. Please try again with a different stream or contact support for assistance.' }
  } else {
    return null
  }
})

const { theme } = useTheme()
const userInfoStore = useUserInfoStore()

const sortedClips = computed(() => orderBy(clips.value, 'score', 'desc').filter(clip => clip.status !== 'failed'))

</script>

<template>
  <section :class="theme" id="clip-gpt-project">
    <div class="layer-0 !bg-zinc-200 dark:!bg-black min-h-[100dvh]">
      <nav class="container mx-auto p-4 sticky top-0 z-[10]">
        <div class="flex w-full items-center justify-between gap-2 layer-1 rounded-xl shadow p-2">
          <RouterLink :to="{ name: dashboardRouteNames.clipGpt.root }">
            <Button variant="ghost">
              <IconSaxArrowLeft />
              <span class="inline md:hidden">Back</span>
              <span class="hidden md:inline">Back to Dashboard</span>
            </Button>
          </RouterLink>

          <RouterLink
            :to="{ name: dashboardRouteNames.dashboard }"
            class="flex flex-nowrap items-center gap-4 overflow-x-hidden rounded-lg px-4 py-2 text-brand-state-text-primary"
          >
            <img alt="StreamLadder branding" class="h-8 w-8" height="192" src="/images/logo.png" width="192" />
            <span class="flex items-center gap-2">
          <span class="h-4 text-lg font-semibold leading-[1]">StreamLadder</span>
          <GoldPlanButton v-if="userInfoStore.tier === tiers.GOLD" class="pointer-events-none h-4 w-4" small />
          <SilverPlanButton v-else-if="userInfoStore.tier === tiers.SILVER" class="pointer-events-none h-4 w-4" small />
          <span v-else class="flex rounded bg-white px-1 py-0.5 text-xs font-semibold uppercase text-indigo-500">
            {{ userInfoStore.plan }}
          </span>
        </span>
          </RouterLink>
        </div>
      </nav>
      <main class="flex flex-col flex-1 p-4 container mx-auto">
        <div v-if="project && project.status === 'succeeded' && !error" class="flex flex-col gap-8 h-full w-full justify-self-start">
          <header class="text-center flex flex-col gap-1 mt-8">
            <h2 class="font-light text-base">
              The best moments from <a :href="`https://www.twitch.tv/videos/${project.videoId}`" target="_blank" class="underline hover:no-underline">{{ project.title! }}</a>
            </h2>
            <h1 class="text-2xl lg:text-4xl xl:text-5xl 2xl:text-6xl font-title font-black">
              We’ve created <span class="text-transparent bg-clip-text bg-gradient-to-tl from-[#DD8AF8] to-[#764FF5]">{{ sortedClips.length }} clips!</span>
            </h1>
          </header>

          <nav class="flex flex-col gap-2 lg:gap-4">
            <ClipGptClipCard v-for="clip in sortedClips" :key="clip.id" :project="project" :clip="clip" :clips="sortedClips" @play="url => pauseAllOthers(url)" />
          </nav>
        </div>
        <Transition
          v-else-if="project"
          enter-active-class="motion-safe:transition-[transform,_opacity]"
          enter-from-class="opacity-0 translate-x-6"
          leave-active-class="motion-safe:transition-[transform,_opacity]"
          leave-to-class="opacity-0 -translate-x-6"
        >
          <div :key="error ? 'error' : 'pending'" class="flex flex-col items-center justify-center gap-8 my-auto p-4 lg:p-8">
            <template v-if="error">
              <LottieAnimation auto-play class="h-36 w-36" loop url="/lottie/error.json" />
              <header class="flex flex-col items-center gap-1">
                <h1 class="text-2xl font-semibold text-brand-state-text-primary">
                  {{ error.title }}
                </h1>
                <p class="text-sm text-brand-state-text-secondary max-w-[50ch] text-center leading-[1.7] text-pretty">
                  {{ error.description }}
                </p>
              </header>

              <div class="w-full flex flex-col items-center justify-center mt-6 gap-2">
                <RouterLink :to="{ name: dashboardRouteNames.clipGpt.root }">
                  <Button variant="primary">
                    Try a different stream
                  </Button>
                </RouterLink>
                <Button as="a" :href="settings.discordInviteUrl" target="_blank" variant="ghost" class="font-normal">
                  <DiscordLogo class="shrink-0 w-5 h-5 fill-current" />
                  Open a ticket
                </Button>
              </div>
            </template>
            <template v-else>
              <LottieAnimation auto-play class="h-72 w-72" loop url="/lottie/clip-gpt/spinner-lg.json" />
              <header class="flex flex-col items-center gap-1">
                <h1 class="text-2xl md:text-3xl lg:text-5xl text-brand-state-text-primary font-title font-bold text-center text-balance">
                  We’re <span class="text-transparent bg-clip-text bg-gradient-to-tl from-[#DD8AF8] to-[#764FF5]">creating</span> your clips!
                </h1>
                <p class="text-brand-state-text-secondary font-light text-center leading-[1.7] text-pretty">
                  This might take up to 10 minutes, depending on how long your stream is.
                </p>
              </header>

              <ol class="flex flex-col gap-2">
                <li class="flex items-center gap-4" v-for="(step, index) in steps" :key="step">
                  <div class="w-10 h-10 text-[#D9D9D9]">
                    <template v-if="currentStepIndex !== -1">
                      <div class="w-full h-full grid place-items-center" v-if="currentStepIndex < index">
                        <div class="flex items-center gap-1">
                          <div class="w-1.5 h-1.5 bg-current rounded-full" />
                          <div class="w-1.5 h-1.5 bg-current rounded-full" />
                          <div class="w-1.5 h-1.5 bg-current rounded-full" />
                        </div>
                      </div>
                      <Spinner class="w-full h-full fill-emerald-500 animate-spin" v-if="currentStepIndex === index" />
                      <LottieAnimation url="/lottie/success.json" auto-play :loop="false" class="w-14 h-14 -m-2" v-else-if="currentStepIndex > index" />
                    </template>
                  </div>
                  <span class="font-light">
                  {{ step }}
                </span>
                </li>
              </ol>

              <div class="mt-8 flex max-w-md rounded-lg border-2 border-green-500 bg-green-100 p-3 dark:bg-green-900">
                <IconSaxInfoCircle class="mr-3 h-full w-6 shrink-0 text-green-700 dark:text-green-400" />
                <span class="cursor-default font-light text-green-700 dark:text-green-400 text-pretty">
                  Feel free to close this tab – we're working on your clips!
                </span>
              </div>
            </template>
          </div>
        </Transition>
      </main>
    </div>
  </section>
</template>

<style lang="scss" scoped></style>
