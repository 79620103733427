<script lang="ts" setup>
import { useHead } from '@unhead/vue';
import { Button } from '@/components/ui/button';
import { ref } from 'vue';
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue';
import { useUserInfoStore } from '@/store/user/userInfo';
import { getUser, supabase } from '@/authentication/supabase';
import { useQuery } from '@tanstack/vue-query';
import { Pagination, PaginationLast, PaginationEllipsis, PaginationFirst, PaginationPrev, PaginationList, PaginationListItem, PaginationNext } from '@/components/ui/pagination';
import { Skeleton } from '@/components/ui/skeleton';
import { usePrevious } from '@vueuse/core';
import { Select, SelectContent, SelectValue, SelectTrigger, SelectItem } from '@/components/ui/select';
import { range } from 'lodash-es';
import type { Project } from '@/areas/editor/@type/Project';
import ProjectPreviewButton from '@/areas/dashboard/pages/recent-projects/ProjectPreviewButton.vue';
import type { ProjectOverviewItem } from '@/areas/dashboard/pages/recent-projects/ProjectOverviewItem';
import type { EditorProject } from '@/areas/editor/@type/editor-project';

useHead({
  title: 'My Videos',
  meta: [
    {
      name: 'description',
      content: 'Check out the clips you created in the last 30 days.',
    },
  ],
});

const userInfoStore = useUserInfoStore();
const userId = computed(() => userInfoStore.userId);

const currentPage = ref(1);
const previousPage = usePrevious(currentPage, 1);
const direction = computed(() => Math.sign(currentPage.value - previousPage.value));

const total = ref(1);
const itemsPerPage = ref(24);
const itemsPerPageStringModel = computed({
  get() {
    return itemsPerPage.value.toString();
  },
  set(value: string) {
    currentPage.value = Math.max(1, Math.floor(itemsPerPage.value * currentPage.value / Number(value)));
    itemsPerPage.value = Number(value);
  },
});

const { data: projects, isPending } = useQuery({

  queryKey: ['projects', userId, currentPage, itemsPerPage],
  queryFn: async (): Promise<ProjectOverviewItem[]> => {

    const { data: { user } } = await getUser();
    if (!user?.id) {
      return [];
    }

    const current = currentPage.value - 1;
    const next = current + 1;

    const { data, count } = await supabase.from('ClipEditorProjects')
      .select(`
        id:           Id,
        created_at:   CreatedAt,
        version:      Version,
        json:         Json
      `, { count: 'exact' })
      .eq('UserId', user.id)
      .filter('CreatedAt', 'gt', new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString())
      .order('CreatedAt', { ascending: false })
      .range(current * itemsPerPage.value, next * itemsPerPage.value);

    if (count) total.value = count;

    return (data ?? []).map<ProjectOverviewItem>(project => ({
      ...project,
      json: project.json as unknown as EditorProject | Project,
      time_ago_ms: Date.now() - new Date(project.created_at).getTime(),
    }));
  },
  enabled: !!userId,
});

const itemsOnPage = computed(() => Math.min(itemsPerPage.value, (projects.value?.length ?? Infinity)));
</script>

<template>
  <main class="flex flex-col gap-4 p-4 lg:p-12">
    <div class="flex flex-row flex-wrap items-end justify-between gap-2">
      <header class="flex flex-col">
        <h1 class="text-3xl lg:text-5xl">My Projects</h1>
        <p class="font-light text-opacity-50">Below are the projects you created in the last 7 days.</p>
      </header>

      <div class="flex gap-2 items-center">
        <p class="text-sm whitespace-nowrap">Items per page</p>
        <Select v-model="itemsPerPageStringModel">
          <SelectTrigger>
            <SelectValue />
          </SelectTrigger>
          <SelectContent align="end">
            <SelectItem v-for="option in [12, 24, 48, 96]" :key="option" :value="option.toString()">
              {{ option }}
            </SelectItem>
          </SelectContent>
        </Select>
      </div>
    </div>

    <section>
      <template v-if="projects?.length || isPending ">
        <div class="relative">
          <Transition
            :enter-from-class="direction === -1 ? 'opacity-0 -translate-x-12' : 'opacity-0 translate-x-12'"
            :leave-to-class="direction === -1 ? 'opacity-0 translate-x-12' : 'opacity-0 -translate-x-12'"
            enter-active-class="motion-safe:transition"
            leave-active-class="motion-safe:transition"
          >
            <ol :key="currentPage" class="grid grid-cols-2 md:grid-cols-3 2xl:grid-cols-4 gap-4">
              <li
                v-for="i in range(itemsOnPage)"
                :key="'project-' + currentPage + '-' + i"
                class="group"
              >
                <div v-if="isPending" class="flex flex-col gap-1">
                  <Skeleton class="w-full aspect-video" />
                  <Skeleton class="w-3/4 h-6" />
                  <Skeleton class="w-1/2 h-4" />
                </div>

                <ProjectPreviewButton v-else-if="projects?.[i]" :project="projects[i]" />
              </li>
            </ol>
          </Transition>
        </div>

        <Pagination
          v-if="(total / itemsPerPage) > 1"
          v-slot="{ page }"
          v-model:page="currentPage"
          :default-page="1"
          :items-per-page="itemsPerPage"
          :sibling-count="1"
          :total="total"
          class="flex justify-center items-center max-w-full mt-4"
          show-edges
        >
          <PaginationList v-slot="{ items }" class="flex items-center gap-1 max-w-[300px] md:max-w-full">
            <PaginationFirst />
            <PaginationPrev />

            <template v-for="(item, index) in items">
              <PaginationListItem v-if="item.type === 'page'" :key="index" :value="item.value" as-child>
                <Button :variant="item.value === page ? 'default' : 'outline'" class="h-10 w-10 p-0">
                  {{ item.value }}
                </Button>
              </PaginationListItem>
              <PaginationEllipsis v-else :key="item.type" :index="index" />
            </template>

            <PaginationNext />
            <PaginationLast />
          </PaginationList>
        </Pagination>
      </template>

      <NoContentSection
        v-else
        subtitle="Create your first project now!"
        title="You haven't created any project in the last 7 days."
      >
        <Button as-child variant="primary">
          <RouterLink :to="{ name: 'editor' }">
            Create Project
          </RouterLink>
        </Button>
      </NoContentSection>
    </section>
  </main>
</template>

<style lang="scss" scoped></style>
