<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import RadioToggleButton from '@/components-v2/data-input/RadioToggleButton.vue'
import type { Gradient } from '@/components/Captions/v3/CaptionPreset'
import { ColorInput } from '@/components/colors'
import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'
import { useRecentlyUsedCaptionColors } from '@/Hooks/captions/useRecentlyUsedCaptionColors'
import { cn } from '@/lib/utils'
import { capitalize } from 'vue'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()

const emit = defineEmits<{ (e: 'changed'): void }>()

const { recommendedColors, recentlyUsedCaptionColors, setRecentlyUsedColor } = useRecentlyUsedCaptionColors()

const toggleEmojis = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_EMOJIS', () => {
    captionsStore.baseOptions.emojis = !captionsStore.baseOptions.emojis
  })
}

const toggleRotation = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_ROTATION', () => {
    captionsStore.baseOptions.rotate = !captionsStore.baseOptions.rotate
  })
}

const toggleStripPunctuation = () => {
  historyStore.transaction('CAPTIONS:TOGGLE_STRIP_PUNCTUATION', () => {
    captionsStore.baseOptions.stripPunctuation = !captionsStore.baseOptions.stripPunctuation
  })
}

const animationGroupingModel = computed({
  get() {
    return captionsStore.baseOptions.grouping
  },
  set(value) {
    historyStore.transaction('CAPTIONS:CHANGE_ANIMATION_GROUPING', () => {
      captionsStore.baseOptions.grouping = value
    })
  },
})

const updateTiming = (value: string | null | undefined) => {
  historyStore.transaction('CAPTIONS:CHANGE_ANIMATION_TIMING', () => {
    if (value === 'single') {
      captionsStore.baseOptions.animationTarget = 'word'
    } else if (value === 'group' && captionsStore.baseOptions.animationTarget === 'word') {
      captionsStore.baseOptions.highlight = false
    }
  })
}

const highlightModel = computed({
  get() {
    return captionsStore.baseOptions.highlight
  },
  set(value) {
    historyStore.transaction('CAPTIONS:TOGGLE_HIGHLIGHT', () => {
      captionsStore.baseOptions.highlight = value
    })
  },
})

const fontHighlightColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.font.highlightColor
  },
  set(color: string | Gradient) {
    emit('changed')
    captionsStore.baseCaptionPreset.font.highlightColor = color
  },
})

const strokeHighlightColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.stroke.highlightColor
  },
  set(color: string) {
    emit('changed')
    captionsStore.baseCaptionPreset.stroke.highlightColor = color
  },
})

const shadowHighlightColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.shadow.highlightColor
  },
  set(color: string) {
    emit('changed')
    captionsStore.baseCaptionPreset.shadow.highlightColor = color
  },
})

const glowHighlightColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.glow.highlightColor
  },
  set(color: string) {
    emit('changed')
    captionsStore.baseCaptionPreset.glow.highlightColor = color
  },
})

const backgroundHighlightColorModel = computed({
  get() {
    return captionsStore.baseCaptionPreset.background.highlightColor
  },
  set(color: string) {
    emit('changed')
    captionsStore.baseCaptionPreset.background.highlightColor = color
  },
})

const isGradient = computed(() => typeof captionsStore.baseCaptionPreset.font.highlightColor !== 'string')
const gradientData = computed(() => captionsStore.baseCaptionPreset.font.highlightColor as Gradient)

const cssGradient = computed(() => {
  if (!isGradient.value) {
    return captionsStore.baseCaptionPreset.font.highlightColor as string
  }

  const sortedStops = [...gradientData.value.colorStops].sort((a, b) => a.stop - b.stop)
  const stopsString = sortedStops.map((stop) => `${stop.color} ${stop.stop * 100}%`).join(', ')

  if (gradientData.value.variant === 'linear') {
    return `linear-gradient(${gradientData.value.angle + 90}deg, ${stopsString})`
  }
  if (gradientData.value.variant === 'radial') {
    return `radial-gradient(${stopsString})`
  }
  return ''
})

function updateFontColorCallback(color: string | Gradient) {
  if (typeof color === 'string') {
    setRecentlyUsedColor(color)
  } else {
    for (const stop of color.colorStops) {
      setRecentlyUsedColor(stop.color)
    }
  }

  captionsStore.savePreset(captionsStore.currentUserCaption!)
}

function onCloseColorPicker(color: string) {
  setRecentlyUsedColor(color)
  captionsStore.savePreset(captionsStore.currentUserCaption!)
}
</script>

<template>
  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="my-4 flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <section class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
        <header class="flex cursor-pointer items-start justify-between gap-2" @click="toggleRotation">
          <div class="flex flex-col gap-0">
            <h3 class="text-lg font-semibold">Random rotation</h3>
            <p class="text-sm font-light text-muted-foreground">Give each caption a slight and random rotation.</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.rotate" @click.stop />
        </header>
      </section>
    </div>

    <div class="flex flex-col gap-2">
      <section class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
        <header class="flex cursor-pointer items-start justify-between gap-2" @click="toggleStripPunctuation">
          <div class="flex flex-col gap-0">
            <h3 class="text-lg font-semibold">Hide punctuation</h3>
            <p class="text-sm font-light text-muted-foreground">Remove all ".?!" from captions</p>
          </div>

          <Switch v-model:checked="captionsStore.baseOptions.stripPunctuation" @click.stop />
        </header>
      </section>
    </div>

    <div class="flex flex-col gap-4">
      <section class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
        <header class="flex cursor-pointer items-start justify-between gap-2">
          <div class="flex flex-col gap-0">
            <h3 class="text-lg font-semibold">Display grouping</h3>
            <p class="text-sm font-light text-muted-foreground">
              Display each word individually or group words into sentences.
            </p>
          </div>
        </header>

        <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
          <RadioToggleButton v-for="option in [
            { label: 'Words', value: 'single' },
            { label: 'Sentences', value: 'group' },
          ]" :key="option.value" :value="option.value" v-model="animationGroupingModel"
            @update:model-value="(value) => updateTiming(value)"
            class="relative flex-1 shrink-0 flex-col !border-transparent bg-transparent font-light hover:bg-zinc-200 data-[state=active]:bg-primary data-[state=active]:font-semibold data-[state=active]:text-white">
            <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
            <span class="invisible font-semibold">{{ capitalize(option.label) }}</span>
          </RadioToggleButton>
        </footer>
      </section>

      <Transition enter-from-class="opacity-0 -translate-y-2" leave-to-class="opacity-0 -translate-y-2"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]">
        <section v-if="
          captionsStore.baseOptions.grouping !== 'single' &&
          captionsStore.baseOptions.animation !== '' &&
          captionsStore.baseOptions.animation !== 'fade-right'
        " class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
          <header class="flex cursor-pointer items-start justify-between gap-2">
            <div class="flex flex-col gap-0">
              <h3 class="text-lg font-semibold">Animation timing</h3>
              <p class="text-sm font-light text-muted-foreground">Choose when to animate your captions.</p>
            </div>
          </header>

          <footer class="grid grid-cols-2 gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-1">
            <RadioToggleButton v-for="option in [
              { label: 'Word by word', value: 'word' },
              { label: 'Sentence by sentence', value: 'sentence' },
            ]" :key="option.value" :value="option.value" @update:model-value="updateTiming"
              v-model="captionsStore.baseOptions.animationTarget"
              class="relative flex-1 shrink-0 flex-col truncate !border-transparent bg-transparent text-xs font-light hover:bg-zinc-200 data-[state=active]:bg-primary data-[state=active]:font-semibold data-[state=active]:text-white 2xl:text-base">
              <span class="absolute inset-auto">{{ capitalize(option.label) }}</span>
              <span class="invisible font-semibold">{{ capitalize(option.label) }}</span>
            </RadioToggleButton>
          </footer>
        </section>
      </Transition>

      <Transition enter-from-class="opacity-0 -translate-y-2" leave-to-class="opacity-0 -translate-y-2"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]">
        <section v-if="captionsStore.baseOptions.grouping !== 'single'"
          class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
          <header class="flex cursor-pointer items-start justify-between gap-2"
            @click="captionsStore.baseOptions.highlight = !captionsStore.baseOptions.highlight">
            <div class="flex flex-col gap-0">
              <h3 class="text-lg font-semibold">Highlight current spoken word</h3>
              <p class="text-sm font-light text-muted-foreground">
                Automatically highlight the current spoken word in your captions.
              </p>
            </div>

            <Switch v-model:checked="highlightModel" @click.stop />
          </header>
          <footer v-if="captionsStore.baseOptions.highlight" class="flex flex-col gap-3">
            <div class="w-full items-center rounded-lg border border-input bg-background p-3">
              <div class="flex flex-row items-center gap-3">
                <ColorInput v-model="fontHighlightColorModel" class="w-full shrink-0" side="right" allow-gradient
                  @commit-value="historyStore.transaction('CAPTIONS:CHANGE_HIGHLIGHT_COLOR')"
                  @close="updateFontColorCallback" :recently-used-colors="recentlyUsedCaptionColors"
                  :recommended-colors="recommendedColors.LIGHT">
                  <Button variant="toggle" class="h-auto border-none bg-transparent p-0 shadow-none">
                    <span :style="{ background: cssGradient }" class="h-7 w-7 rounded border border-border" />
                  </Button>
                </ColorInput>

                <span class="text-sm font-semibold">Fill</span>
              </div>
            </div>

            <div v-if="captionsStore.baseCaptionPreset?.stroke.editable"
              class="relative w-full items-center rounded-lg border border-input bg-background p-3">
              <div v-if="captionsStore.baseCaptionPreset?.stroke.width === 0"
                class="absolute inset-0 h-full w-full rounded-lg bg-gray-300 opacity-50"></div>
              <div class="flex flex-row items-center gap-3">
                <ColorInput v-model="strokeHighlightColorModel" class="w-full shrink-0" side="right"
                  @commit-value="historyStore.transaction('CAPTIONS:CHANGE_HIGHLIGHT_COLOR')"
                  @close="(color) => onCloseColorPicker(color as string)"
                  :recently-used-colors="recentlyUsedCaptionColors" :recommended-colors="recommendedColors.LIGHT">
                  <Button variant="toggle" :class="cn('h-auto border-none bg-transparent p-0 shadow-none', {
                    'bg-transparent-grid': captionsStore.baseCaptionPreset?.stroke.width === 0,
                  })
                    ">
                    <span :style="{
                      backgroundColor:
                        captionsStore.baseCaptionPreset?.stroke.width > 0 ? strokeHighlightColorModel : undefined,
                    }" class="h-7 w-7 rounded border border-border" />
                  </Button>
                </ColorInput>

                <span class="text-sm font-semibold">Outline</span>
              </div>
            </div>

            <div v-if="captionsStore.baseCaptionPreset?.shadow.editable"
              class="relative w-full items-center rounded-lg border border-input bg-background p-3">
              <div v-if="captionsStore.baseCaptionPreset?.shadow.opacity === 0"
                class="absolute inset-0 h-full w-full rounded-lg bg-gray-300 opacity-50"></div>
              <div class="flex flex-row items-center gap-3">
                <ColorInput v-model="shadowHighlightColorModel" class="w-full shrink-0" side="right"
                  @commit-value="historyStore.transaction('CAPTIONS:CHANGE_HIGHLIGHT_COLOR')"
                  @close="(color) => onCloseColorPicker(color as string)"
                  :recently-used-colors="recentlyUsedCaptionColors" :recommended-colors="recommendedColors.LIGHT">
                  <Button variant="toggle" :class="cn('h-auto border-none bg-transparent p-0 shadow-none', {
                    'bg-transparent-grid': captionsStore.baseCaptionPreset.shadow.blur === 0,
                  })
                    ">
                    <span :style="{
                      backgroundColor:
                        captionsStore.baseCaptionPreset.shadow.blur > 0 ? shadowHighlightColorModel : undefined,
                    }" class="h-7 w-7 rounded border border-border" />
                  </Button>
                </ColorInput>

                <span class="text-sm font-semibold">Shadow</span>
              </div>
            </div>

            <div v-if="captionsStore.baseCaptionPreset?.glow.editable"
              class="relative w-full items-center rounded-lg border border-input bg-background p-3">
              <div v-if="captionsStore.baseCaptionPreset?.glow.radius === 0"
                class="absolute inset-0 h-full w-full rounded-lg bg-gray-300 opacity-50"></div>
              <div class="flex flex-row items-center gap-3">
                <ColorInput v-model="glowHighlightColorModel" class="w-full shrink-0" side="right"
                  @commit-value="historyStore.transaction('CAPTIONS:CHANGE_HIGHLIGHT_COLOR')"
                  @close="(color) => onCloseColorPicker(color as string)"
                  :recently-used-colors="recentlyUsedCaptionColors" :recommended-colors="recommendedColors.LIGHT">
                  <Button variant="toggle" :class="cn('h-auto border-none bg-transparent p-0 shadow-none', {
                    'bg-transparent-grid': captionsStore.baseCaptionPreset?.glow.radius === 0,
                  })
                    ">
                    <span :style="{
                      backgroundColor:
                        captionsStore.baseCaptionPreset?.glow.radius > 0 ? glowHighlightColorModel : undefined,
                    }" class="h-7 w-7 rounded border border-border" />
                  </Button>
                </ColorInput>

                <span class="text-sm font-semibold">Glow</span>
              </div>
            </div>

            <div v-if="captionsStore.baseCaptionPreset?.background.editable"
              class="relative w-full items-center rounded-lg border border-input bg-background p-3">
              <div v-if="captionsStore.baseCaptionPreset?.background.radius === 0"
                class="absolute inset-0 h-full w-full rounded-lg bg-gray-300 opacity-50"></div>
              <div class="flex flex-row items-center gap-3">
                <ColorInput v-model="backgroundHighlightColorModel" class="w-full shrink-0" side="right"
                  @commit-value="historyStore.transaction('CAPTIONS:CHANGE_HIGHLIGHT_COLOR')"
                  @close="(color) => onCloseColorPicker(color as string)"
                  :recently-used-colors="recentlyUsedCaptionColors" :recommended-colors="recommendedColors.LIGHT">
                  <Button variant="toggle" :class="cn('h-auto border-none bg-transparent p-0 shadow-none', {
                    'bg-transparent-grid': captionsStore.baseCaptionPreset?.background.radius === 0,
                  })
                    ">
                    <span :style="{
                      backgroundColor:
                        captionsStore.baseCaptionPreset?.background.radius > 0
                          ? backgroundHighlightColorModel
                          : undefined,
                    }" class="h-7 w-7 rounded border border-border" />
                  </Button>
                </ColorInput>

                <span class="text-sm font-semibold">Background</span>
              </div>
            </div>
          </footer>
        </section>
      </Transition>
    </div>
  </div>

  <div class="my-1 h-px bg-surface-panel-border" />

  <div class="mt-4 flex select-none flex-col gap-4">
    <section v-if="captionsStore.baseOptions.emojis"
      class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
      <header class="flex cursor-pointer items-start justify-between gap-2" @click="toggleEmojis">
        <div class="flex flex-col gap-0">
          <h3 class="text-lg font-semibold">Add emojis automatically</h3>
          <p class="text-sm font-light text-muted-foreground">Magically add emojis to captions. ✨</p>
        </div>

        <Switch v-model:checked="captionsStore.baseOptions.emojis" @click.stop />
      </header>
    </section>

    <section class="flex flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3">
      <div class="mt-1 flex max-h-[300px] flex-col justify-between gap-2">
        <div class="flex flex-row gap-2">
          <h3 class="text-lg font-semibold">Special Effects ✨</h3>
        </div>
        <p class="text-sm font-light text-muted-foreground">Animated emojis and more to your captions!</p>
      </div>

      <div class="my-1 h-px bg-surface-panel-border" />

      <div class="relative">
        <section class="flex flex-col gap-2 rounded-xl bg-surface-panel p-3">
          <header class="flex cursor-pointer items-start justify-between gap-2">
            <div class="flex flex-col gap-0">
              <h3 class="text-lg font-semibold">Bleep curse words</h3>
              <p class="text-sm font-light text-muted-foreground">
                Automatically detect curse words and emit a sound effect instead
              </p>
            </div>
          </header>
        </section>

        <div class="mt-4 flex flex-col gap-2 p-2">
          <audio class="h-8 w-full" controls src="" />
          <audio class="h-8 w-full" controls src="" />
          <audio class="h-8 w-full" controls src="" />
        </div>

        <div class="absolute top-0 flex h-full w-full items-center justify-center rounded-xl backdrop-blur-sm">
          <span
            class="user-select-none -mt-0.5 rotate-6 cursor-default rounded bg-green-700 px-2 py-1 text-xl font-semibold uppercase text-background">
            Coming soon!
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.bg-transparent-grid {
  background-color: #ccc;
  /* Light grey background color */
  background-size: 10px 10px;
  /* Adjust size to control the size of each square */
  background-position: 0 0, 5px 5px;
  /* Offset the second layer to create the checkerboard */
  background-image: linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff),
    linear-gradient(45deg, #ffffff 25%, transparent 25%, transparent 75%, #ffffff 75%, #ffffff);
}
</style>
