<script setup lang="ts">
import HsbInput from '@/components/colors/HsbInput.vue'
import { Button } from '@/components/ui/button'
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import { useIsMobile } from '@/Hooks/useIsMobile'
import { cn } from '@/lib/utils'
import { type ClassValue } from 'clsx'
import tinycolor from 'tinycolor2'
import { v4 as uuid } from "uuid"
import type { Gradient } from '../Captions/v3/CaptionPreset'
import GradientColorInput from './GradientColorInput.vue'

interface Props {
  disabled?: boolean
  class?: ClassValue
  recentlyUsedColors?: readonly string[]
  recommendedColors?: readonly string[]
  side?: 'left' | 'right' | 'top' | 'bottom'
  allowGradient?: boolean
}

const props = defineProps<Props>()
const model = defineModel<string | Gradient>({ required: true })
const emit = defineEmits<{
  (e: 'commit-value'): void
  (e: 'update:model-value', value: String | Gradient): void
  (e: 'close', value: string | Gradient): void
  (e: 'open'): void
}>()
const tab = ref<'solid' | 'gradient'>(typeof model.value === 'string' ? 'solid' : 'gradient')

const isMobile = useIsMobile()

const isGradient = computed(() => typeof model.value !== 'string')
const stringModel = computed({
  get() {
    return isGradient.value ? (model.value as Gradient).colorStops[0].color : (model.value as string)
  },
  set(value: string) {
    emit('update:model-value', value)
  },
})

const gradientModel = computed({
  get() {
    if (isGradient.value) {
      for (const stop of (model.value as Gradient).colorStops) {
        if (!stop?.id) {
          stop.id = uuid()
        }
      }

      return model.value as Gradient
    }

    const firstStop = { id: uuid(), color: model.value as string, stop: 0 }
    const secondStop = {
      id: uuid(),
      color: tinycolor(model.value as string)
        .darken(50)
        .toHexString(),
      stop: 1,
    }
    const gradient = {
      variant: 'linear',
      angle: 0,
      colorStops: [firstStop, secondStop],
    } as Gradient

    model.value = gradient
    return gradient
  },
  set(value: Gradient) {
    emit('update:model-value', value)
  },
})

const modelHasChanged = ref(false)
watch(model, () => {
  modelHasChanged.value = true
}, { deep: true })

watch(isGradient, () => {
  if (isGradient.value) {
    tab.value = 'gradient'
  }
})

watch(tab, () => {
  if (tab.value === 'solid') {
    model.value = stringModel.value
  } else {
    model.value = gradientModel.value
  }
})

function onOpenChange(open: boolean) {
  if (open) {
    emit('open')
  } else if (modelHasChanged.value) {
    emit('close', model.value)
  }
  modelHasChanged.value = false
}

const isInteracting = ref(false)
function onIsInteracting(value: boolean) {
  isInteracting.value = value
}
</script>

<template>
  <template v-if="isMobile">
    <Dialog @update:open="onOpenChange">
      <DialogTrigger as-child>
        <template v-if="$slots.default">
          <slot />
        </template>

        <Button
          v-else
          variant="toggle"
          :class="cn('min-w-56 justify-start border border-input bg-background', props.class)"
        >
          <span :style="{ backgroundColor: stringModel }" class="h-6 w-6 rounded border border-border" />
          <span class="text-sm uppercase">{{ stringModel }}</span>
        </Button>
      </DialogTrigger>

      <DialogContent class="mx-auto max-h-[90dvh] max-w-[85%] rounded-lg gap-1" :class="{ 'overflow-hidden': isInteracting }">
        <DialogHeader class="text-left">
          <DialogTitle>Choose your color</DialogTitle>
        </DialogHeader>

        <Tabs v-if="allowGradient" v-model="tab">
          <TabsList class="mt-2 flex w-full flex-row gap-1">
            <TabsTrigger
              key="solid"
              value="solid"
              class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md data-[state=active]:shadow-md dark:hover:bg-black"
            >
              Solid
            </TabsTrigger>
            <TabsTrigger
              key="gradient"
              value="gradient"
              class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md data-[state=active]:shadow-md dark:hover:bg-black"
            >
              Gradient
            </TabsTrigger>
          </TabsList>

          <TabsContent value="solid">
            <HsbInput
              v-model:hex="stringModel"
              @commit-value="emit('commit-value')"
              @is-interacting="onIsInteracting"
              :recently-used-colors="recentlyUsedColors"
              :recommended-colors="recommendedColors"
            />
          </TabsContent>
          <TabsContent value="gradient">
            <GradientColorInput
              v-model="gradientModel"
              class="flex flex-col"
              @commit-value="emit('commit-value')"
              @update:model-value="(value: Gradient) => (model = value)"
              :recently-used-colors="recentlyUsedColors"
              :recommended-colors="recommendedColors"
            />
          </TabsContent>
        </Tabs>

        <template v-else>
          <HsbInput
            v-model:hex="stringModel"
            @commit-value="emit('commit-value')"
            :recently-used-colors="recentlyUsedColors"
            :recommended-colors="recommendedColors"
          />
        </template>

        <DialogFooter class="mt-2">
          <DialogClose as-child>
            <Button variant="primary" class="h-full text-sm">Choose color</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  </template>

  <template v-else>
    <Popover @update:open="onOpenChange">
      <PopoverTrigger as-child>
        <template v-if="$slots.default">
          <slot />
        </template>

        <Button
          v-else
          variant="toggle"
          :class="cn('min-w-56 justify-start border border-input bg-background', props.class)"
        >
          <span :style="{ backgroundColor: stringModel }" class="h-6 w-6 rounded border border-border" />
          <span class="text-sm uppercase">{{ model }}</span>
        </Button>
      </PopoverTrigger>

      <PopoverContent :side="props.side" class="w-64 2xl:w-72">
        <Tabs v-if="allowGradient" v-model="tab">
          <TabsList class="mt-2 flex w-full flex-row gap-1">
            <TabsTrigger
              key="solid"
              value="solid"
              class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md data-[state=active]:shadow-md dark:hover:bg-black"
            >
              Solid
            </TabsTrigger>
            <TabsTrigger
              key="gradient"
              value="gradient"
              class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md data-[state=active]:shadow-md dark:hover:bg-black"
            >
              Gradient
            </TabsTrigger>
          </TabsList>

          <TabsContent value="solid">
            <HsbInput
              v-model:hex="stringModel"
              @commit-value="emit('commit-value')"
              :recently-used-colors="recentlyUsedColors"
              :recommended-colors="recommendedColors"
            />
          </TabsContent>
          <TabsContent value="gradient">
            <GradientColorInput
              v-model="gradientModel"
              class="flex flex-col"
              @commit-value="emit('commit-value')"
              @update:model-value="(value: Gradient) => (model = value)"
              :recently-used-colors="recentlyUsedColors"
              :recommended-colors="recommendedColors"
            />
          </TabsContent>
        </Tabs>

        <template v-else>
          <HsbInput
            v-model:hex="stringModel"
            @commit-value="emit('commit-value')"
            :recently-used-colors="recentlyUsedColors"
            :recommended-colors="recommendedColors"
          />
        </template>
      </PopoverContent>
    </Popover>
  </template>
</template>

<style scoped></style>
