import type { EditorProject } from '@/areas/editor/@type/editor-project';
import { onUserInfoReadyAsync } from '@/store/user/userInfo';
import type { Feature } from '@/data/features';
import featuresArray from '@/data/features';

export async function preflight(request: { project: EditorProject }) {

  const { tier } = await onUserInfoReadyAsync();

  const usages = new Map<Feature, number>();
  for (const feature of featuresArray) {
    usages.set(feature.name, countUsageOf(feature.name, request.project));
  }

  return { usages: Object.fromEntries(usages) as Record<Feature, number>, tier: tier };
}

export default { preflight };

export type PreflightResult = Awaited<ReturnType<typeof preflight>>

function countUsageOf(feature: Feature, project: EditorProject) {
  switch (feature) {
    case 'text': return count(project.elements, e => e.type === 'text');
    case 'stickers': return count(project.elements, e => e.type === 'rive' && !isAnimatedRiveArtboard(e.artboard));
    case 'animated-social-stickers': return count(project.elements, e => e.type === 'rive' && isAnimatedRiveArtboard(e.artboard));
    case 'resolution-1080': return (project.fps >= 60 || project.width >= 1080 || project.height >= 1920) ? 1 : 0;
    case 'upload-custom-stickers': return count(project.elements, e => e.type === 'image' && e.subtype === 'custom');
    case 'mobile': return isMobile() ? 1 : 0;
    case 'captions': return project.captions.entities?.length > 0 && project.captions.baseCaptionPreset ? 1 : 0;
    case 'zoom-segments': return count(project.segments, s => s.type === 'zoom');
    case 'giphy-stickers': return count(project.elements, e => e.type === 'image' && e.subtype === 'gif');
    case 'twitch-emotes': return count(project.elements, e => e.type === 'image' && e.subtype === 'emote');
    case 'sounds': return count(project.effects, e => e.type === 'sound');
    case 'element-animations': return count(project.elements, e => e.effects?.length);
    case 'profanity': return project.profanity.enabled ? 1 : 0;
    default: return 0;
  }
}

function count<T>(array: T[] | undefined, predicate?: (value: T, index: number, array: T[]) => unknown): number {

  if (!array) {
    return 0;
  }

  if (!predicate) {
    return array.length;
  }

  return array.filter(predicate).length;
}

function isMobile() {

  const isMobileRegexp = /(android|bb\d+|meego).+mobile|armv7l|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series[46]0|samsungbrowser.*mobile|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i;
  const isNotMobileRegexp = /CrOS/;

  return Boolean(navigator.userAgent)
    && isMobileRegexp.test(navigator.userAgent)
    && !isNotMobileRegexp.test(navigator.userAgent);
}

const animatedRiveStickersArtboardNames = ["twitch 2", "TWITCH", "KICK", "YOUTUBE", "1 subscribe", "30", "32",
  "31 2", "mix icons", "2 3D icons", "3 youtube", "4 3 icons pink", "6 bounce button ", "8 3 icons green", "9 twitter"];
function isAnimatedRiveArtboard(artboard: string) {
  return animatedRiveStickersArtboardNames.includes(artboard);
}
