<script setup lang="ts">
import { useToast } from '@/Hooks/useToast'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import CaptionEditorTabsAnimateTab from '@/areas/editor/views/captions/tabs/settings/CaptionEditorTabsAnimateTab.vue'
import CaptionEditorTabsEffectsTab from '@/areas/editor/views/captions/tabs/settings/CaptionEditorTabsEffectsTab.vue'
import CaptionEditorTabsStylesTab from '@/areas/editor/views/captions/tabs/settings/CaptionEditorTabsStylesTab.vue'
import ConfirmDialog from '@/components/Dialog/Confirm/ConfirmDialog.vue'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'
import toastEvents from '@/events/toastEvents'
import logging from '@/logging'
import LoginDialog from '@/pages/auth/LoginDialog.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useConfirmDialog, usePrevious } from '@vueuse/core'
import { Loader2Icon, PaletteIcon, PencilIcon, SaveIcon, SparklesIcon, Trash2Icon, ZapIcon } from 'lucide-vue-next'
import { computed, ref } from 'vue'
import z from 'zod'

const userInfoStore = useUserInfoStore()
const captionsStore = useCaptionsStore()
const toast = useToast()

const templateName = computed({
  get() {
    return captionsStore.currentUserCaption?.name ?? captionsStore.baseCaptionPreset.key
  },
  set(value: string) {
    if (captionsStore.currentUserCaption) {
      captionsStore.currentUserCaption.name = value
    }
  },
})

const tab = ref('styles')
const tabs = ['styles', 'effects', 'animations']
const previous = usePrevious(tab, 'styles')
const direction = computed(() => Math.sign(tabs.indexOf(tab.value) - tabs.indexOf(previous.value)))
const isEditingPresetTitle = ref(false)
const formError = ref<string | null>()

const canDeleteCaptionPreset = computed(() => !!captionsStore.currentUserCaption?.id)

const formSchema = z.object({
  templateName: z
    .string()
    .min(1, 'Please enter a name for your style')
    .max(255, "Style name can't be longer than 255 characters"),
})

function onSubmit(event: Event) {
  const formData = new FormData(event.target as HTMLFormElement)
  const rawData = Object.fromEntries(formData.entries()) as { templateName: string }

  const { success, error } = formSchema.safeParse(rawData)

  formError.value = error?.issues?.[0]?.message ?? null

  if (success) {
    isEditingPresetTitle.value = false
    captionsStore.savePreset(captionsStore.currentUserCaption!).then(() => {
      logging.trackEvent("Custom Caption Name Changed")
    })
  }
}

const deleteConfirmDialog = useConfirmDialog()
deleteConfirmDialog.onConfirm(() => {
  const id = captionsStore.currentUserCaption?.id
  if (id) {
    captionsStore.deletePreset(id).then(() => {
      captionsStore.sidebarPage = 'captions'
      toast.showToast({
        title: 'Preset deleted',
        subtitle: 'Your preset has been saved deleted',
        type: toastEvents.TOAST,
      })
      logging.trackEvent('Custom Caption Deleted')
    })
  }
})

async function onClickBack() {
  if (captionsStore.hasUnsavedChanges) {
    captionsStore.savePreset(captionsStore.currentUserCaption!)
  }
  captionsStore.sidebarPage = 'captions'
}

function onChanged() {
  captionsStore.hasUnsavedChanges = true
  if (!captionsStore.currentUserCaption?.id && captionsStore.currentUserCaption?.name === captionsStore.baseCaptionPreset.key) {
    templateName.value = 'Custom'
  }
}

function savePreset() {
  captionsStore
    .savePreset(captionsStore.currentUserCaption!)
    .then((value) => {
      if (value) {
        toast.showToast({
          title: 'Styles saved',
          subtitle: 'Your styles has been saved',
          type: toastEvents.TOAST_SUCCESS,
        })
      }
    })
    .catch(console.error)
}

watch(captionsStore.baseOptions, () => {
  captionsStore.hasUnsavedChanges = true
})
</script>

<template>
  <section class="flex flex-col gap-4 pb-4 2xl:pb-8">
    <div class="mt-2 w-max md:mt-0">
      <div
        class="flex cursor-pointer items-center gap-2 text-brand-state-link underline-offset-2 transition-transform hover:-translate-x-0.5 hover:underline md:mb-4"
        @click="onClickBack">
        <IconSaxArrowLeft class="h-4 w-4" />
        <span class="mt-0.5 select-none lg:text-sm">Back to Captions</span>
      </div>
    </div>

    <header class="flex flex-row gap-2 items-center justify-between">
      <div>
        <h2 class="font-title text-xl font-semibold leading-snug">Customize</h2>
        <p class="font-light">Create your own custom caption styles</p>
      </div>
      <Button variant="primary" @click="savePreset()">
        <Loader2Icon class="w-4 animate-spin" v-if="captionsStore.isSavingCustomPreset" />
        <SaveIcon v-else class="w-4" />
        Save
      </Button>
    </header>

    <div class="h-px bg-surface-panel-border" />

    <div class="flex flex-row items-center justify-between gap-2">
      <template v-if="!isEditingPresetTitle">
        <span ref="label" @dblclick="isEditingPresetTitle = true" class="w-full overflow-hidden text-ellipsis">
          {{ templateName }}
        </span>

        <Button @click="isEditingPresetTitle = true" variant="ghost" class="aspect-square h-auto w-auto p-2">
          <PencilIcon class="h-4 w-4 cursor-pointer" />
        </Button>
      </template>

      <template v-else>
        <form @submit.prevent="onSubmit" class="flex w-full flex-col gap-1">
          <Input as="span" class="h-auto shadow-none hover:shadow-none"
            :class="{ '!ring-rose-400 hover:ring-rose-400': formError }">
          <input v-focus type="text" name="templateName" :min="1" :max="255" v-model="templateName"
            class="w-full min-w-0 flex-auto basis-0 select-all bg-transparent outline-none" />

          <Button type="submit" variant="ghost" size="lg" class="-my-2 -mr-3 h-auto rounded-l-none rounded-r-md">
            Save
          </Button>
          </Input>
          <span v-if="formError" class="text-sm text-rose-500">
            {{ formError }}
          </span>
        </form>
      </template>
    </div>

    <Tabs v-model="tab">
      <div class="">
        <TabsList class="flex w-full flex-row gap-1">
          <TabsTrigger key="styles" value="styles"
            class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md dark:hover:bg-black data-[state=active]:shadow-md">
            <PaletteIcon class="w-4" />
            Style
          </TabsTrigger>
          <TabsTrigger key="effects" value="effects"
            class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md dark:hover:bg-black data-[state=active]:shadow-md">
            <SparklesIcon class="w-4" />
            Effects
          </TabsTrigger>
          <TabsTrigger key="animations" value="animations"
            class="w-full rounded-md hover:bg-white hover:text-foreground hover:shadow-md dark:hover:bg-black data-[state=active]:shadow-md">
            <ZapIcon class="w-4" />
            Animations
          </TabsTrigger>
        </TabsList>
      </div>

      <Transition appear :enter-from-class="direction === -1 ? 'opacity-0 -translate-x-12' : 'opacity-0 translate-x-12'"
        :leave-to-class="direction === -1 ? 'opacity-0 translate-x-12' : 'opacity-0 -translate-x-12'"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]">
        <section :key="tab" class="absolute mt-2 w-full pb-4 2xl:pb-8">
          <TabsContent value="styles">
            <CaptionEditorTabsStylesTab @changed="onChanged" />
          </TabsContent>
          <TabsContent value="effects">
            <CaptionEditorTabsEffectsTab @changed="onChanged" />
          </TabsContent>
          <TabsContent value="animations">
            <CaptionEditorTabsAnimateTab />
          </TabsContent>

          <div v-if="canDeleteCaptionPreset" class="mt-4">
            <div v-if="!userInfoStore.isLoggedIn" class="flex w-full justify-center">
              <LoginDialog>
                <Button variant="ghostDestructive" class="w-full">
                  <SaveIcon class="w-4" />
                  Delete style
                </Button>
              </LoginDialog>
            </div>
            <Button v-else class="w-full" variant="ghostDestructive" :disabled="captionsStore.isDeletingCustomPreset"
              @click="deleteConfirmDialog.reveal()">
              <Loader2Icon class="w-4 animate-spin" v-if="captionsStore.isDeletingCustomPreset" />
              <Trash2Icon v-else class="w-4" />
              Delete style
            </Button>
          </div>
        </section>
      </Transition>
    </Tabs>
  </section>

  <teleport to="body">
    <ConfirmDialog v-if="deleteConfirmDialog.isRevealed.value" @confirm="deleteConfirmDialog.confirm"
      @cancel="deleteConfirmDialog.cancel" title="Delete caption style"
      message="Are you sure you want to delete this caption style?" />
  </teleport>
</template>

<style scoped></style>
