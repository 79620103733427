<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router'
import LoginForm from '@/pages/auth/LoginForm.vue'
import { awaitFeatureFlagsPromise, useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'

const router = useRouter();
const route = useRoute();

const navigateToSite = () => {
  router.push(route.query.redirect as string || '/');
};

const isLoading = ref(true);

const showClipDownloaderVariant = useFeatureFlagVariantEnabled('clip-downloader-login-page', 'clip-downloader');
const isRedirectToClipDownloader = computed(() => router.currentRoute.value.query.redirect === '/download');

onMounted(async () => {
  await awaitFeatureFlagsPromise();
  isLoading.value = false;
});
</script>

<template>
  <template v-if="!isLoading">
    <template v-if="showClipDownloaderVariant && isRedirectToClipDownloader">
      <div class="flex relative min-h-screen w-full items-center justify-center flex-col">
        <div class="absolute right-0 top-0 w-full h-full bg-gradient-to-tl from-transparent to-[#1BC9FF]" />
        <div class="absolute right-0 top-0 w-full h-full bg-gradient-to-tr from-transparent to-[#f4d4e1]" />
        <div class="absolute right-0 top-0 w-full h-full bg-gradient-to-b from-transparent to-white" />
        <div class="z-10 w-auto h-auto rounded-2xl shadow-2xl p-8 bg-white">
          <LoginForm @loggedIn="navigateToSite" />
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex min-h-screen w-full flex-col">
        <div class="container flex w-full max-w-5xl flex-grow flex-col items-center justify-items-center space-y-2 self-center justify-self-center px-4 pb-20 pt-6 md:flex-row md:space-y-0">
          <div class="fixed left-1/2 top-1/2 z-50 max-h-[90vh] overflow-y-auto -translate-x-1/2 -translate-y-1/2 layer-1 duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] app-bg grid md:grid-cols-2 w-full p-0 min-w-screen min-h-screen max-w-[1920px] min-[1920px]:shadow-2xl min-[1920px]:rounded-lg">
            <LoginForm @loggedIn="navigateToSite" />
          </div>
        </div>
      </div>
    </template>
  </template>
</template>
