import logging from '@/logging'

export default {
  trackEvent(request: TrackEventRequestBody) {
    logging.trackEvent(request.eventName, request.properties);
  },
}

interface TrackEventRequestBody {
  eventName: string;
  properties?: Record<string, unknown>;
}