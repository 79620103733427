// @ts-ignore
import MP4Box from 'mp4box';

export const getVideoMetaData = async (url: string): Promise<{ duration: number; videoWidth: number; videoHeight: number } | undefined> => {

    return new Promise((resolve) => {

        let reader: ReadableStreamDefaultReader<Uint8Array> | undefined;
        
        // Set timeout of 60 seconds
        const timeoutId = setTimeout(() => {
            console.warn('getVideoMetaData timeout after 60 seconds');
            reader?.cancel();
            resolve(undefined);
        }, 60000);

        try {
            fetch(url).then(response => {
                reader = response?.body?.getReader();
                if (!reader) {
                    clearTimeout(timeoutId);
                    return resolve(undefined);
                }

            const mp4BoxFile = MP4Box.createFile();
            let continueReading = true;
            let offset = 0;

            mp4BoxFile.onReady = (info) => {
                clearTimeout(timeoutId);
                continueReading = false;
                reader?.cancel();

                console.log('Using fallback getVideoMetaData', JSON.stringify(info));

                resolve({
                    duration: info.duration,
                    videoWidth: info.tracks[0].track_width,
                    videoHeight: info.tracks[0].track_height,
                });
            };

            // Error handler
            mp4BoxFile.onError = (error) => {
                clearTimeout(timeoutId);
                console.error('MP4Box error:', error);
                reader?.cancel();
                resolve(undefined);
            };

            const processChunk = () => {
                if (!continueReading || !reader) return;
                
                reader.read().then(({ value, done }) => {
                    if (done) {
                        continueReading = false;
                        return;
                    }

                    try {
                        //@ts-ignore
                        const mp4Buffer = value.buffer as MP4ArrayBuffer;
                        mp4Buffer.fileStart = offset;
                        offset += value.byteLength;

                        mp4BoxFile.appendBuffer(mp4Buffer);
                        
                        // Continue reading the next chunk
                        processChunk();
                    } catch (error) {
                        clearTimeout(timeoutId);
                        console.error('Error processing video chunk:', error);
                        resolve(undefined);
                    }
                }).catch(error => {
                    clearTimeout(timeoutId);
                    console.error('Error reading video stream:', error);
                    resolve(undefined);
                });
            };
            
            // Start processing chunks
            processChunk();
            }).catch(error => {
                clearTimeout(timeoutId);
                console.error('Error fetching video:', error);
                resolve(undefined);
            });
        } catch (error) {
            clearTimeout(timeoutId);
            console.error('Error in getVideoMetaData:', error);
            resolve(undefined);
        }
    });
};