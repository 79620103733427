// @ts-ignore
import compatabilityChecker from '@/services/compatabilityChecker'

export function download(url: string, filename: string, target = '_self') {
  const a = document.createElement('a');
  a.download = filename;
  a.href = url;
  a.target = target;
  a.click();
  a.remove();
}

export async function shareOrDownload(url: string, filename?: string) {

  const response = await fetch(url);
  const blob = await response.blob();

  // If no filename is provided, try to extract it from the Content-Disposition header
  if (!filename) {
    const disposition = response.headers.get('Content-Disposition');
    const match = disposition?.match(/filename=(.*)/);
    filename = decodeURIComponent(match?.[1] || `StreamLadder - ${Date.now().toString()}`);
  }

  // Make sure the filename always ends with .mp4 otherwise IOS won't see it as a video
  filename = filename.endsWith('.mp4') ? filename : `${filename}.mp4`;
  
  const file = new File([blob], filename, { type: 'video/mp4' });
  
  try {
    const canShare = navigator.canShare?.({ files: [ file ] });

    if (compatabilityChecker.isMobile() && canShare) {
      await navigator.share({
        files: [ file ],
      });
    } else {
      const url = URL.createObjectURL(blob); 
      download(url, filename);
      URL.revokeObjectURL(url);
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}