<script setup lang="ts">
import WorkspaceSpinner from '@/areas/editor/workspaces/WorkspaceSpinner.vue';
import { onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { startup, type StartupSource } from '@/areas/editor/startup';
import { v4 as uuid } from 'uuid';
import { retryAsync } from '@/helpers/retry';
import { handleStartupError } from '@/areas/editor/startup/handleStartupError';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
import type { LayoutWithCrops } from '@/areas/editor/@type/Project';
import { resizeAspectLocked } from '@/modules/SLMovable/helpers/resize/resizeAspectLocked';
import localStorageApi from '@/areas/editor/pages/api/localStorageApi';
import { version } from '@/data/versions';
import { storeProject } from '@/queries/projects/projectsApi';
import { full } from '@/areas/editor/@data/prefabs/full';
import { metadataService } from '@/services/metadataService';
import { usePresetsByVideoSize } from '@/areas/editor/@data/layouts';
import { useLocalStorage } from '@vueuse/core';
import { merge } from 'lodash-es';
import type { Segment } from '@/areas/editor/@type/editor-project/Segment';
import type { Layout } from '@/areas/editor/@type/editor-project/Layout';
import type { Crop } from '@/areas/editor/@type/editor-project/Crop';
import type { Crop as LayoutCrop } from '@/areas/editor/@type/Project';
import type { CropSource } from '@/areas/editor/@type/editor-project/CropSource';
import { stickerToElement } from '@/areas/editor/@data/convert/toVideoEditorTemplate';
import type { Element } from '@/areas/editor/@type/editor-project/Element';
import type { SoundEffect } from '@/areas/editor/@type/editor-project/Effect';
import logging from '@/logging';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import { getVideoMetaData } from '@/areas/editor/startup/videoMetaData';

const route = useRoute();
const router = useRouter();

const clipSources = ['twitch-clip', 'twitch-vod', 'youtube-clip', 'kick-clip', 'local-file', 'kick-cx-clip'];
function isValidStartupSource(clipSource: unknown): clipSource is StartupSource {
  return typeof clipSource === 'string' && clipSources.includes(clipSource);
}

function isValidClipId(clipId: unknown): clipId is string {
  return typeof clipId === 'string';
}

const abortController = new AbortController();
const historyStore = useHistoryStore();

onMounted(async () => {
  historyStore.$reset();

  // The delay here stops the historyStore reset from interfering with the loading spinner.
  await new Promise((resolve) => setTimeout(resolve, 0));

  const clipSource = route.params.clipSource;
  const clipId = route.params.clipId;

  if (!isValidClipId(clipId) || !isValidStartupSource(clipSource)) {
    return await router.replace({ name: 'editor' });
  }

  const { error, clip } = await startup(clipSource, clipId, { signal: abortController.signal });

  if (error || !clip) {

    const editorClipInfoStore = useEditorClipInfoStore();

    // If the loading state is still loading, set it to error. This is to prevent infinite loading.
    if (editorClipInfoStore.loadingState?.state === 'loading') {
      editorClipInfoStore.loadingState = {
        state: 'error',
        title: 'Something went wrong 🧐',
        description: 'The startup process failed. Please try again or contact support using the chat widget.',
      };

      console.error({
        state: 'error',
        error: error,
        title: 'Something went wrong 🧐',
        description: 'The startup process failed. Please try again or contact support using the chat widget.',
      });

      Sentry.captureException(new Error('Editor Startup Failed: No clip or error occurred'));
    }

    return;
  }

  if (!clip.mp4Url) {

    const editorClipInfoStore = useEditorClipInfoStore();

    editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Could not load video',
      description: 'The startup process failed. Please try again or contact support using the chat widget.',
    };

    console.error({
      state: 'error',
      title: 'Could not load video',
      description: 'The startup process failed. Please try again or contact support using the chat widget.',
    });

    Sentry.captureException(new Error('Editor Startup Failed: Video has no mp4Url'));

    return;
  }

  const projectId = uuid();
  const captions = initialCaptionsState();

  const mp4Url = new URL(clip.mp4Url);
  mp4Url.searchParams.set('t', performance.now().toString());

  let { duration, videoWidth, videoHeight } = await metadataService.fetchVideoUrlMetadata(mp4Url.href);

  if (!duration || !videoWidth || !videoHeight) {

    const fallbackInfo = await getVideoMetaData(mp4Url.href);

    if (fallbackInfo) {

      Sentry.captureException(new Error('Editor Startup Fallback: Video has no dimensions or duration'));

      duration = fallbackInfo.duration / 1e3;
      videoWidth = fallbackInfo.videoWidth;
      videoHeight = fallbackInfo.videoHeight;
    } else {
      const editorClipInfoStore = useEditorClipInfoStore();

      editorClipInfoStore.loadingState = {
        state: 'error',
        title: 'Could not load video',
        description: 'The startup process failed. Please try again by re-uploading your video or selecting another video or contact support using the chat widget.',
      };

      console.error({
        state: 'error',
        title: 'Could not load video',
        description: 'The startup process failed. Please try again by re-uploading your video or selecting another video or contact support using the chat widget.',
        duration: duration,
        videoWidth: videoWidth,
        videoHeight: videoHeight,
      });

      Sentry.captureException(new Error('Editor Startup Failed: Video has no dimensions or duration'));

      return;
    }
  }
  
  const sources = [{ id: uuid(), type: 'video', url: mp4Url.href }];
  const segments: Segment[] = [];
  const layouts: Layout[] = [];
  const crops: Crop[] = [];
  const cropSources: CropSource[] = [];
  const elements: Element[] = [];
  const effects: SoundEffect[] = [];

  const isPreCropped = Math.abs((videoWidth / videoHeight) - (9 / 16)) < 0.1;
  if (isPreCropped) {

    const preset = full(videoWidth, videoHeight);
    const layoutId = uuid();
    layouts.push({ id: layoutId, presetId: preset.presetId, name: preset.name });
    segments.push({ id: uuid(), layoutId: layoutId, startMs: 0, endMs: duration * 1000, volume: 1.0, type: 'layout', loop: false, sourceId: sources[0].id });

    for (const crop of preset.crops) {
      const cropId = uuid();
      crops.push({ id: cropId, layoutId: layoutId, z: crop.z, input: crop.input, feedData: crop.feedData });
      cropSources.push({ id: uuid(), cropId: cropId, startMs: null, endMs: null, x: crop.x, y: crop.y, width: crop.width, height: crop.height });
    }
  } else {

    const { layouts: presets, prepareLayouts } = usePresetsByVideoSize(videoWidth, videoHeight, duration * 1000);
    await prepareLayouts();

    const defaultPreset = useLocalStorage('defaultPreset', 'split')
    const preset = presets.value.find((l) => l?.id === defaultPreset.value) ?? presets.value.find((l) => l?.id === 'split')!;

    const layoutId = uuid();
    layouts.push({ id: layoutId, presetId: preset.presetId, name: preset.name });
    segments.push({ id: uuid(), layoutId: layoutId, startMs: 0, endMs: duration * 1000, volume: 1.0, type: 'layout', loop: false, sourceId: sources[0].id });

    for (let i = 0; i < (preset.crops ?? []).length; i++) {
      const cropId = uuid();
      const crop = merge(preset.crops[i], storedValueOf(preset.id, preset.crops, i, videoWidth, videoHeight));
      crops.push({ id: cropId, layoutId: layoutId, z: crop.z, input: crop.input, feedData: crop.feedData });
      cropSources.push({ id: uuid(), cropId: cropId, startMs: null, endMs: null, x: crop.x, y: crop.y, width: crop.width, height: crop.height });
    }
    
    for (let i = 0; i < (preset.stickers ?? []).length; i++) {
      const element = stickerToElement(preset.stickers[i]);
      if (element) {
        elements.push(element);
      }
    }

    for (let i = 0; i < (preset.sounds ?? []).length; i++) {
      const soundEffect = preset.sounds[i];
      if (soundEffect) {
        effects.push(soundEffect);
      }
    }
  }
  
  const project = JSON.parse(JSON.stringify({
    id: projectId,
    durationMs: duration * 1000,
    language: clip.languageCode,
    title: clip.title,
    version: version,
    captions: captions,
    sources: sources,
    segments: segments,
    layouts: layouts,
    crops: crops,
    cropSources: cropSources,
    elements: elements,
    effects: effects,
  }));


  logging.trackEvent('Clip Imported', {
    source: clipSource,
  });

  try {
    await retryAsync(() => storeProject(project));
    await router.replace({ name: 'editor/[projectId]', params: { projectId } });
  } catch (e) {
    return handleStartupError(e);
  }
});

function isValidNumber(value: unknown): value is number {
  return typeof value === 'number' && !isNaN(value) && value >= 0;
}

function isValidCrop(crop: LayoutCrop) {
  return isValidNumber(crop.x) && isValidNumber(crop.y) && isValidNumber(crop.width) && isValidNumber(crop.height);
}

function storedValueOf(
  presetId: string, 
  crops: LayoutWithCrops['crops'], cropIndex: number, 
  videoWidth: number, videoHeight: number
) {

  const crop = crops[cropIndex];
  const preference = localStorageApi.loadCropAreaPreferences(presetId, cropIndex);
  
  if (!preference || !isValidCrop(preference)) {
    return crop
  }

  if (Math.abs((preference.videoWidth / preference.videoHeight) - (videoWidth / videoHeight)) > 0.01) {
    return crop
  }

  if (crop.input.shape === 'freeform' || crop.input.shape !== preference.input.shape) {
    return preference
  }

  const storedCrop = {
    width: preference.width * videoWidth,
    height: preference.height * videoHeight,
    x: preference.x * videoWidth,
    y: preference.y * videoHeight
  }

  const cropAspectLock = {
    width: crop.width * videoWidth,
    height: crop.height * videoHeight,
  }

  if (storedCrop.width / storedCrop.height === cropAspectLock.width / cropAspectLock.height) {
    return preference
  }

  const resizedCrop = resizeAspectLocked(
    storedCrop,
    { x: (crop.x + crop.width) * videoWidth, y: (crop.y + crop.height) * videoHeight },
    ['s', 'e'],
    { top: 0, right: videoWidth, bottom: videoHeight, left: 0 },
    cropAspectLock,
    null)

  return {
    width: resizedCrop.width / videoWidth,
    height: resizedCrop.height / videoHeight,
    x: resizedCrop.x / videoWidth,
    y: resizedCrop.y / videoHeight
  }
}

const captionsStore = useCaptionsStore();

function initialCaptionsState() {
  return {
    entities: captionsStore.entities,
    hasGeneratedCaptions: captionsStore.hasGeneratedCaptions,
    hasGeneratedProfanity: captionsStore.hasGeneratedProfanity,
    baseOptions: captionsStore.baseOptions,
    baseCaptionPreset: captionsStore.baseCaptionPreset!,
  };
}

onUnmounted(() => abortController.abort());
</script>

<template>
  <main class="w-[100dvw] h-[100dvh] flex flex-col overflow-hidden layer-0 text-brand-state-text-primary dark:bg-[#0a0a0a]">
    <div class="grid flex-1 size-full relative">
      <WorkspaceSpinner />
    </div>
  </main>
</template>

<style scoped lang="scss">

</style>
