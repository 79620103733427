<script setup lang="ts">
import { useAsyncFeatureFlagEnabled } from '@/Hooks/useFeatureFlagEnabled';
import WhiteLabelVideoEditorStartupPage from '@/areas/editor/pages/video-editor-ab-test/startup-page/WhiteLabelVideoEditorStartupPage.vue';
import IntegratedEditorStartupPage from '@/areas/editor/pages/video-editor-ab-test/startup-page/IntegratedEditorStartupPage.vue';
import WorkspaceSpinner from '@/areas/editor/workspaces/WorkspaceSpinner.vue';
import { useTheme } from '@/Hooks/useTheme';
import { useHead } from '@unhead/vue';

const { isLoading, flag: useWhiteLabelEditor } = useAsyncFeatureFlagEnabled('white-label-editor');

const { theme } = useTheme()
useHead({
  bodyAttrs: {
    class: [theme],
  },
  htmlAttrs: {
    class: 'prevent-overscroll'
  }
})
</script>

<template>
  <template v-if="!isLoading">
    <WhiteLabelVideoEditorStartupPage v-if="useWhiteLabelEditor" />
    <IntegratedEditorStartupPage v-else />
  </template>
  <main v-else class="w-[100dvw] h-[100dvh] flex flex-col overflow-hidden layer-0 text-brand-state-text-primary dark:bg-[#0a0a0a]">
    <div class="grid flex-1 size-full relative">
      <WorkspaceSpinner />
    </div>
  </main>
</template>

<style scoped></style>
