<script setup lang="ts">
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { Button } from '@/components/ui/button'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

const captionsStore = useCaptionsStore()

const fontSizeModel = computed({
  get() {
    return captionsStore.baseOptions.size
  },
  set(size: 'small' | 'medium' | 'large') {
    emit('changed')
    captionsStore.baseOptions.size = size
  },
})

const emit = defineEmits<{ (e: 'changed'): void }>()
</script>

<template>
  <RadioGroup v-model="fontSizeModel" default-value="medium" aria-label="View density">
    <div class="grid grid-cols-3 gap-2.5">
      <Button
        as-child
        :variant="captionsStore.baseOptions.size === 'small' ? 'primary' : 'outline'"
        class="radio-group-item text-xs font-normal"
      >
        <label class="w-full">
          <RadioGroupItem id="small" value="small" class="hidden" />
          <span>Small</span>
        </label>
      </Button>

      <Button
        as-child
        :variant="captionsStore.baseOptions.size === 'medium' ? 'primary' : 'outline'"
        class="radio-group-item text-md font-normal"
      >
        <label class="w-full">
          <RadioGroupItem id="medium" value="medium" class="hidden" />
          <span>Medium</span>
        </label>
      </Button>

      <Button
        as-child
        :variant="captionsStore.baseOptions.size === 'large' ? 'primary' : 'outline'"
        class="radio-group-item text-xl font-normal"
      >
        <label class="w-full">
          <RadioGroupItem id="large" value="large" class="hidden" />
          <span>Large</span>
        </label>
      </Button>
    </div>
  </RadioGroup>
</template>

<style scoped>
.radio-group-item {
  @apply flex h-10 w-full cursor-pointer items-center justify-center overflow-hidden rounded-lg font-light transition-colors;
}
</style>
