<script setup lang="ts">
import { generateTextToSpeech } from '@/apis/aiApi';
import { getApiTwitchEmotes } from '@/apis/streamladder-api/twitch-emotes/twitch-emotes';
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import type { Project } from '@/areas/editor/@type/Project';
import customCaptionPresetsApi from '@/areas/editor/pages/api/customCaptionPresetsApi';
import customElementsApi from '@/areas/editor/pages/api/customElementsApi';
import customSoundEffectsApi from '@/areas/editor/pages/api/customSoundEffectsApi';
import customTemplatesApi from '@/areas/editor/pages/api/customTemplatesApi';
import exportApi from '@/areas/editor/pages/api/exportApi';
import historyStateApi, { type HistoryStateChange } from '@/areas/editor/pages/api/historyStateApi';
import { IframeMessageBus } from '@/areas/editor/pages/api/IframeMessageBus';
import loggingApi from '@/areas/editor/pages/api/loggingApi';
import transcriptionApi from '@/areas/editor/pages/api/transcriptionApi';
import Max5PremiumRendersDialog from '@/areas/editor/pages/components/Max5PremiumRendersDialog.vue';
import WatermarkOrUpgradeDialog from '@/areas/editor/pages/components/WatermarkOrUpgradeDialog.vue';
import { replaceBrokenTwitchSourceUrlsIfNeeded, replaceBrokenTwitchUrlsIfNeeded } from '@/areas/editor/pages/config/replaceBrokenTwitchUrlsIfNeeded';
import { useVideoEditorTheme } from '@/areas/editor/pages/config/theme';
import WorkspaceSpinner from '@/areas/editor/workspaces/WorkspaceSpinner.vue';
import { requestUserSignInAsync } from '@/authentication/supabase';
import UpgradeBanner from '@/components-v2/navigation/UpgradeBanner.vue';
import { findFeature, type Feature } from '@/data/features';
import { upgradeProjectToEditorProject } from '@/data/versions';
import { tiers } from '@/enums/tiers';
import unwrap from '@/helpers/unwrap';
import { upgradeDialog } from '@/helpers/upgradeDialog';
import { useChatButtonStyle, useChatWindowStyle } from '@/Hooks/useChat';
import { cn } from '@/lib/utils';
import logging from '@/logging';
import { fetchProjectJson, storeProject } from '@/queries/projects/projectsApi';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import { onUserInfoReadyAsync, useUserInfoStore } from '@/store/user/userInfo';
import { useQuery } from '@tanstack/vue-query';
import { merge } from 'lodash-es';
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import MobileNavigationBar from '../../components/MobileNavigationBar.vue';

const route = useRoute();
const theme = useVideoEditorTheme();

const projectId = route.params.projectId as string;

useHead({
  bodyAttrs: { class: 'w-[100dvw] h-[100dvh] overflow-hidden' },
})

const { suspense, data: instructions } = useQuery({
  queryKey: ['render-instructions', projectId],
  queryFn: () => fetchProjectJson(projectId),
});

async function openUpgradeDialog(payload: { feature?: Parameters<typeof upgradeDialog['open']>[0] } = {}) {
  if (await requestUserSignInAsync()) {
    if (typeof payload.feature === 'string') {
      upgradeDialog.open(payload.feature);
    } else {
      upgradeDialog.open({
        tier: 100,
        reason: 'Editor Upgrade Banner Clicked.',
        subtitle: undefined,
        title: undefined,
        intentional: true,
        ...payload.feature
      });
    }
  }
}

const hasStarted = ref(false);

const iframe = ref<HTMLIFrameElement | null>(null);
const iframeMessageBus = new IframeMessageBus(iframe, 'editor', () => startProject());
const project = ref<EditorProject | null>(null);

const clipInfoStore = useEditorClipInfoStore();

async function startProject() {

  clipInfoStore.loadingState = {
    state: 'loading',
    description: 'Connecting to Twitch...'
  }

  const [response, user] = await Promise.all([suspense(), onUserInfoReadyAsync()]);
  hasStarted.value = true;

  if (response.error || !response.data) {
    clipInfoStore.loadingState = {
      state: 'error',
      description: 'Project not found'
    };
    return;
  }

  let editorProject: EditorProject | Project = JSON.parse(JSON.stringify(response.data));
  editorProject = await upgradeProjectToEditorProject(editorProject);
  if (editorProject.sources?.some((s) => s.url?.includes('twitch-clips-v2.b-cdn.net'))) {

    clipInfoStore.loadingState = {
      state: 'loading',
      description: 'Connecting to Twitch...'
    }
    editorProject = await replaceBrokenTwitchUrlsIfNeeded(editorProject);
  }
  
  if (editorProject.segments.some((s) => s.sourceUrl?.includes('twitch-clips-v2.b-cdn.net'))) {
    clipInfoStore.loadingState = {
      state: 'loading',
      description: 'Connecting to Twitch...'
    }
    editorProject = await replaceBrokenTwitchSourceUrlsIfNeeded(editorProject);
  }

  editorProject.watermarkUrl = undefined;

  const config = {
    appId: 'b70d6ef4-7c85-44ef-adf5-41107d032417',
    baseUrl: window.location.origin,
    editorProject: editorProject,
    theme: theme.value,
    user: { tier: user.tier, socials: { twitch: user.userName } },
  };

  project.value = editorProject;
  clipInfoStore.loadingState = null;
  iframeMessageBus.emit('MOUNT_VIDEO_EDITOR', { config: config });
  watch(userInfoStore, ({ tier, userName }) => {
    iframeMessageBus.emit('UPDATE_USER', { user: { tier: tier, socials: { twitch: userName } } })
  }, { immediate: true, deep: true });
}

function toClipCreatedProperties(editorProject: EditorProject, isPremiumExport = false) {

  const isLocalFile = editorProject.segments.find((s) => s.sourceUrl.includes('streamladder.com'));

  const amountOfGiphyElements = editorProject.elements.filter(e => e.type === 'image' && e.subtype === 'gif')?.length || 0;
  const amountOfTwitchEmotes = editorProject.elements.filter(e => e.type === 'image' && e.subtype === 'emote')?.length || 0;
  const amountOfSoundsEffects = editorProject.effects.filter(e => e.type === 'sound')?.length || 0;
  const amountOfRiveElements = editorProject.elements.filter(e => e.type === 'rive')?.length || 0;

  const selectedCaptionPreset = editorProject.captions.baseCaptionPreset?.key ?? 'unknown';
  const selectedCaptionFontFamily = editorProject.captions.baseCaptionPreset?.font?.fontFamily;

  return {
    source: editorProject.segments[0].sourceUrl,
    isLocalFile: isLocalFile !== undefined,
    hasStickers: editorProject.elements.length > 0,
    hasTwitchEmotes: amountOfTwitchEmotes > 0,
    hasCaptionsV2: editorProject.captions.entities?.length && editorProject.captions.baseCaptionPreset,
    hasCustomCaptions: editorProject.captions.customCaptionPresetId !== null,
    hasEffects: editorProject.effects.length > 0,
    isPremiumExport: isPremiumExport,
    hasGiphyStickers: amountOfGiphyElements > 0,
    hasPremiumOutputQuality: editorProject.width === 1920 && editorProject.height === 1080,
    hasSoundEffects: amountOfSoundsEffects > 0,
    amountOfSegments: editorProject.segments.length,
    hasRiveStickers: amountOfRiveElements > 0,
    hasCensorWordsEffect: editorProject.profanity?.enabled,
    bleepCurseWordSoundEffect: editorProject.profanity?.selectedSoundEffect,
    selectedCaptionPreset: selectedCaptionPreset,
    selectedCaptionFontFamily: selectedCaptionFontFamily,
    hasEmojis: editorProject.captions.baseCaptionPreset?.options.emojis && editorProject.captions?.entities?.some(entity => entity.emojis?.length),
  };
}


async function renderWithWatermark() {
  const projectWithWatermark = merge(project.value, { watermarkUrl: 'https://app.streamladder.com/images/watermark.png' });
  project.value = projectWithWatermark;
  await storeProject(merge(projectWithWatermark, { id: projectId }));
  await redirectToExportPage();
}

async function redirectToExportPage() {
  await router.push({ name: 'editor/[projectId]/export', params: { projectId: projectId } });
}

watch(theme, (theme) => iframeMessageBus.emit('UPDATE_THEME', theme));

const userInfoStore = useUserInfoStore();

iframeMessageBus.on('VIDEO_EDITOR_UPGRADE_BUTTON_CLICKED', openUpgradeDialog);

const router = useRouter();
const amountOfPremiumRenders = ref(0);
const isMax5PremiumExportsDialogOpen = ref(false);
const isWatermarkOrExportDialogOpen = ref(false);

const usedPremiumFeatures = ref<Feature[]>([]);

iframeMessageBus.on('VIDEO_EDITOR_EXPORT_BUTTON_CLICKED', async (request: { project: EditorProject }) => {

  const userIsAuthenticated = await requestUserSignInAsync('Please login to render your video')
  if (!userIsAuthenticated) {
    logging.trackEvent('Clip Creation Rejected', { message: 'User is not authenticated' });
    return {
      type: 'error',
      message: 'User is not authenticated',
    } as const
  }

  const storeProjectPromise = storeProject(request.project);
  const preflight = await exportApi.preflight(request);

  const isUsedFeature = (feature: Feature) => preflight.usages[feature] > 0;
  const userCannotUseFeature = (feature: Feature) => (findFeature(feature)?.tier ?? 0) > preflight.tier;

  const features = unwrap.keys(preflight.usages)
    .filter(isUsedFeature)
    .filter(userCannotUseFeature)

  usedPremiumFeatures.value = features;
  await new Promise(resolve => setTimeout(resolve, 0));

  if (isUsedFeature('giphy-stickers')) {
    const giphyStickers = request.project.elements
      .map(e => e.type === 'image' && e.subtype === 'gif' ? e.url : null)
      .filter(e => e !== null);

    logging.trackEvent('Giphy Stickers: user clicked export with Giphy stickers', {
      selectedStickerUrls: giphyStickers
    })
  }

  if (preflight.tier === tiers.GOLD || features.length === 0) {
    await storeProjectPromise;
    logging.trackEvent('Clip Created', toClipCreatedProperties(request.project, features.length > 0));
    await redirectToExportPage();
    return;
  }

  // This straight up doesn't even work on production, so I'll skip it for now.
  //
  // if (preflight.tier === tiers.FREE) {
  //   amountOfPremiumRenders.value = await retryAsync(async () => {
  //     const response = await streamLadderAxios<number>('/api/renders/premiumRenderCount', { method: 'GET' });
  //     if (response.status === 200) {
  //       return response.data;
  //     } else {
  //       throw new Error('Failed to fetch premium render count');
  //     }
  //   })
  //
  //   if (amountOfPremiumRenders.value < 5) {
  //     isMax5PremiumExportsDialogOpen.value = true;
  //     return;
  //   }
  // }

  logging.trackEvent('Watermark Dialog Opened', toClipCreatedProperties(request.project, features.length > 0));
  isWatermarkOrExportDialogOpen.value = true;
});

iframeMessageBus.on('VIDEO_EDITOR_HISTORY_STATE_CHANGE', async (request: HistoryStateChange) => {
  project.value = request.snapshot;
  await historyStateApi.save(request, projectId);
});

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_FEATURE_ACCESS', (request: { feature: string }) => {
  switch (request.feature) {
    case 'upload-custom-elements':
      openUpgradeDialog({ feature: 'upload-custom-stickers' });
      return false;
    case 'sound-effects':
      openUpgradeDialog({ feature: 'sounds' });
      return false;
    case 'save-template':
      openUpgradeDialog({ feature: 'save-template' });
      return false;
    default:
      return true;
  }
});

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_TEXT_TO_SPEECH', async (request: { text: string, voice: string }) => {
  return await generateTextToSpeech(request.text, request.voice);
});

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_LIST_CUSTOM_TEMPLATES', customTemplatesApi.list);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_SAVE_CUSTOM_TEMPLATE', customTemplatesApi.upsert);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_REMOVE_CUSTOM_TEMPLATE', customTemplatesApi.remove);

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_LIST_CUSTOM_ELEMENTS', customElementsApi.list);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_UPLOAD_CUSTOM_ELEMENT', customElementsApi.upload);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_REMOVE_CUSTOM_ELEMENT', customElementsApi.delete);

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_LIST_CUSTOM_SOUND_EFFECTS', customSoundEffectsApi.list);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_UPLOAD_CUSTOM_SOUND_EFFECT', customSoundEffectsApi.upload);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_REMOVE_CUSTOM_SOUND_EFFECT', customSoundEffectsApi.delete);

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_CAPTIONS', transcriptionApi.transcribe);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_PROFANITY', transcriptionApi.detectProfanity);
iframeMessageBus.on('VIDEO_EDITOR_REQUEST_ANIMATED_EMOJIS', transcriptionApi.getAnimatedEmojis);

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_CUSTOM_CAPTION_PRESET', customCaptionPresetsApi.list);
iframeMessageBus.on('VIDEO_EDITOR_SAVE_CUSTOM_CAPTION_PRESET', customCaptionPresetsApi.save);
iframeMessageBus.on('VIDEO_EDITOR_DELETE_CUSTOM_CAPTION_PRESET', customCaptionPresetsApi.remove);

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_EVENT_TRACKING', loggingApi.trackEvent);

const chatButtonStyle = useChatButtonStyle();
const chatWindowStyle = useChatWindowStyle();

iframeMessageBus.on('VIDEO_EDITOR_CHAT_PLUGIN_RESIZE', (payload: { rect: DOMRect }) => {

  const rect = payload.rect;
  const offset = 50;

  chatButtonStyle.value = {
    top: `${rect.bottom - rect.width}px`, left: `${rect.left}px`, width: `${rect.width}px`, height: `${rect.width}px`,
    display: 'grid', alignItems: 'end', justifyContent: 'center', opacity: '1', pointerEvents: 'auto', visibility: 'visible',
  }

  chatWindowStyle.value = { top: 'auto', bottom: `calc(100dvh - ${rect.bottom - rect.width + offset}px)`, left: `${rect.left}px` }
});

iframeMessageBus.on('VIDEO_EDITOR_REQUEST_TWITCH_EMOTES', async (payload: { username: string }) => {
  return await getApiTwitchEmotes({ streamerName: payload.username });
});

onUnmounted(() => iframeMessageBus.destroy());
</script>

<template>
  <main class="w-[100dvw] h-[100dvh] flex flex-col overflow-hidden layer-0 text-brand-state-text-primary dark:bg-[#0a0a0a]">
    <UpgradeBanner @open-upgrade-dialog="(feature) => openUpgradeDialog({ feature })" />
    <MobileNavigationBar />

    <div class="grid flex-1 size-full relative">
      <iframe
        ref="iframe"
        :class="cn('overflow-hidden col-start-1 row-start-1 size-full', { hidden: !instructions, 'opacity-0': !hasStarted })"
      />
      <WorkspaceSpinner />
    </div>
  </main>

  <template v-if="usedPremiumFeatures.length && project">
    <Max5PremiumRendersDialog
      v-model:open="isMax5PremiumExportsDialogOpen"
      :project="project"
      :count="amountOfPremiumRenders"
      :features="usedPremiumFeatures"
      @upgrade="openUpgradeDialog"
      @render="redirectToExportPage"
    />
    <WatermarkOrUpgradeDialog
      v-model:open="isWatermarkOrExportDialogOpen"
      :project="project"
      :features="usedPremiumFeatures"
      @upgrade="openUpgradeDialog"
      @render-with-watermark="renderWithWatermark"
    />
  </template>
</template>

<style scoped lang="scss">

</style>
