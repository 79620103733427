<script setup lang="ts">
import { useHead } from '@unhead/vue'
import DashboardClipDownloaderForm from '@/areas/dashboard/pages/downloader/DashboardClipDownloaderForm.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRouter } from 'vue-router'
import TwitchIcon from '@/components/Icons/SocialMedia/TwitchIcon.vue'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import KickIcon from '@/components/Icons/SocialMedia/KickIcon.vue'
import ToolsOverview from '@/areas/dashboard/components/ToolsOverview.vue';

useHead({
  title: 'Clip Downloader',
  meta: [
    {
      name: 'description',
      content: 'Download Youtube, Twitch or Kick clip',
    },
  ],
})

const router = useRouter()
async function onClipSelected(source: string, id: string) {
  await router.push({ name: dashboardRouteNames.downloader.result, params: { source: source, id: id } })
}
</script>

<template>
  <main>
    <header
      class="flex w-full flex-col items-center gap-8 rounded-t-xl bg-gradient-to-br from-[#EB85FF] to-[#7C4CFD] p-4 sm:p-8 lg:p-24"
    >
      <div class="flex flex-col items-center gap-1">

        <ol class="flex gap-1">
          <li class="flex items-center gap-1.5 rounded bg-[#9147FF] px-2 py-1 font-semibold text-white">
            <TwitchIcon class="h-5 w-5 shrink-0" />
            Twitch
          </li>
          <li class="flex items-center gap-1 rounded bg-[#F00] px-2 py-1 font-semibold text-white">
            <YoutubeIcon class="h-5 w-5 shrink-0" />
            <p class="translate-y-[1px]">YouTube</p>
          </li>
          <li class="flex items-center gap-2 rounded bg-black px-2 py-1 font-semibold text-white">
            <KickIcon class="h-4 w-4 shrink-0 text-[#0CFF7F]" />
            <p class="translate-y-[1px]">Kick</p>
          </li>
        </ol>
        <h1 class="text-center text-white text-3xl lg:text-5xl text-pretty">
          Download YouTube, Twitch or Kick clip
        </h1>
        <p class="font-light text-white">Start by selecting a clip</p>
      </div>
      <DashboardClipDownloaderForm @selected="({ source, id }) => onClipSelected(source, id)" />
    </header>

    <section class="flex flex-col gap-4 px-6 py-8 lg:p-12">
      <h2 class="mb-0 mt-8 text-3xl font-normal text-brand-state-text-primary">
        Or check out our <span class="font-bold">other tools</span>
      </h2>
      <ToolsOverview />
    </section>
  </main>
</template>

<style scoped lang="scss">

</style>
