<script setup lang="ts">
import type { ColorStop, Gradient } from '@/components/Captions/v3/CaptionPreset'
import ColorInput from '@/components/colors/ColorInput.vue'
import IconSaxTrash from '@/components/Icons/iconsax/IconSaxTrash.vue'
import { Button } from '@/components/ui/button'
import {
  NumberField,
  NumberFieldContent,
  NumberFieldDecrement,
  NumberFieldIncrement,
  NumberFieldInput,
} from '@/components/ui/number-field'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { cn } from '@/lib/utils'
import { round } from 'lodash-es'
import tinycolor from 'tinycolor2'
import { v4 as uuid } from 'uuid'

const model = defineModel<Gradient>({ required: true })
const emit = defineEmits<{
  (e: 'commit-value'): void
  (e: 'update:model-value', value: Gradient): void
}>()
defineProps<{
  recentlyUsedColors?: readonly string[]
  recommendedColors?: readonly string[]
}>()

const variantModel = computed({
  get() {
    return model.value.variant
  },
  set(value: Gradient['variant']) {
    const updatedGradient = { ...model.value, variant: value }
    emit('update:model-value', updatedGradient)
    emit('commit-value')
  },
})

const cssGradient = computed(() => {
  const sortedStops = [...model.value.colorStops].sort((a, b) => a.stop - b.stop)
  const stopsString = sortedStops.map((stop) => `${stop.color} ${stop.stop * 100}%`).join(', ')

  if (model.value.variant === 'linear') {
    return `linear-gradient(90deg, ${stopsString})`
  }
  if (model.value.variant === 'radial') {
    return `radial-gradient(${stopsString})`
  }
  return ''
})

const gradientContainer = ref<HTMLElement | null>(null)
const isDragging = ref(false)
const dragIndex = ref<number | null>(null)
const gradientRect = ref<DOMRect | null>(null)
const maxColorStops = 10
const highlightStopIndex = ref<string | undefined>('')

const initialX = ref<number>(0)
const initialY = ref<number>(0)
const movementThreshold = 2 // pixels

const sortedColorStops = computed(() => {
  return [...model.value.colorStops].sort((a, b) => a.stop - b.stop)
})

function calculateDistance(x1: number, y1: number, x2: number, y2: number): number {
  const dx = x2 - x1
  const dy = y2 - y1
  return Math.sqrt(dx * dx + dy * dy)
}

const startDrag = (index: number, stop: ColorStop) => {
  dragIndex.value = index
  highlightStopIndex.value = stop.id

  if (event instanceof MouseEvent) {
    initialX.value = event.clientX
    initialY.value = event.clientY
  } else if (event instanceof TouchEvent) {
    initialX.value = event.touches[0].clientX
    initialY.value = event.touches[0].clientY
  }

  window.addEventListener('mousemove', onDrag)
  window.addEventListener('touchmove', onDrag)
  window.addEventListener('mouseup', stopDrag)
  window.addEventListener('touchend', stopDrag)
}

const onDrag = (event: MouseEvent | TouchEvent) => {
  if (dragIndex.value === null) return

  let currentX = 0
  let currentY = 0

  if (event instanceof MouseEvent) {
    currentX = event.clientX
    currentY = event.clientY
  } else if (event instanceof TouchEvent) {
    currentX = event.touches[0].clientX
    currentY = event.touches[0].clientY
  }

  const distance = calculateDistance(initialX.value, initialY.value, currentX, currentY)

  if (!isDragging.value && distance > movementThreshold) {
    isDragging.value = true

    if (gradientContainer.value) {
      gradientRect.value = gradientContainer.value.getBoundingClientRect()
    }
  }

  if (isDragging.value && dragIndex.value !== null && gradientRect.value) {
    let x = currentX - gradientRect.value.left
    let percent = x / gradientRect.value.width
    percent = round(Math.min(Math.max(percent, 0), 1), 2)
  
    const updatedColorStops = [...model.value.colorStops]
    updatedColorStops[dragIndex.value].stop = percent
  
    emit('update:model-value', { ...model.value, colorStops: updatedColorStops })
  }
}

const stopDrag = () => {
  if (isDragging.value) {
    dragIndex.value = null
    gradientRect.value = null
  
    emit('update:model-value', { ...model.value })
    emit('commit-value')
    isDragging.value = false
  }

  window.removeEventListener('mousemove', onDrag)
  window.removeEventListener('touchmove', onDrag)
  window.removeEventListener('mouseup', stopDrag)
  window.removeEventListener('touchend', stopDrag)
}

const onGradientClick = (event: MouseEvent | TouchEvent) => {
  if (model.value.colorStops.length >= maxColorStops) {
    alert(`Maximum of ${maxColorStops} color stops reached.`)
    return
  }

  if (!gradientContainer.value) {
    return
  }

  const clientX = 'touches' in event ? event.touches[0].clientX : event.clientX

  const rect = gradientContainer.value.getBoundingClientRect()
  let x = clientX - rect.left
  let percent = x / rect.width
  percent = round(Math.min(Math.max(percent, 0), 1), 2)

  // Find the closest stop lower than the new precent
  const closestStop = sortedColorStops.value.reduce((prev, curr) => {
    return curr.stop > prev.stop && curr.stop < percent ? curr : prev
  }, model.value.colorStops.at(0)!)

  // Find the closest stop higher than the new precent
  const closestStopAfter = sortedColorStops.value.reduce((prev, curr) => {
    return curr.stop < prev.stop && curr.stop > percent ? curr : prev
  }, model.value.colorStops.at(-1)!)

  // Create a color between the two closest stops
  const newColor = tinycolor.mix(closestStop.color, closestStopAfter.color, 50).toHexString()

  const newColorStop: ColorStop = {
    stop: percent,
    color: newColor,
  }

  const updatedColorStops = [...model.value.colorStops, newColorStop]
  updatedColorStops.sort((a, b) => a.stop - b.stop)

  emit('update:model-value', { ...model.value, colorStops: updatedColorStops })
  emit('commit-value')
}

onBeforeUnmount(() => {
  window.removeEventListener('mousemove', onDrag)
  window.removeEventListener('touchmove', onDrag)
  window.removeEventListener('mouseup', stopDrag)
  window.removeEventListener('touchend', stopDrag)
})

function addColorStop() {
  const lastStop = model.value.colorStops.at(-1)
  const secondLastStop = model.value.colorStops.at(-2)

  if (!lastStop) {
    return
  }

  if (lastStop && !secondLastStop) {
    const newColor = tinycolor(lastStop.color).darken(50).toHexString()

    model.value.colorStops.push({ stop: 1, color: newColor })
    return
  }

  // Create a color between the two last stops
  const newColor = tinycolor.mix(lastStop.color, secondLastStop!.color, 50).toHexString()
  const newStop: ColorStop = {
    id: uuid(),
    stop: (lastStop?.stop - secondLastStop!.stop) / 2 + secondLastStop!.stop,
    color: newColor,
  }

  const updatedColorStops = [...model.value.colorStops, newStop]
  updatedColorStops.sort((a, b) => a.stop - b.stop)

  emit('update:model-value', { ...model.value, colorStops: updatedColorStops })
  emit('commit-value')
}

function updateColorStop(index: number, newColor: string) {
  const updatedColorStops = [...model.value.colorStops]
  updatedColorStops[index].color = newColor
  const updatedGradient = { ...model.value, colorStops: updatedColorStops }
  emit('update:model-value', updatedGradient)
}

function deleteColorStep(index: number) {
  if (model.value.colorStops.length <= 2) {
    return
  }

  const updatedColorStops = [...model.value.colorStops]
  updatedColorStops.splice(index, 1)

  emit('update:model-value', { ...model.value, colorStops: updatedColorStops })
  emit('commit-value')
}
</script>

<template>
  <div class="flex gap-2">
    <div class="grid grid-cols-2 gap-2">
      <Select v-model="variantModel">
        <SelectTrigger class="h-auto !min-w-min px-3 py-2 text-sm font-normal">
          <SelectValue>{{ variantModel }}</SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="linear">Linear</SelectItem>
          <SelectItem value="radial">Radial</SelectItem>
        </SelectContent>
      </Select>

      <NumberField v-model.number.lazy="model.angle" :step="1" :min="0" :max="360" class="flex w-full flex-col gap-1">
        <NumberFieldContent class="group">
          <NumberFieldDecrement />
          <NumberFieldInput class="h-auto !min-w-min px-3 py-2 text-sm font-normal" />
          <span
            class="pointer-events-none absolute inset-0 grid place-items-center text-sm font-normal group-focus-within:opacity-0"
          >
            <span class="ml-1.5">{{ model.angle }}º</span>
          </span>
          <NumberFieldIncrement class="p-2" />
        </NumberFieldContent>
      </NumberField>
    </div>

    <div
      ref="gradientContainer"
      class="relative mx-auto mt-10 h-4 w-[90%] cursor-pointer rounded pt-5"
      :style="{ background: cssGradient }"
      @mousedown="onGradientClick"
      aria-label="Gradient Color Picker"
      role="slider"
      tabindex="0"
    >
      <div
        v-for="(stop, index) of sortedColorStops"
        :key="`color-stop-${index}`"
        role="button"
        tabindex="0"
        :aria-label="`Color stop ${index + 1}`"
        class="absolute top-0 z-10 h-6 w-6 origin-center -translate-x-1/2 -translate-y-[120%] cursor-pointer rounded drop-shadow-lg transition"
        :class="{ 'z-20 border-primary': isDragging && highlightStopIndex === stop.id }"
        :style="{ left: `${stop.stop * 100}%` }"
        @mousedown.stop.prevent="startDrag(index, stop)"
        @touchstart.stop.prevent="startDrag(index, stop)"
        @click="highlightStopIndex = stop.id"
      >
        <div
          :class="
            cn(
              'absolute left-0 top-[80%] h-0 w-0 border-l-12 border-r-12 border-t-10 border-transparent border-t-white',
              { 'border-t-primary': highlightStopIndex === stop.id }
            )
          "
        ></div>
        <div
          :class="cn('relative h-full w-full rounded bg-white p-1', { 'bg-primary': highlightStopIndex === stop.id })"
        >
          <div class="z-50 h-full w-full rounded-sm" :style="{ backgroundColor: stop.color }"></div>
        </div>
      </div>
    </div>

    <div class="mt-2 h-px bg-surface-panel-border" />

    <div v-if="sortedColorStops.length > 0" class="flex w-full flex-row items-center justify-between">
      <p class="m-0 text-sm">Stops</p>
      <Button @click="addColorStop" class="aspect-square h-auto w-8 p-0" variant="ghost" size="square">
        <IconPlus class="h-4 w-4" />
      </Button>
    </div>

    <div
      v-for="(stop, index) of sortedColorStops"
      :key="`color-stop-${index}`"
      class="grid grid-cols-[60px_auto_32px] gap-1 rounded-lg"
    >
      <NumberField
        v-model.number="stop.stop"
        :step="0.1"
        :min="0"
        :max="1"
        :format-options="{
          style: 'percent',
        }"
      >
        <NumberFieldContent>
          <NumberFieldInput class="h-full !min-w-min px-2 py-1 text-left text-xs font-normal" />
        </NumberFieldContent>
      </NumberField>

      <div class="flex h-full">
        <ColorInput
          v-model="stop.color"
          class="flex h-full w-full flex-1"
          side="right"
          :recently-used-colors="recentlyUsedColors"
          :recommended-colors="recommendedColors"
          @commit-value="emit('commit-value')"
          @update:model-value="(value) => updateColorStop(index, value as string)"
        >
          <Button
            variant="toggle"
            :class="
              cn('h-full w-full !min-w-min flex-1 justify-start border border-input bg-background px-2 py-1', {
                'border-primary': highlightStopIndex === stop.id,
              })
            "
            @click="highlightStopIndex = stop.id"
          >
            <span :style="{ backgroundColor: stop.color }" class="h-4 w-4 rounded border border-border" />
            <span class="flex-1 text-left text-xs font-normal uppercase">{{ stop.color }}</span>
          </Button>
        </ColorInput>
      </div>
      <Button
        class="grid aspect-square h-full place-items-center p-0"
        :disabled="model.colorStops.length <= 2"
        variant="ghost"
        @click="deleteColorStep(index)"
      >
        <IconSaxTrash class="h-4 w-4" />
      </Button>
    </div>
  </div>
</template>

<style scoped></style>
