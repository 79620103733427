<script setup lang="ts">
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus'
import { computed, type ComputedRef, watch } from 'vue'
import { useVideoStore } from '@/areas/editor/store/useVideoStore'
import { createZoomAt } from '@/areas/editor/store/functions/createZoomAt'
import { tiers } from '@/enums/tiers'
import GoldPlanButton from '@/components/Account/Upgrade/GoldPlanButton.vue'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore'
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore'
import type { Segment } from '@/areas/editor/@type/Project'
import { editorRouteNames } from '@/areas/editor/routeNames'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep'
import ZoomIcon from '@/components/Icons/ZoomIcon.vue'

const videoStore = useVideoStore()
const editorFocusStore = useEditorFocusStore()
const segmentsStore = useSegmentsStore()
const effectsStore = useEffectsStore()
const userInfoStore = useUserInfoStore()
const { currentSection } = useEditorStep()

const zoomSegments = segmentsStore.whereIsZoom() as ComputedRef<Segment[]>

const currentZoomSegment = computed(() => {
  return zoomSegments.value.find(
    (effect) => effect.startMs <= videoStore.currentTimeMs && effect.endMs >= videoStore.currentTimeMs
  )
})

watch(
  () => editorFocusStore.focus,
  () => {
    if (editorFocusStore.focus?.type === FocusTypes.ZOOM) {
      currentSection.value = editorRouteNames.zooms
    }
  }
)

function addOrEditZoom() {
  if (currentZoomSegment.value?.id) {
    const effect = effectsStore.selectBySegmentId(currentZoomSegment.value.id)
    if (effect.value) {
      editorFocusStore.setFocus(FocusTypes.ZOOM, effect.value.id)
    }
  } else {
    createZoomAt(videoStore.currentTimeMs)
  }
}
</script>

<template>
  <section
    class="flex cursor-pointer flex-col gap-2 rounded-xl border border-surface-panel-border bg-surface-panel p-3 hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
    @click="addOrEditZoom()"
  >
    <div class="flex items-center justify-between gap-2">
      <div class="flex items-center gap-2">
        <div class="grid size-12 place-items-center rounded-xl bg-gradient-to-r from-[#FAE6FF] to-[#DAF1FF]">
          <ZoomIcon class="size-12" />
        </div>
        <div class="flex flex-col">
          <h3 class="text-lg font-semibold">Zoom</h3>
          <p class="text-sm font-light text-muted-foreground">Add a zoom to your clip.</p>
        </div>
      </div>

      <GoldPlanButton
        v-if="userInfoStore.tier !== tiers.GOLD"
        class="pointer-events-none -mt-0.5"
        :can-click="false"
        :small="true"
      />
    </div>
  </section>
</template>

<style scoped></style>
