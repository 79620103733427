<script setup lang="ts">
import { computed } from 'vue'
import type { TypedSticker } from '@/areas/editor/@type/Project';
import { existsAsync, startupVideo, startupWithDefaultLayout } from '@/areas/editor/startup/generalStartupMethods';
import { handleStartupError } from '@/areas/editor/startup/handleStartupError';
import { intersection } from 'lodash-es'
import { useCropsStore } from '@/areas/editor/store/useCropsStore';
import { useEffectsStore } from '@/areas/editor/store/useEffectsStore';
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore';
import { useLayoutsStore } from '@/areas/editor/store/useLayoutsStore';
import { useSegmentsStore } from '@/areas/editor/store/useSegmentsStore';
import { useStickersStore } from '@/areas/editor/store/useStickersStore';
import Workspace from '@/areas/editor/workspaces/Workspace.vue';
import { captionStyleSettingsArray } from '@/components/Captions/styles/CaptionStyleManager'
import WorkspaceMobile from '@/areas/editor/workspaces/WorkspaceMobile.vue';
import WorkspaceSpinner from '@/areas/editor/workspaces/WorkspaceSpinner.vue';
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore';
import { useVideoStore } from '@/areas/editor/store/useVideoStore';
import { supabase } from '@/authentication/supabase';
import { captionStylesSettings } from '@/components/Captions/styles/CaptionStyleManager';
import { useIsMobile } from '@/Hooks/useIsMobile';
import { useCustomCaptionPresets } from '@/queries/customCaptionPresetsApi';
import { saveProject } from '@/queries/projects/autoSaveProjectById';
import { fetchProjectJson, storeProjectInDb } from '@/queries/projects/projectsApi';
import { defaultCaptionOptions, useEditorCaptionsStore } from '@/store/editor/editorCaptions';
import { useEditorClipInfoStore } from '@/store/editor/editorClipInfo';
import { FocusTypes, useEditorFocusStore } from '@/store/editor/editorFocus';
import { useFontsStore } from '@/store/fonts';
import { onUserInfoReadyAsync } from '@/store/user/userInfo';
import { cloneDeep, uniqBy } from 'lodash-es';
import { onMounted, onUnmounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { getLastUsedCaptionStyle } from '@/components/Captions/useLocalCaptionSettings'
import { captionStyleToCaptionPreset } from '@/components/Captions/v3/captionStyleToCaptionPreset'
import type { CaptionPreset, CustomCaptionPreset } from '@/components/Captions/v3/CaptionPreset'
import type { CaptionStyleDefinition } from '@/apis/streamladder-api/model'
import { useFeatureFlagVariantEnabled } from '@/Hooks/useFeatureFlagEnabled'
import { lastUsedCaptionPreset } from '@/components/Captions/useLocalCaptionSettings'
import { useEditorStep } from '@/areas/editor/hooks/useEditorStep';
import { getApiTwitchClipsClipId } from '@/apis/streamladder-api/twitch-clips/twitch-clips';
import EditorLayout from '@/areas/editor/pages/EditorLayout.vue';
import { captionPresets, type CaptionPresetKey } from '@/data/captionPresets';
import { getClipSlug } from '@/areas/editor/startup/repairTwitchClip';

const route = useRoute();
const router = useRouter();

const editorClipInfoStore = useEditorClipInfoStore();
const editorStep = useEditorStep()

const fontsStore = useFontsStore();

function isValidProjectId(projectId: unknown): projectId is string {
  return typeof projectId === 'string';
}

const historyStore = useHistoryStore();

const captionsStore = useCaptionsStore();
const { data: customCaptionPresets, refetch: fetchCustomCaptionPresets } = useCustomCaptionPresets()

const abortController = new AbortController();

const addCaptionsAtStartup = useFeatureFlagVariantEnabled('automatically-add-captions-at-startup', 'add-captions');

const isMounted = ref(false);
onMounted(async () => {

  // For some reason, if we open this page without awaiting the next tick, the preview will be shown without content for
  // a short moment. This is a workaround to prevent that from happening.
  await new Promise((resolve) => setTimeout(resolve, 0));
  isMounted.value = true;

  editorClipInfoStore.loadingState = {
    state: 'loading',
    description: 'Loading project...',
  };

  const projectId = route.params.projectId;
  if (!isValidProjectId(projectId)) {
    return await router.replace({ name: 'editor' });
  }

  const project = await tryDownloadProject(projectId);
  if (!project) {
    const userInfoStore = await onUserInfoReadyAsync();
    return editorClipInfoStore.loadingState = {
      state: 'error',
      title: 'Project not found 🕵️',
      description: userInfoStore.isAuthenticated
        ? 'Looks like the project you are trying to open does not exist, or is not available to you.'
        : 'Please sign in to access your projects.',
    };
  }

  if (!project.id || !project.mp4Url) {
    return handleStartupError(new Error('Invalid project data'));
  }

  const videoExists = await existsAsync(project.mp4Url);
  if (!videoExists && project.mp4Url.includes('twitch-clips-v2.b-cdn.net')) {
    const clipId = getClipSlug(project.mp4Url);
    if (clipId) {
      const result = await getApiTwitchClipsClipId(clipId);
      if (result.mp4Url) {
        project.mp4Url = result.mp4Url
        await storeProjectInDb(project);
      }
    }
  }

  editorClipInfoStore.id = project.id;
  editorClipInfoStore.mp4Url = project.mp4Url;
  editorClipInfoStore.title = project.title ?? 'New Clip';
  editorClipInfoStore.languageCode = project.language ?? null;

  const errors = [];

  try {
    editorStep.resetEditorStep();

    if (project.segments.length === 0) {

      let videoOffsetMs = 0;
      const mp4BoxPromise = await startupVideo({ signal: abortController.signal });
      editorClipInfoStore.loadingState = {
        state: 'loading',
        title: 'Loading video metadata',
        description: 'Ensuring the video is ready for editing...',
      };

      try {
        const info = await mp4BoxPromise();

        // Assume the first video track contains the framerate info
        const videoTrack = info.tracks.find(track => track.type === 'video');
        const audioTrack = info.tracks.find(track => track.type === 'audio');

        const videoMinusOneEntry = videoTrack?.edits?.find(edit => edit.media_time === -1);
        const audioMinusOneEntry = audioTrack?.edits?.find(edit => edit.media_time === -1);

        if (videoTrack && videoMinusOneEntry) {
          videoOffsetMs = Math.max(videoOffsetMs, videoMinusOneEntry.segment_duration / videoTrack.movie_timescale);
        }
        if (audioTrack && audioMinusOneEntry) {
          videoOffsetMs = Math.max(videoOffsetMs, audioMinusOneEntry.segment_duration / audioTrack.movie_timescale);
        }
      } catch (e) {
        console.error(e);
      }

      await startupWithDefaultLayout(abortController, videoOffsetMs);
    } else {

      await startupVideo({ signal: abortController.signal })

      const segmentsStore = useSegmentsStore();
      segmentsStore.$reset();
      for (const segment of project.segments) {
        segmentsStore.createById(segment.id, segment);
      }

      const layoutsStore = useLayoutsStore();
      layoutsStore.$reset();
      for (const layout of uniqBy(project.layouts, 'id')) {
        layoutsStore.createById(layout.id, layout);
      }

      const cropsStore = useCropsStore();
      cropsStore.$reset();
      for (const crop of project.crops) {
        cropsStore.createById(crop.id, crop);
      }
    }

    const editorFocusStore = useEditorFocusStore();
    if (project.crops[0]) {
      editorFocusStore.setFocus(FocusTypes.CROP, project.crops[0].id);
    }

    const stickersStore = useStickersStore();
    stickersStore.$reset();
    for (const sticker of project.stickers) {
      const upgradedSticker = purgeBlobUrlFrom(sticker);
      stickersStore.createById(upgradedSticker.id, upgradedSticker);
    }
  } catch (e) {
    errors.push(e);
  }

  const effectsStore = useEffectsStore();
  try {
    effectsStore.$reset();
    for (const effect of project.effects) {
      try {
        effectsStore.createById(effect.id, effect);
      } catch (e) {
        errors.push(e);
      }
    }
  } catch (e) {
    errors.push(e);
  }

  captionsStore.$reset();

  if ('entities' in project.captions) {
    try {

      for (const caption of project.captions.entities) {
        captionsStore.createById(caption.id, caption);
      }

      captionsStore.captionsArea = project.captions.captionsArea;
      captionsStore.bleepCurseWords = project.captions.bleepCurseWords || false;
      captionsStore.hasGeneratedCaptions = project.captions.hasGeneratedCaptions;
      captionsStore.hasGeneratedProfanity = project.captions.hasGeneratedProfanity || false;
      captionsStore.bleepCurseWordSoundEffect = project.captions.bleepCurseWordSoundEffect || null;
      captionsStore.customCaptionPresetId = project.captions.currentUserCaption?.id ?? project.captions.customCaptionPresetId ?? null;
      captionsStore.customCaptionPresetName = project.captions.currentUserCaption?.name ?? project.captions.customCaptionPresetName ?? null;

      captionsStore.baseCaptionPreset = project.captions.baseCaptionPreset ?? null;
      if (captionsStore.baseCaptionPreset && !captionsStore.baseCaptionPreset?.options) {
        const defaultOptions = captionPresets[captionsStore.baseCaptionPreset.key as CaptionPresetKey].options;
        captionsStore.baseCaptionPreset.options = project.captions.baseOptions ?? defaultOptions;
      }

      captionsStore.currentUserCaption = project.captions.currentUserCaption;

      if (captionsStore.bleepCurseWords && !captionsStore.hasGeneratedProfanity) {
        // Don't await this, because it's not critical for the project to load.
        captionsStore.generateProfanity();
      }
    } catch (e) {
      errors.push(e);
    }
  }

  if (errors.length > 0) {
    console.error(errors);
    return handleStartupError(new Error('Invalid project data'));
  }

  if ('captions' in project.captions) {

    const editorCaptionsStore = useEditorCaptionsStore();
    editorCaptionsStore.captionsSettings = project.captions.settings;
    editorCaptionsStore.captionsDocument = project.captions.document;

    // Wait for captions document side effects to finish.
    await new Promise((resolve) => setTimeout(resolve, 0));

    editorCaptionsStore.groupedCaptions.splice(0, editorCaptionsStore.groupedCaptions.length, ...project.captions.captions);

    const captionStyleDefinition = captionStylesSettings[project.captions.settings.style];
    await fontsStore.loadFontByLabel(captionStyleDefinition.fontFamily);
  }

  await fetchCustomCaptionPresets()
  for (const preset of customCaptionPresets.value || []) {
    if (captionsStore.currentUserCaption?.id === preset.id) {
      captionsStore.currentUserCaption = cloneDeep(preset)
      captionsStore.baseCaptionPreset = cloneDeep(preset.preset)
      captionsStore.baseOptions = cloneDeep(preset.preset.options)
    }
  }

  editorClipInfoStore.loadingState = null;

  historyStore.transaction('PROJECT:CREATE');

  if (addCaptionsAtStartup.value && captionsStore.entities.length === 0) {

    const defaultCaptionStyle = getLastUsedCaptionStyle();
    const sortedCaptionsArray = computed(() => captionStyleSettingsArray
      .map((captionStyle) => ({ ...captionStyle, createdAt: captionStyle.createdAt || new Date(0) }))
      .sort((a, b) => {
        if (a.type === defaultCaptionStyle) {
          return -1
        } else if (b.type === defaultCaptionStyle) {
          return 1
        } else if (intersection(a?.tags, ['christmas', 'halloween']).length > 0) {
          return 1
        } else if (intersection(b?.tags, ['christmas', 'halloween']).length > 0) {
          return -1
        } else {
          return 1
        }
      }));

    const preset = captionStyleToCaptionPreset(sortedCaptionsArray.value[0].type) as CaptionPreset;
    const captionStyleDefinition = captionStylesSettings[sortedCaptionsArray.value[0].type] as CaptionStyleDefinition;

    // Don't await this, because it's not critical for the project to load.
    captionsStore.generateCaptions(preset, captionStyleDefinition.highlightColor)
      .then(() => {
        if (captionsStore.bleepCurseWords) {
          captionsStore.generateProfanity();
        }
      });
  }

  // await uploadProjectThumbnail();

  videoStore.playing = true;
  // Play might fail if user has not interacted with the page yet. In which case we will mute the video and then play it again, since playing muted videos is allowed and playing the video takes precedence over the audio.
  videoStore.videoElement?.play().catch(() => {
    videoStore.muteAudioAndVideo();
    videoStore.videoElement?.play().catch(console.error);
  });
});

supabase.auth.onAuthStateChange((event) => {
  if (event === 'SIGNED_IN') {
    saveProject();
  }
});

async function tryDownloadProject(id: string) {
  try {
    return await fetchProjectJson(id);
  } catch (e) {
    handleStartupError(e);
    return null;
  }
}

// In some cases a Blob URL is stored in the sticker object. This function removes it, because it can cause the application
// to throw errors when the Blob URL is no longer valid (for example after reloading).
function purgeBlobUrlFrom(sticker: TypedSticker) {
  const upgradedSticker = { ...sticker }
  if (upgradedSticker.imageUrl?.startsWith('blob:')) {
    upgradedSticker.imageUrl = ''
  }
  return upgradedSticker
}

onUnmounted(() => {
  abortController.abort();
});

const isMobile = useIsMobile();

const videoStore = useVideoStore();
</script>

<template>
  <EditorLayout>
    <template v-if="isMounted" #router-view>
      <div v-if="editorClipInfoStore.loadingState || !videoStore.videoSize"
        class="flex items-center justify-center p-8 w-full h-full">
        <WorkspaceSpinner />
      </div>
      <template v-else>
        <template v-if="isMobile">
          <WorkspaceMobile />
        </template>
        <template v-else>
          <Workspace />
        </template>
      </template>
    </template>
  </EditorLayout>
</template>

<style scoped></style>
