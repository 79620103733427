<script setup lang="ts">
import { cn } from '@/lib/utils'
import { type TranscriptWord, useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import logging from '@/logging'

const captionsStore = useCaptionsStore()

function toggleCurseWord(word: TranscriptWord) {
  if (word.isCurseWord) {
    logging.trackEvent('Curse Word Removed', { word: word.text })
    captionsStore.unmarkAsCurseWordByIds([word.id])
  } else {
    logging.trackEvent('Curse Word Added', { word: word.text })
    captionsStore.markAsCurseWordByIds([word.id])
  }
}
</script>

<template>
  <div class="text-normal flex flex-wrap text-base font-light text-muted-foreground">
    <template v-for="entity in captionsStore.entities" :key="entity.id">
      <span v-for="word in entity.words" :key="word.id">
        <span
          :class="
            cn('cursor-pointer rounded transition select-none p-1 hover:bg-accent-focus/40', { 'bg-accent-focus/40 p-1 font-semibold line-through text-foreground': word.isCurseWord })
          "
          @click="toggleCurseWord(word)"
          >{{ word.text }}</span
        >
      </span>
    </template>
  </div>
</template>

<style scoped></style>
