<template>
  <Card>
    <CardHeader class="cursor-pointer flex-row gap-2 p-8" @click="open = !open">
      <div class="flex aspect-square h-12 w-12 items-center justify-center rounded bg-zinc-200 text-special-youtube dark:text-white">
        <YoutubeIcon class="h-5 w-5 fill-current" />
      </div>
      <div class="flex flex-col">
        <CardTitle>YouTube settings</CardTitle>
        <CardDescription>Decide how your YouTube post should look.</CardDescription>
      </div>
      <div class="flex-grow" />
      <Button variant="primary" size="circle" class="h-6 w-6 self-center p-1">
        <IconSaxArrowDown2
          class="transform transition-all"
          :class="{
            'rotate-180': open,
          }"
        />
      </Button>
    </CardHeader>
    <CardContent v-show="open" class="flex flex-col gap-2">
      <div class="flex w-full flex-row items-center justify-between gap-8">
        <div class="flex flex-col">
          <span class="label-text mb-1 font-bold text-foreground">Notify subscribers?</span>
          <span class="mb-2 font-light text-xs"> Let your subscribers know that you have uploaded a new video. </span>
        </div>
        <LightSwitch
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]"
          class="text-sm"
          :value="_notifySubscribers.toString()"
          @update:value="(value) => (_notifySubscribers = value === 'true')"
        />
      </div>
      <hr />
      <div class="flex w-full flex-row items-center justify-between gap-8">
        <div class="flex flex-col">
          <span class="label-text mt-2 mb-1 font-bold text-foreground">Child friendly?</span>
          <span class="mb-2 font-light text-xs">
            Determine whether your content is suitable for kids according to the
            <a class="text-blue-500" href="https://support.google.com/youtube/answer/9528076?hl=en" target="_blank"
              >YouTube guidelines.
            </a>
          </span>
        </div>
        <LightSwitch
          :options="[
            { label: 'Yes', value: 'true' },
            { label: 'No', value: 'false' },
          ]"
          class="text-sm"
          :value="_isChildFriendly.toString()"
          @update:value="(value) => (_isChildFriendly = value === 'true')"
        />
      </div>
      <hr />
      <div class="group relative flex w-full flex-col justify-between gap-2 sm:min-h-fit">
        <span class="label-text mt-1 mb-1 font-bold text-foreground">Visibility</span>
        <Select v-model="_visibility">
          <SelectTrigger class="w-full">
            <SelectValue placeholder="Select an option" />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem v-for="item of Object.values(youTubeVisibility)" :key="item" :value="item" class="cursor-pointer">
                {{ item }}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <hr />
      <div class="group relative flex w-full flex-col justify-between gap-2 sm:min-h-fit">
        <span class="label-text mt-1 mb-1 font-bold text-foreground">Category</span>
        <Select v-model="_category">
          <SelectTrigger class="w-full" :disabled="isLoading">
            <div class="flex items-center">
              <span v-if="isLoading" class="mr-2">
                <svg class="animate-spin h-4 w-4 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                  <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
              </span>
              <SelectValue placeholder="Gaming" />
            </div>
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              <SelectItem 
                v-for="(title, id) in youTubeCategories" 
                :key="id" 
                :value="id" 
                class="cursor-pointer"
              >
                {{ title }}
              </SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
        <div v-if="errors?.category" class="text-xs text-red-500 mt-1">
          {{ errors.category[0].message }}
        </div>
      </div>
    </CardContent>
    <CardFooter v-show="open" />
  </Card>
</template>
<script lang="ts" setup>
import { useVModel } from '@vueuse/core'
import YoutubeIcon from '@/components/Icons/SocialMedia/YoutubeIcon.vue'
import { Card, CardContent, CardDescription, CardHeader, CardTitle, CardFooter } from '@/components/ui/card'
import { Button } from '@/components/ui/button'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import { ref, computed } from 'vue'
import LightSwitch from '@/components-v2/data-input/boolean/LightSwitch.vue'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { YouTubeVisibility as youTubeVisibility } from '@/apis/streamladder-publisher/model'
import { useGetYoutubeVideoCategories } from '@/apis/streamladder-publisher/you-tube/you-tube'

const props = defineProps<{
  isChildFriendly: boolean
  visibility: keyof typeof youTubeVisibility
  notifySubscribers: boolean
  category?: string
  errors?: Record<string, { code: string; message: string }[]>
}>()

const emit = defineEmits(['update:isChildFriendly', 'update:publishPublic', 'update:notifySubscribers', 'update:category'])

const open = ref(false)

const _isChildFriendly = useVModel(props, 'isChildFriendly', emit)
const _visibility = useVModel(props, 'visibility', emit)
const _notifySubscribers = useVModel(props, 'notifySubscribers', emit)
const _category = useVModel(props, 'category', emit)

// Fetch YouTube categories from the API
const { data: youTubeCategoriesData, isLoading } = useGetYoutubeVideoCategories()

// Transform the categories data for the select component
const youTubeCategories = computed(() => {
  if (!youTubeCategoriesData.value || youTubeCategoriesData.value.length === 0) {
    // If no data, return an object with just the Gaming category
    return { '20': 'Gaming' }
  }
  
  // Create an object with id as key and title as value
  return youTubeCategoriesData.value.reduce((acc, category) => {
    if (category.id && category.title) {
      acc[category.id] = category.title
    }
    return acc
  }, {} as Record<string, string>)
});
</script>
