<script lang="ts" setup>
import { type VideoResultDto } from '@/apis/streamladder-api/model'
import MontageMakerVideo from '@/areas/dashboard/components/MontageMaker/MontageMakerVideo.vue'
import type ResultVideoPreview from '@/areas/dashboard/components/ResultVideos/ResultVideoPreview.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import NoContentSection from '@/components-v2/data-display/NoContentSection.vue'
import MultiUploadDialog from '@/components/Dialog/MultiUploadDialog/MultiUploadDialog.vue'
import IconSaxAdd from '@/components/Icons/iconsax/IconSaxAdd.vue'
import IconSaxVideo from '@/components/Icons/iconsax/IconSaxVideo.vue'
import UploadIcon from "@/components/Icons/UploadIcon.vue"
import { Button } from '@/components/ui/button'
import { videosFilter } from '@/Hooks/useFilterVideos'
import { useGuard } from '@/Hooks/useGuard'
import { range } from 'lodash-es'
import { computed, ref } from 'vue'

const props = defineProps<{
  singleRow?: boolean
  selectedVideoUrls: string[]
  isLoadingVideos: boolean
  filteredVideos: VideoResultDto[]
  dataUpdatedAt: number
}>()

const emit = defineEmits<{
  (name: 'addToMontage', url: string): void
  (name: 'removeFromMontage', url: string): void
}>()

const previews = ref<typeof ResultVideoPreview[]>()
const previewVideos = computed<HTMLVideoElement[]>(() => {
  return (previews.value ?? []).map((p) => p.video).filter(Boolean)
})

function pauseOthers(id: string | null | undefined) {
  for (const video of previewVideos.value) {
    if (video.id !== id) {
      video.pause()
    }
  }
}

const isLoading = computed(() => props.isLoadingVideos)
const amountOfVideos = computed(() => {
  if (isLoading.value) {
    return 4
  } else if (props.singleRow) {
    return Math.min(4, props.filteredVideos.length)
  } else {
    return props.filteredVideos.length
  }
})

const canUseMontageMaker = computed(() => useGuard('montage-maker').value)
</script>

<template>
  <div>
    <section v-if="!canUseMontageMaker">
      <ol class="single-row grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5">
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-1.jpg" />
        </li>
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-2.jpg" />
        </li>
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-3.jpg" />
        </li>
        <li class="block">
          <MontageMakerVideo placeholder-src="/images/posters/placeholder-4.jpg" />
        </li>
      </ol>
    </section>

    <section v-else-if="amountOfVideos >= 2">
      <TransitionGroup
        :key="videosFilter! + props.dataUpdatedAt"
        :class="{ 'grid-single-row': singleRow || isLoading }"
        appear
        class="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-5"
        name="grid"
        tag="ol"
      >
        <li :key="`filteredVideos_local`">
          <MultiUploadDialog upload-in-background storage-variant="medium" :auto-close-dialog-in-ms="2000">
            <Button
              class="flex flex-col rounded-xl h-full w-full items-center justify-center border border-dashed border-brand-state-hover-border bg-brand-state-hover-bg group"
            >
              <div class="mb-4 aspect-square rounded-full bg-cyan-800 p-4">
                <UploadIcon class="text-brand-state-hover-text h-6 text-white w-6 group-hover:scale-125 transition-all" />
              </div>
              <span class="text-style-lg text-brand-state-text-primary group-hover:text-white transition-all">Upload files</span>
              <span class="text-style-sm text-brand-state-text-secondary group-hover:text-white transition-all">Choose your files</span>
            </Button>
          </MultiUploadDialog>
        </li>
        <li v-for="i in range(amountOfVideos)" :key="`filteredVideos_${i}`" class="block">
          <MontageMakerVideo
            ref="previews"
            :video="filteredVideos[i]"
            :selectedVideoUrls="selectedVideoUrls"
            @addToMontage="(e) => emit('addToMontage', e)"
            @removeFromMontage="(e) => emit('removeFromMontage', e)"
            @play="pauseOthers"
          />
        </li>
      </TransitionGroup>

      <div v-if="singleRow && !isLoading && amountOfVideos > 4" class="mt-4 flex flex-row justify-center gap-4">
        <RouterLink
          :to="{ name: dashboardRouteNames.videos }"
          class="active:bg-zinc-600/25 mt-4 flex items-center gap-2 rounded-xl border border-current px-3 py-1.5 font-semibold transition-all hover:border-transparent hover:bg-zinc-800 hover:text-zinc-100 active:scale-90 dark:hover:bg-zinc-200 dark:hover:text-zinc-800"
        >
          <IconSaxVideo />
          View all clips
        </RouterLink>
      </div>
    </section>

    <NoContentSection
      v-else
      :title="amountOfVideos === 1 ? 'You need one more clip to create a montage!' : 'You need at least 2 clips to create a montage.'"
      subtitle="Create additional clips with our editor to get your montage rolling!"
    >
      <div class="flex flex-col gap-5 items-center">
        <RouterLink :to="{ name: 'editor' }" class="flex">
          <GradientButton class="shadow-lg">
            Create a clip
            <IconSaxAdd />
          </GradientButton>
        </RouterLink>
        <MultiUploadDialog upload-in-background storage-variant="medium" :auto-close-dialog-in-ms="2000">
          <Button variant="outline" class="shadow-lg p-5 rounded-lg">
            Upload file
            <UploadIcon class="w-5" />
          </Button>
        </MultiUploadDialog>
      </div>
    </NoContentSection>
  </div>
</template>

<style lang="scss"></style>
