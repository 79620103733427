import { useLocalStorage } from "@vueuse/core";

const recommendedColors = {
    LIGHT: ["#000000", "#FFFF00", "#FFB038", "#FF4949", "#FF498C", "#FF66C4", "#FFFFFF", "#38B6FF", "#00FFFF", "#38FFD7", "#39FF14", "#A374FF"],
    DARK: ["#000000", "#847E11", "#935F10", "#8F0000", "#A22A56", "#9E1A6B", "#FFFFFF", "#1978AF", "#099494", "#197A67", "#268514", "#441B96"],
} as const

if (window.localStorage.getItem("lastUsedCaptionColors") !== null) {
    window.localStorage.removeItem("lastUsedCaptionColors")
}

export const useRecentlyUsedCaptionColors = () => {
    const recentlyUsedCaptionColors = useLocalStorage<string[]>("recently-used:caption-colors", [])

    const setRecentlyUsedColor = (color: string) => {
        if (typeof color !== "string") {
            return
        }
        // Set timout is used to make sure the colors is added to the recently used after the color picker animation is done.
        setTimeout(() => {
            color = color.toLowerCase()
            recentlyUsedCaptionColors.value = [color, ...recentlyUsedCaptionColors.value.filter(c => c !== color)].slice(0, 16)
        }, 125)
    };

    return {
        recommendedColors,
        recentlyUsedCaptionColors,
        setRecentlyUsedColor,
    }
}