<script setup lang="ts">
import { useIsMobile } from '@/Hooks/useIsMobile'
import type { Effect } from '@/areas/editor/@type/Project'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useHistoryStore } from '@/areas/editor/store/useHistoryStore'
import BleepEffect from '@/areas/editor/views/effects/sections/curse-words/components/BleepEffect.vue'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger
} from '@/components/ui/dialog'
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover'
import { ChevronsUpDown } from 'lucide-vue-next'

const historyStore = useHistoryStore()
const captionsStore = useCaptionsStore()
const isMobile = useIsMobile()

const isOpen = ref(false)

const selectedSound = computed(() => {
  return captionsStore.curseWordSoundEffects.find((s) => s.id === captionsStore.bleepCurseWordSoundEffect)
})

function setCurseSoundEffect(sound: Effect<'sound'> | null) {
  historyStore.transaction('CAPTIONS:CHANGE_BLEEP_SOUND', () => {
    captionsStore.setCurseSoundEffect(sound)
  })

  isOpen.value = false
}
</script>

<template>
  <template v-if="isMobile">
    <Dialog v-model:open="isOpen">
      <DialogTrigger class="w-full">
        <div
          class="relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 transition-[border-color] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
        >
          <BleepEffect v-if="selectedSound" :sound="selectedSound" />
          <div
            v-else
            class="relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg"
          >
            <div class="flex h-full items-center justify-center gap-3">
              <div class="bg-gradient flex items-center justify-center rounded-full p-2.5">
                <IconForbid class="size-5 text-white" />
              </div>
              <div class="flex select-none flex-col gap-0 text-sm">
                <span class="max-w-full overflow-hidden truncate font-bold">Mute</span>
              </div>
            </div>
          </div>
    
          <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </div>
      </DialogTrigger>

      <DialogContent class="mx-auto max-w-[90%] rounded-lg">
        <DialogHeader class="mb-4">
          <DialogTitle>Choose a censor sound effect</DialogTitle>
        </DialogHeader>

        <div class="flex flex-col gap-2">
          <div
            class="relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 transition-[border-color] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
            :class="{
              'border-brand-state-hover-border bg-brand-state-hover-bg': !captionsStore.bleepCurseWordSoundEffect,
            }"
            @click="setCurseSoundEffect(null)"
          >
            <div class="flex h-full items-center justify-center gap-3">
              <div class="bg-gradient flex items-center justify-center rounded-full p-2.5">
                <IconForbid class="size-5 text-white" />
              </div>
              <div class="flex select-none flex-col gap-0 text-sm">
                <span class="max-w-full overflow-hidden truncate font-bold">Mute</span>
              </div>
            </div>
          </div>
      
          <div
            v-for="sound in captionsStore.curseWordSoundEffects"
            :key="sound.id"
            class="group relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 transition-[border-color] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
            :class="{
              'border-brand-state-hover-border bg-brand-state-hover-bg':
                captionsStore.bleepCurseWordSoundEffect === sound.id,
            }"
            @click="setCurseSoundEffect(sound)"
          >
            <BleepEffect :sound="sound" />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  </template>

  <template v-else>
    <Popover v-model:open="isOpen">
      <PopoverTrigger class="w-full">
        <div
          class="relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 transition-[border-color] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
        >
          <BleepEffect v-if="selectedSound" :sound="selectedSound" />
          <div
            v-else
            class="relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg"
          >
            <div class="flex h-full items-center justify-center gap-3">
              <div class="bg-gradient flex items-center justify-center rounded-full p-2.5">
                <IconForbid class="size-5 text-white" />
              </div>
              <div class="flex select-none flex-col gap-0 text-sm">
                <span class="max-w-full overflow-hidden truncate font-bold">Mute</span>
              </div>
            </div>
          </div>
    
          <ChevronsUpDown class="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </div>
      </PopoverTrigger>
      <PopoverContent class="flex w-[--radix-popover-trigger-width] flex-col gap-2 p-2">
        <div
          class="relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 transition-[border-color] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
          :class="{
              'border-brand-state-hover-border bg-brand-state-hover-bg': !captionsStore.bleepCurseWordSoundEffect,
          }"
          @click="setCurseSoundEffect(null)"
        >
          <div class="flex h-full items-center justify-center gap-3">
            <div class="bg-gradient flex items-center justify-center rounded-full p-2.5">
              <IconForbid class="size-5 text-white" />
            </div>
            <div class="flex select-none flex-col gap-0 text-sm">
              <span class="max-w-full overflow-hidden truncate font-bold">Mute</span>
            </div>
          </div>
        </div>
    
        <div
          v-for="sound in captionsStore.curseWordSoundEffects"
          :key="sound.id"
          class="group relative flex w-full cursor-pointer items-center justify-between gap-2 rounded-lg border p-3 transition-[border-color] hover:border-brand-state-hover-border hover:bg-brand-state-hover-bg dark:border-surface-panel-border dark:hover:border-brand-state-hover-border"
          :class="{
            'border-brand-state-hover-border bg-brand-state-hover-bg':
              captionsStore.bleepCurseWordSoundEffect === sound.id,
          }"
          @click="setCurseSoundEffect(sound)"
        >
          <BleepEffect :sound="sound" />
        </div>
      </PopoverContent>
    </Popover>
  </template>
</template>