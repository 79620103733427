import { existsAsync } from '@/areas/editor/startup/generalStartupMethods';
import { getApiTwitchClipsClipId } from '@/apis/streamladder-api/twitch-clips/twitch-clips';
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import type { Source } from '@/areas/editor/@type/editor-project/Source';
import { getClipSlug } from '../../startup/repairTwitchClip';
import type { Segment } from '@/areas/editor/@type/editor-project/Segment';

export async function replaceBrokenTwitchUrlsIfNeeded(project: EditorProject) {
  
  const replacementMap = new Map<string, Promise<string>>();

  const promises = project.sources.map<Promise<Source>>(async (source) => {

    const clipId = source.url.includes('twitch-clips-v2.b-cdn.net')
      ? getClipSlug(source.url)
      : null;
    
    if (!clipId) {
      return source;
    }
    
    const promise = replacementMap.get(clipId);
    if (promise) {
      source.url = await promise;
      return source;
    } else {
      const promise = (async () => {
        const videoExists = await existsAsync(source.url);
        if (videoExists) {
          return source.url;
        } else {
          const result = await getApiTwitchClipsClipId(clipId);
          return result.mp4Url!;
        }
      })();

      replacementMap.set(clipId, promise);
      source.url = await promise;
      return source;
    }
  });

  // @ts-ignore
  project.sources = await Promise.all(promises);
  return project;
}

export async function replaceBrokenTwitchSourceUrlsIfNeeded(project: EditorProject) {

  const replacementMap = new Map<string, Promise<string>>();

  const promises = project.segments.map<Promise<Segment>>(async (segment) => {

    const clipId = segment.sourceUrl.includes('twitch-clips-v2.b-cdn.net')
      ? getClipSlug(segment.sourceUrl)
      : null;

    if (!clipId) {
      return segment;
    }

    const promise = replacementMap.get(clipId);
    if (promise) {
      segment.sourceUrl = await promise;
      return segment;
    } else {
      const promise = (async () => {
        const videoExists = await existsAsync(segment.sourceUrl);
        if (videoExists) {
          return segment.sourceUrl;
        } else {
          const result = await getApiTwitchClipsClipId(clipId);
          return result.mp4Url!;
        }
      })();

      replacementMap.set(clipId, promise);
      segment.sourceUrl = await promise;
      return segment;
    }
  });

  // @ts-ignore
  project.segments = await Promise.all(promises);
  return project;
}
