import { useRoute } from 'vue-router'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed } from 'vue'
import { isArray } from 'lodash-es'
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import type { DeepPartial } from 'ts-essentials'
import {
  type ContentPublisherFormState,
  MarketingHashtags,
} from '@/areas/dashboard/pages/contentPublishing/ContentPublishHelpers'
import {
  useGetUploadedVideosQuery,
  type EnhancedUploadedVideo,
} from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import type { VideoResultDto } from '@/apis/swagger-api-streamladder'

export const useContentPublisherVideoData = () => {
  const route = useRoute()
  const userInfo = useUserInfoStore()

  const refetched = ref(false);

  const videoId = computed(() => {
    if (isArray(route.params.videoId) || !route.params.videoId) {
      return undefined
    }
    return route.params.videoId
  })

  const hasVideo = computed(() => {
    return !!videoId.value
  })

  const isAuthenticated = computed(() => userInfo.isAuthenticated && userInfo.hasLoadedUserInfo)
  const { data: videos, refetch } = useGetApiVideos({ fromDays: 30 }, {
    query: {
      enabled: isAuthenticated,
    },
  })
  
  const { uploadedVideos } = useGetUploadedVideosQuery()

  const data = computed(() => {

    const fromVideos = videos.value?.find((video) => video.id === videoId.value) ;

    // sometimes the last 30 days does not have the video yet?
    // lame
    if (videoId.value?.length && !fromVideos && !refetched.value) {
      refetched.value = true;
      setTimeout(() => {
        refetch();
      }, 1000)
    }

    return fromVideos ?? uploadedVideos.value?.find((video) => video.id === videoId.value)
  })
  
  const videoResultDto = computed<VideoResultDto | null>(() => {
    if (isVideoResultDto(data.value)) {
      return data.value
    } else if (isEnhancedUploadedVideo(data.value)) {
      return toVideoResultDto(data.value)
    } else {
      return null
    }
  })

  const getFormData = (): DeepPartial<ContentPublisherFormState> => {
    const title = videoResultDto.value?.title ?? ''
    return {
      youtube: {
        title: title,
        description: MarketingHashtags,
      },
      instagram: {
        description: title,
      },
      tikTok: {
        title: title + ' ' + MarketingHashtags || MarketingHashtags,
      },
    }
  }

  return {
    videoId,
    hasVideo,
    videoData: videoResultDto,
    getFormData,
  }
}

function isVideoResultDto(video: VideoResultDto | EnhancedUploadedVideo | undefined): video is VideoResultDto {
  return video !== undefined && 'title' in video
}

function isEnhancedUploadedVideo(video: VideoResultDto | EnhancedUploadedVideo | undefined): video is EnhancedUploadedVideo {
  return video !== undefined && 'name' in video
}

function toVideoResultDto(enhancedUploadedVideo: EnhancedUploadedVideo): VideoResultDto {
  return {
    id: enhancedUploadedVideo.id,
    title: enhancedUploadedVideo.name,
    thumbnailUrl: enhancedUploadedVideo.thumbnailUrl,
    resultUrl: enhancedUploadedVideo.videoUrl,
  }
}
