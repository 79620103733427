<script setup lang="ts">
import { DialogContent, Dialog } from '@/components/ui/dialog';
import IconSaxExport from '@/components/Icons/iconsax/IconSaxExport.vue';
import IconSaxFlash from '@/components/Icons/iconsax/IconSaxFlash.vue';
import { Button } from '@/components/ui/button';
import LottieAnimation from '@/components/LottieAnimation.vue';
import { type Feature } from '@/data/features';
import type { EditorProject } from '@/areas/editor/@type/editor-project';
import DynamicPlanButton from '@/components/Account/Upgrade/DynamicPlanButton.vue';

interface Props {
  count: number;
  project: EditorProject;
  features: Feature[];
}

defineProps<Props>();

interface Emits {
  (event: 'upgrade'): void;
  (event: 'render'): void;
}

const emit = defineEmits<Emits>();

const isOpen = defineModel<boolean>('open', { required: true });

const labels: Partial<Record<Feature, string>> = {
  'stickers': 'Social Stickers',
  'text': 'Texts or Social Stickers',
  'captions': 'AI-Powered Captions',
  'video-segmentation': 'Multiple Segments',
  'zoom-segments': 'Zoom Effects',
  'sounds': 'Sound Effects',
  'giphy-stickers': 'Animated Giphy Stickers',
  'animated-social-stickers': 'Animated Social Stickers',
  'profanity': 'Profanity Filter',
  'resolution-1080': 'High Quality Export'
}
</script>

<template>
  <Dialog v-model:open="isOpen">
    <DialogContent class="flex flex-col items-center lg:gap-2 lg:px-6 lg:py-12 max-w-3xl">

      <LottieAnimation :loop="false" url="/lottie/present.json" class="w-[100px] pb-2 md:w-[300px] md:pb-3" />

      <h2 class="text-center text-3xl font-bold dark:text-white">
        You have <span class="text-company-primary-200 font-extrabold">{{ 5 - count }}</span> Free Premium Exports left!
      </h2>

      <div class="flex flex-col gap-2">
        <p class="text-center text-sm lg:text-base font-light text-gray-600 dark:text-white">
          Try all Awesome Features free until your Free Premium Exports run out.
        </p>
      </div>

      <div class="flex flex-col gap-2 mt-4">
        <Button @click="emit('upgrade')" size="lg" class="text-black bg-yellow-300 hover:bg-yellow-200">
          <IconSaxFlash class="h-5 w-5" />
          Upgrade Now to Premium
        </Button>

        <Button @click="emit('render')" variant="primary" size="lg">
          <IconSaxExport class="h-5 w-5" />
          Export Clip
        </Button>
      </div>

      <div class="flex flex-col gap-4 items-center max-w-full border rounded-lg p-4 bg-slate-100 dark:bg-surface-panel">
        <p class="font-semibold">Premium Features Used</p>
        <div class="flex flex-wrap items-center justify-center w-full gap-2 font-light text-sm">
          <template v-for="feature in features" :key="feature">
            <div class="bg-white dark:bg-surface-panel flex gap-2 border rounded px-2 py-1 cursor-default text-nowrap">
              <span class="font-semibold">{{ labels[feature] }}</span>
              <DynamicPlanButton class="pointer-events-none h-4 w-4 mt-0.5" small :feature="feature" />
            </div>
          </template>
        </div>
      </div>
    </DialogContent>
  </Dialog>
</template>

<style scoped lang="scss">

</style>
