import { editorRouteNames } from '@/areas/editor/routeNames'
import { useProjectStore } from '@/areas/editor/store/useProjectStore'
import CaptionsPage from '@/areas/editor/views/captions/CaptionsPage.vue'
import CaptionsPageV2 from '@/areas/editor/views/captions/v2/CaptionsPage.vue'
import EffectsPage from '@/areas/editor/views/effects/EffectsPage.vue'
import ElementsPage from '@/areas/editor/views/elements/ElementsPage.vue'
import TwitchEmotesPage from '@/areas/editor/views/emotes/TwitchEmotesPage.vue'
import ExportPage from '@/areas/editor/views/export/ExportPage.vue'
import GifsPage from '@/areas/editor/views/gifs/GifsPage.vue'
import LayoutsPage from '@/areas/editor/views/layouts/LayoutsPage.vue'
import RivePage from '@/areas/editor/views/rive/RivePage.vue'
import SoundsPage from '@/areas/editor/views/sounds/SoundsPage.vue'
import SoundsPageEditSound from '@/areas/editor/views/sounds/SoundsPageEditSound.vue'
import StickerLibraryTab from '@/areas/editor/views/stickers/StickerLibraryTab.vue'
import StickerUploadTab from '@/areas/editor/views/stickers/StickerUploadTab.vue'
import TextPage from '@/areas/editor/views/text/TextPage.vue'
import ZoomsPage from '@/areas/editor/views/zooms/ZoomsPage.vue'
import { createGlobalState, usePrevious, type Awaitable } from '@vueuse/core'
import { noop } from 'lodash-es'
import { computed, ref } from 'vue'
import CensorWordsSectionPage from '@/areas/editor/views/effects/sections/curse-words/CensorWordsSectionPage.vue'


export const useEditorStep = createGlobalState(() => {

  const projectStore = useProjectStore();
  
  const steps = computed(() => {
    return [
      {
        name: editorRouteNames.layouts,
        component: LayoutsPage,
        sections: null,
      },
      {
        name: editorRouteNames.elements,
        component: ElementsPage,
        sections: [
          {
            name: editorRouteNames.stickers,
            component: StickerLibraryTab,
          },
          {
            name: editorRouteNames.uploads,
            component: StickerUploadTab,
          },
          {
            name: editorRouteNames.gifs,
            component: GifsPage,
          },
          {
            name: editorRouteNames.text,
            component: TextPage,
          },
          {
            name: editorRouteNames.emotes,
            component: TwitchEmotesPage,
          },
          {
            name: editorRouteNames.rive,
            component: RivePage,
          }
      ]},
      {
        name: editorRouteNames.effects,
        component: EffectsPage,
        sections: [
          {
            name: editorRouteNames.zooms,
            component: ZoomsPage,
          },
          {
            name: editorRouteNames.censorWords,
            component: CensorWordsSectionPage,
          }
        ],
      },
      {
        name: editorRouteNames.sounds,
        component: SoundsPage,
        sections: [
          {
            name: editorRouteNames.editSounds,
            component: SoundsPageEditSound,
          },
        ],
      },
      {
        name: editorRouteNames.captions,
        component: projectStore.useLegacyCaptions ? CaptionsPage : CaptionsPageV2,
        sections: null,
      },
      {
        name: editorRouteNames.export,
        component: ExportPage,
        sections: null,
      },
    ];
  });

  function findStepIndex(step: (typeof steps)[number]['name'] | undefined) {
    return step ? steps.value.map(s => s.name).indexOf(step) : -1
  }

  const currentSection = ref<string|null>(null);

  const _currentStep = ref<typeof steps[number]['name']>('editor-layouts');
  const currentStep = computed({
    get: () => _currentStep.value,
    set: async (value) => {
      if (value === _currentStep.value) {
        return
      }

      const { isCanceled } = await beforeChangeStep(value);
      if (!isCanceled) {
        _currentStep.value = value;
        currentSection.value = null;
      }
    }
  });

  const previousStep = usePrevious(currentStep);

  const component = computed(() => {

    const step = steps.value[findStepIndex(currentStep.value)];

    if (!step?.component) {
      currentStep.value = editorRouteNames.layouts;
      return steps.value[0].component;
    }

    if (!step?.sections) {
      return step.component;
    }

    const section = step.sections?.find(s => s.name === currentSection.value);
    if (section) {
      return section.component;
    } else {
      return step.component;
    }
  });

  const sections = computed(() => steps.value[findStepIndex(currentStep.value)]?.sections);

  const isLayoutsStep = computed(() => currentStep.value === editorRouteNames.layouts)
  const isCaptionsStep = computed(() => currentStep.value === editorRouteNames.captions)
  const isEffectsStep = computed(() => currentStep.value === editorRouteNames.effects)
  const isZoomStep = computed(() => currentStep.value === editorRouteNames.effects && currentSection.value === editorRouteNames.zooms)
  const isRiveStep = computed(() => currentSection.value === editorRouteNames.rive);

  const stepIndex = computed(() => findStepIndex(currentStep.value))
  const direction = computed(() => {
    if (findStepIndex(previousStep.value) < findStepIndex(currentStep.value)) {
      return 'forward'
    } else {
      return 'backward'
    }
  })

  function selectLabelByStepIndex(index: number) {
    switch (index) {
      case 0:
        return 'Crop'
      case 1:
        return 'Elements'
      case 2:
        return 'Effects'
      case 3:
        return 'Audio'
      case 4:
        return 'Captions'
      case 5:
        return 'Export'
      default:
        return 'Render'
    }
  }

  const isBottomPanelOpen = ref(true)
  const cropMode = ref<'cropper' | 'preview'>('cropper')
  const cropperOrPreviewSwitchMobile = computed<'cropper' | 'preview'>({
    get() {
      return cropMode.value;
    },
    set(value) {
      cropMode.value = value;
      isBottomPanelOpen.value = value !== 'preview';
    }
  });


  return {
    isBottomPanelOpen,
    cropperOrPreviewSwitchMobile,
    steps,
    currentStep,
    previousStep,
    component,
    currentSection,
    sections,
    stepIndex,
    direction,
    isLayoutsStep,
    isCaptionsStep,
    isEffectsStep,
    isZoomStep,
    isRiveStep,
    selectLabelByStepIndex,
    resetEditorStep() {
      currentStep.value = editorRouteNames.layouts
      currentSection.value = null
    },
  }
})

type StepChangeInterceptor = (step: string) => Awaitable<{ isCanceled: boolean }>;

const beforeChangeStepListeners = new Set<StepChangeInterceptor>();

export function onBeforeChangeStep(fn: StepChangeInterceptor) {
  beforeChangeStepListeners.add(fn);
  return () => beforeChangeStepListeners.delete(fn);
}

export function useBeforeChangeStep(fn: StepChangeInterceptor) {
 
  let unsubscribe = noop;
  onMounted(() => {
    unsubscribe = onBeforeChangeStep(fn);
  });

  onUnmounted(() => {
    unsubscribe();
  });
}

async function beforeChangeStep(step: string) {
  let isCanceled = false;
  for (const listener of beforeChangeStepListeners) {
    const result = await listener(step);
    if (result.isCanceled) {
      isCanceled = true;
    }
  }
  return { isCanceled };
}