<script lang="ts" setup>
import { requestUserSignInAsync } from '@/authentication/supabase'
import FileUploadCallToAction from '@/components/Dialog/MultiUploadDialog/FileUploadCallToAction.vue'
import FileUploadPreview from '@/components/Dialog/MultiUploadDialog/FileUploadPreview.vue'
import PreviousUploads from '@/components/Dialog/MultiUploadDialog/PreviousUploads.vue'
import { cleanupFileStorage } from '@/components/Dialog/MultiUploadDialog/file-uploads/_storage'
import { useFileUploads } from '@/components/Dialog/MultiUploadDialog/file-uploads/useFileUploads'
import { useGetUploadedVideosQuery } from '@/components/Dialog/MultiUploadDialog/file-uploads/useUploadedVideos'
import IconSaxArrowDown2 from '@/components/Icons/iconsax/IconSaxArrowDown2.vue'
import IconSaxDocumentUpload from '@/components/Icons/iconsax/IconSaxDocumentUpload.vue'
import IconSaxTickCircle from '@/components/Icons/iconsax/IconSaxTickCircle.vue'
import { Button } from '@/components/ui/button'
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@/components/ui/dialog'
import { batchAsync } from '@/helpers/batchAsync'
import { formatFileSize } from '@/helpers/fileSize'
import logging from '@/logging'
import { metadataService } from '@/services/metadataService'
import { useUserInfoStore } from '@/store/user/userInfo'
import { useDropZone } from '@vueuse/core'
import { computed, ref } from 'vue'
import type { Upload } from './file-uploads/Upload'

interface Props {
  max?: number
  callToAction?: string
  signInCallToAction?: string
  uploadInBackground?: boolean
  storageVariant?: Upload['storageVariant']
  titleEditable?: boolean
  showRecentlyUploaded?: boolean
  autoCloseDialogInMs?: number | null
}

const props = withDefaults(defineProps<Props>(), {
  max: 1,
  uploadInBackground: false,
  storageVariant: 'short',
  titleEditable: false,
  showRecentlyUploaded: false,
  autoCloseDialogInMs: null,
});

const emit = defineEmits<{ (name: 'select', event: { id: string; title: string; videoUrl: string }): void }>()

const fileUploads = useFileUploads()
const allUploaded = fileUploads.all((u) => u.status === 'finished')
const allPending = fileUploads.all((u) => u.status === 'pending')

const isOpen = ref(false)
const dropzone = ref<HTMLLabelElement | null>(null)
const { isOverDropZone } = useDropZone(dropzone, { onDrop: updateFiles })

async function updateFiles(input: FileList | File[] | null) {

  if (!input) return

  multiUploadFinished.value = false

  const files = Array.from<File>(input)

  // If the user tries to upload more files than the max, we need to cancel the oldest uploads. If an upload that is
  // to be canceled matches a file that is being uploaded, we don't cancel it, but rather let fileUploads display a
  // warning on the original upload.
  const idsToCancel = fileUploads.uploads
    .slice(0, files.length + fileUploads.ids.length - props.max)
    .filter((u) => !files.some((f) => metadataService.isSameMetaData(f, u.file)))
    .map((u) => u.id)

  batchAsync(idsToCancel, (id) => fileUploads.cancel(id, 'Upload was replaced by a newer file upload by the user.'))
    .then(() => {
      if (idsToCancel.length > 0) {
        logging.trackEvent('Local file replaced', {
          count: idsToCancel.length,
          max: props.max,
        })
      }
    })

  for (const file of files.slice(0, props.max)) {
    await fileUploads.add(file, props.storageVariant)
  }

  if (files.slice(0, props.max).length > 1) {
    logging.trackEvent('Local file multi-upload', { count: files.length })
  }

  if (files.length > props.max) {
    logging.trackEvent('Local file multi-upload attempted', {
      amountOfFiles: files.length,
      max: props.max,
      excess: files.length - props.max,
    })
  }
}

function updateFilesFromEvent(event: Event) {
  const target = event.target as HTMLInputElement
  updateFiles(target.files)
  target.value = ''
}

const userInfoStore = useUserInfoStore()

const isNavigating = ref(false)

const { uploadedVideos } = useGetUploadedVideosQuery()
const showAll = ref(false)
const uploads = computed(() => (showAll.value ? uploadedVideos.value : uploadedVideos.value.slice(0, 3)))

async function usePreviousUpload(id: string) {
  const upload = uploadedVideos.value.find((u) => u.id === id)
  if (!upload) return

  emit('select', { id: upload.id, title: upload.name, videoUrl: upload.videoUrl })
}

async function onSelect(selection: { id: string, title: string, videoUrl: string }) {
  const isSignedIn = await requestUserSignInAsync('Please sign in to upload your video file.')
  if (isSignedIn) {
    emit('select', selection)
  }
}

// This is a safe place to perform routine cleanup without affecting the user experience.
async function onToggleOpen() {
  await fileUploads.batchRemove(['finished', 'error'])
  await cleanupFileStorage()
  isNavigating.value = false
  multiUploadFinished.value = false
}

const multiFileUploaded = computed(() => fileUploads.validUploads.length > 1)
const multiUploadFinished = ref(false)

watch(allUploaded, (done: boolean) => {
  if (done && props.autoCloseDialogInMs !== null && fileUploads.uploads.length > 0) {
    setTimeout(() => {
      isOpen.value = false
    }, props.autoCloseDialogInMs);
  }
});

watch(uploads, (newValue) => {
  if (newValue.length === 0 && multiUploadFinished.value) {
    fileUploads.uploads.map(u => fileUploads.cancel(u.id));
    multiUploadFinished.value = false;
  }
});
</script>

<template>
  <Dialog v-model:open="isOpen" @update:open="onToggleOpen">
    <DialogTrigger v-if="$slots.default" as-child>
      <slot />
    </DialogTrigger>
    <DialogContent class="max-w-3xl gap-8">

      <header v-if="multiUploadFinished" class="flex flex-col gap-1">
        <DialogTitle class="text-foreground">Your files</DialogTitle>
        <DialogDescription class="font-light text-foreground opacity-50">
          Here are the videos you uploaded. You can start editing them now.
        </DialogDescription>
      </header>

      <header v-else class="flex flex-col gap-1">
        <DialogTitle class="text-foreground">Upload your media</DialogTitle>
        <DialogDescription v-if="max === 1" class="font-light text-foreground opacity-50">
          Upload a video file to start editing.
        </DialogDescription>
        <DialogDescription v-else class="font-light text-foreground opacity-50">
          Add up to {{ max }} videos here, to edit in the future.
        </DialogDescription>
      </header>

      <label
        v-if="!multiUploadFinished"
        ref="dropzone"
        :class="{
          'border-dashed border-brand-state-active-border hover:bg-brand-state-hover-bg active:scale-90': fileUploads.uploads.length < 5,
          'dark:bg-sky-400/50 !border-solid bg-brand-state-hover-bg text-black dark:text-white': isOverDropZone,
        }"
        class="flex cursor-pointer flex-col items-center gap-4 rounded-lg border-2 p-8 transition-[color,border-color,background-color,transform]"
      >
        <input
          :multiple="max > 1"
          accept=".mp4,.mov"
          class="invisible absolute inset-0"
          type="file"
          @change="updateFilesFromEvent"
        />
        <IconSaxDocumentUpload :class="isOverDropZone ? 'text-sky-300 dark:text-sky-700' : 'text-brand-state-link'" />
        <span class="flex flex-col gap-1">
          <span v-if="isOverDropZone" class="text-center text-sm"> Drop to upload </span>
          <span v-else class="text-center text-sm">
            Drag your {{ max === 1 ? 'file' : 'files' }} or
            <Button as="p" class="h-auto cursor-pointer p-0" variant="link">browse</Button>
          </span>
          <span class="text-center text-xs font-light opacity-50">
            Max {{ formatFileSize(userInfoStore.fileSizeLimit) }} per file is allowed. Only .mp4 and .mov files are supported.
          </span>
        </span>
      </label>

      <template v-if="fileUploads.uploads.length === 0 && uploads.length && showRecentlyUploaded && userInfoStore.isLoggedIn">
        <div class="flex items-center opacity-50">
          <div class="h-px w-full bg-surface-panel-border" />
          <span class="px-2 text-brand-state-text-secondary">OR</span>
          <div class="h-px w-full bg-surface-panel-border" />
        </div>

        <div class="flex min-h-0 flex-col gap-4">
          <h3 class="text-foreground">Recently uploaded files</h3>

          <div class="-mx-6 min-h-0 overflow-y-auto px-6">
            <ol class="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
              <PreviousUploads
                v-for="upload in uploads"
                :upload="upload"
                :key="upload.id"
                @click="usePreviousUpload"
              />
            </ol>
          </div>
        </div>
      </template>

      <template v-if="multiUploadFinished">
        <template v-if="userInfoStore.isLoggedIn">
          <div class="flex min-h-0 flex-col gap-4">
            <div class="-mx-6 min-h-0 overflow-y-auto px-6">
              <ol class="grid gap-4 sm:grid-cols-2 md:grid-cols-3">
                <PreviousUploads
                  v-for="upload in uploads"
                  :upload="upload"
                  :key="upload.id"
                  @click="usePreviousUpload"
                />
              </ol>
            </div>
          </div>
        </template>
      </template>

      <template v-else>
        <template v-if="userInfoStore.isLoggedIn">
          <div
            v-if="fileUploads.anyUploadInProgress"
            class="dark:bg-sky-400/50 flex items-center justify-between rounded-lg border border-brand-state-hover-border bg-brand-state-hover-bg p-4"
          >
            <div>
              <h3 v-if="fileUploads.ids.length === 1">1 video</h3>
              <h3 v-else>{{ fileUploads.ids.length }} videos</h3>
              <p class="text-xs font-light text-brand-state-hover-border">
                <template v-if="allPending">Preparing...</template>
                <template v-else>Uploading... {{ fileUploads.overallProgress }}%</template>
              </p>
            </div>
            <div
              class="h-6 w-6 animate-[spin_600ms_linear_infinite] rounded-full border border-brand-state-hover-border border-t-transparent"
            />
          </div>
          <div
            v-else-if="fileUploads.anyUploadFinished"
            class="flex items-center justify-between rounded-lg border border-brand-state-success bg-brand-state-success-bg p-4 text-brand-state-success"
          >
            <div>
              <h3 v-if="fileUploads.validUploads.length === 1" class="text-foreground">1 video</h3>
              <h3 v-else class="text-foreground">{{ fileUploads.validUploads.length }} videos</h3>
              <p class="text-xs font-light text-green-700">Successfully uploaded</p>
            </div>
            <IconSaxTickCircle class="h-8 w-8 text-green-700" />
          </div>
        </template>
        <ol class="flex flex-col">
          <FileUploadPreview v-for="id in fileUploads.ids" :key="id" :id="id" :title-editable="titleEditable" />
        </ol>
      </template>

      <footer
        class="flex items-center justify-center gap-2"
        v-if="fileUploads.uploads.length === 0 && showRecentlyUploaded"
      >
        <Button variant="depressed" @click="showAll = !showAll" v-if="uploadedVideos?.length > 3">
          {{ showAll ? 'Collapse' : 'Show All' }}
          <IconSaxArrowDown2 class="h-4 w-4 transition-transform" :class="showAll ? 'rotate-180' : ''" />
        </Button>
      </footer>

      <footer class="flex items-center justify-center gap-2" v-else>
        <template v-if="multiFileUploaded">
          <Button
            v-if="!multiUploadFinished"
            :disabled="fileUploads.uploads.length === 0"
            variant="primary"
            @click="
              () => {
                multiUploadFinished = true
                showAll = true
              }
            "
          >
            Finish uploading
          </Button>
        </template>

        <template v-else-if="callToAction">
          <FileUploadCallToAction
            v-if="fileUploads.validUploads[0]"
            @select="selection => onSelect(selection)"
            :id="fileUploads.validUploads[0].id"
            :call-to-action="callToAction"
            :sign-in-call-to-action="signInCallToAction"
            :upload-in-background="uploadInBackground"
          />
          <Button disabled v-else>
            {{ callToAction }}
          </Button>
        </template>
      </footer>
    </DialogContent>
  </Dialog>
</template>

<style lang="scss" scoped></style>
