<script setup lang="ts">
import { useBeforeChangeStep } from '@/areas/editor/hooks/useEditorStep'
import { useCaptionsStore } from '@/areas/editor/store/useCaptionsStore'
import { useGenerateCaptions } from '@/areas/editor/views/captions/useGenerateCaptions'
import CaptionV2StyleSelector from '@/areas/editor/views/captions/v2/CaptionV2StyleSelector.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'
import { useEditorCaptionsStore } from '@/store/editor/editorCaptions'
import { onMounted } from 'vue'
import { CaptionEditorCaptionTabs, CaptionEditorSettingTabs } from '../tabs'

const legacyCaptionsStore = useEditorCaptionsStore()
const captionsStore = useCaptionsStore()
const { captionsLanguageCode, supportedLocales } = useGenerateCaptions()

onMounted(async () => {
  // We don't want to keep legacy captions when you get into this component.
  legacyCaptionsStore.$reset()
})

watch(
  () => captionsStore.captionIdBeingEdited,
  (editingCaption) => {
    if (editingCaption) {
      captionsStore.sidebarPage = 'captions'
    }
  },
  { immediate: true }
)

useBeforeChangeStep(async () => {
  if (captionsStore.hasUnsavedChanges) {
    captionsStore.savePreset(captionsStore.currentUserCaption!)
  }
  return { isCanceled: false }
})

onBeforeMount(() => {
  const handleBeforeUnload = () => {
    if (captionsStore.hasUnsavedChanges) {
      captionsStore.savePreset(captionsStore.currentUserCaption!)
    }
    captionsStore.sidebarPage = 'captions'
  }

  window.addEventListener('beforeunload', handleBeforeUnload)

  onBeforeUnmount(() => {
    captionsStore.sidebarPage = 'captions'
    window.removeEventListener('beforeunload', handleBeforeUnload)
  })
})
</script>

<template>
  <div class="flex h-full flex-col gap-4 overflow-y-auto p-0 pb-0 sm:h-screen">
    <div class="relative p-4 2xl:p-8">
      <Transition
        enter-from-class="opacity-0 translate-x-6"
        leave-to-class="opacity-0 -translate-x-6"
        enter-active-class="motion-safe:transition-[transform,_opacity]"
        leave-active-class="motion-safe:transition-[transform,_opacity]"
      >
        <template v-if="captionsStore.isGenerating">
          <div class="flex h-full flex-col items-center gap-2 pt-2 text-center">
            <LottieAnimation class="-mt-16" height="300px" marginBottom="90px" url="/lottie/magician-wand.json" />
            <p>
              <strong>Making magic</strong>
            </p>
            <p class="my-2 font-light">
              This process might take up to 20 seconds depending on the length of your clip.
            </p>
            <div
              class="flex items-center justify-center gap-4 rounded-lg border border-current bg-emerald-500/5 p-4 text-sm font-light text-emerald-600 dark:bg-emerald-500/5 dark:text-emerald-500"
            >
              You can continue working on your project while we generate the captions.
            </div>
          </div>
        </template>

        <template v-else-if="!captionsStore.hasGeneratedCaptions || !captionsStore.baseCaptionPreset">
          <div class="absolute inset-4 2xl:inset-8 flex flex-col gap-2">
            <header>
              <div class="flex items-center gap-1">
                <h2 class="-mt-[1px] font-title text-xl font-semibold leading-snug">AI-Powered Captions</h2>
              </div>

              <p class="font-light">
                Select a caption style below and customize it to your preferences after adding captions.
              </p>

              <div class="my-2">
                <span class="label-text text-foreground">Select the spoken language:</span>
                <Select v-model="captionsLanguageCode">
                  <SelectTrigger>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent class="z-[1000]">
                    <SelectGroup>
                      <SelectItem v-for="locale of supportedLocales" :key="locale.code" :value="locale.code">
                        {{ locale.label }}
                      </SelectItem>
                    </SelectGroup>
                  </SelectContent>
                </Select>
              </div>
            </header>

            <CaptionV2StyleSelector />
          </div>
        </template>

        <template v-else>
          <div class="relative w-full">
            <div class="absolute inset-0 flex flex-col gap-4">
              <CaptionEditorCaptionTabs v-if="captionsStore.sidebarPage === 'captions'" />
              <CaptionEditorSettingTabs v-else />
            </div>
          </div>
        </template>
      </Transition>
    </div>
  </div>
</template>

<style scoped></style>
