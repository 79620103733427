/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * StreamLadder Publisher Api
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/vue-query'
import type { QueryFunction, QueryKey, UseQueryOptions, UseQueryReturnType } from '@tanstack/vue-query'
import { unref } from 'vue'
import type { MaybeRef } from 'vue'
import type { GetYoutubeVideoCategoriesParams, YouTubeCategoryDto } from '.././model'
import { publisherAxiosInstance } from '../../../services/axios'

type AwaitedInput<T> = PromiseLike<T> | T

type Awaited<O> = O extends AwaitedInput<infer T> ? T : never

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1]

export const getYoutubeVideoCategories = (
  params?: MaybeRef<GetYoutubeVideoCategoriesParams>,
  options?: SecondParameter<typeof publisherAxiosInstance>,
  signal?: AbortSignal
) => {
  params = unref(params)

  return publisherAxiosInstance<YouTubeCategoryDto[]>(
    { url: `/youtube/video-categories`, method: 'GET', params: unref(params), signal },
    options
  )
}

export const getGetYoutubeVideoCategoriesQueryKey = (params?: MaybeRef<GetYoutubeVideoCategoriesParams>) => {
  return ['youtube', 'video-categories', ...(params ? [params] : [])] as const
}

export const getGetYoutubeVideoCategoriesQueryOptions = <
  TData = Awaited<ReturnType<typeof getYoutubeVideoCategories>>,
  TError = void
>(
  params?: MaybeRef<GetYoutubeVideoCategoriesParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getYoutubeVideoCategories>>, TError, TData>>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {}

  const queryKey = getGetYoutubeVideoCategoriesQueryKey(params)

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getYoutubeVideoCategories>>> = ({ signal }) =>
    getYoutubeVideoCategories(params, requestOptions, signal)

  return { queryKey, queryFn, staleTime: 60000, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getYoutubeVideoCategories>>,
    TError,
    TData
  >
}

export type GetYoutubeVideoCategoriesQueryResult = NonNullable<Awaited<ReturnType<typeof getYoutubeVideoCategories>>>
export type GetYoutubeVideoCategoriesQueryError = void

export const useGetYoutubeVideoCategories = <
  TData = Awaited<ReturnType<typeof getYoutubeVideoCategories>>,
  TError = void
>(
  params?: MaybeRef<GetYoutubeVideoCategoriesParams>,
  options?: {
    query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getYoutubeVideoCategories>>, TError, TData>>
    request?: SecondParameter<typeof publisherAxiosInstance>
  }
): UseQueryReturnType<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetYoutubeVideoCategoriesQueryOptions(params, options)

  const query = useQuery(queryOptions) as UseQueryReturnType<TData, TError> & { queryKey: QueryKey }

  query.queryKey = unref(queryOptions).queryKey as QueryKey

  return query
}
