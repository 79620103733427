import { detectProfanity, getAnimatedEmojis, getTranscriptFor } from '@/apis/aiApi';
import { requestUserSignInAsync } from '@/authentication/supabase';

export default {

  async detectProfanity(request: { transcript: string }) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      return await detectProfanity(request.transcript);
    } else {
      throw new Error('User is not signed in');
    }
  },

  async transcribe(request: { url: string, languageCode?: string }) {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      return await getTranscriptFor(request.url, request.languageCode);
    } else {
      throw new Error('User is not signed in');
    }
  },

  async getAnimatedEmojis(request: { text: string[] })  {
    const isSignedIn = await requestUserSignInAsync();
    if (isSignedIn) {
      return await getAnimatedEmojis(request.text);
    }
  }
}
