import { type Awaitable } from '@vueuse/core'
import { v4 as uuid } from 'uuid'

export class IframeMessageBus {

  readonly #iframe: MaybeRefOrGetter<HTMLIFrameElement | null>;
  get iframe() {
    return toValue(this.#iframe);
  }

  private readonly instanceId = uuid();

  #origin: string | null = null;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private listeners = new Set<[type: string, (event: any, onProgress: (progress: number) => void) => Awaitable<any>]>();

  constructor(iframe: MaybeRefOrGetter<HTMLIFrameElement | null>, app: App, onReady: () => Awaitable<void>) {

    this.#iframe = iframe;

    const initialize = async (event: MessageEvent) => {

      if (event.data.type !== 'READY' || event.data.instanceId !== this.instanceId) {
        return;
      }

      const isProdOrigin = import.meta.env.PROD && event.origin.endsWith('video-editor-frontend.pages.dev');
      const isDevOrigin = event.origin === iframeOrigin;

      if (!isProdOrigin && !isDevOrigin) {
        console.debug(`Ready event from ${ event.origin } ignored.`);
        return;
      }

      if (this.#origin === null) {
        this.#origin = event.origin;
      }

      await onReady();
      window.removeEventListener('message', initialize);
      window.addEventListener('message', this.onMessage.bind(this));
    };

    const stop = watch(() => this.iframe, (iframe) => {
      if (iframe) {
        iframe.src = iframeUrlOf(app) + '#' + this.instanceId;
        setTimeout(() => stop(), 0);
        window.addEventListener('message', initialize);
      }
    });
  }

  destroy() {
    window.removeEventListener('message', this.onMessage.bind(this));
    this.listeners = new Set();
  }

  private async onMessage(event: MessageEvent) {

    if (event.origin !== event.origin) {
      console.debug(`Message from ${ event.origin } ignored.`);
      return;
    }

    if (event.source === null) {
      console.debug('Message without Event Source ignored.');
      return;
    }

    if (event.data.instanceId !== this.instanceId) {
      console.debug('Message from other instance ignored. Expected:', this.instanceId, 'Received:', event.data.instanceId);
      return;
    }

    const promises = Array.from(this.listeners).map(async ([ type, listener ]) => {
      if (type === event.data.type) {
        try {

          const response = await listener(event.data.payload, (progress: number) => {
            if (event.data.channel) {
              this.progress(event.data.channel, progress);
            }
          });

          if (event.data.channel) {
            this.resolve(event.data.channel, response);
          }
        } catch (exception) {
          if (event.data.channel) {
            if (exception instanceof Error) {
              this.reject(event.data.channel, exception.message);
            } else if (typeof exception === 'object') {
              const clone = JSON.parse(JSON.stringify(exception));
              this.reject(event.data.channel, clone);
            } else {
              this.reject(event.data.channel, exception);
            }
          }
        }
      }
    });

    await Promise.all(promises);
  }

  private resolve<T>(channel: string, payload?: T) {
    this.emit('ASYNC_MESSAGE_RESULT', { channel, payload });
  }

  private reject<T>(channel: string, payload: T) {
    this.emit('ASYNC_MESSAGE_ERROR', { channel, payload });
  }

  private progress(channel: string, progress: number) {
    this.emit('ASYNC_MESSAGE_PROGRESS', { channel, progress });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  on<TMessage>(event: string, fn: (message: TMessage, onProgress: (progress: number) => void) => Awaitable<any>) {
    this.listeners.add([ event, fn ]);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  off<TMessage>(event: string, fn: (message: TMessage, onProgress: (progress: number) => void) => Awaitable<any>) {
    this.listeners.delete([ event, fn ]);
  }

  emit<T>(type: string, message?: T) {
    if (this.iframe?.contentWindow && new URL(this.iframe.src).origin === iframeOrigin && this.#origin) {
      this.iframe.contentWindow.postMessage({ type, ...message }, this.#origin);
    } else {
      console.error(`iframe not ready for ${type}:`, message);
    }
  }
}

const iframeOrigin = new URL(import.meta.env.VITE_VIDEO_EDITOR_URL as string).origin;
const videoEditorIframeUrl = iframeOrigin + '/';
const videoPreviewIframeUrl = iframeOrigin + '/preview';
const videoRenderIframeUrl = iframeOrigin + '/video-render';

type App = 'editor' | 'renderer' | 'preview';
function iframeUrlOf(app: App) {
  switch (app) {
    case 'editor': return videoEditorIframeUrl;
    case 'renderer': return videoRenderIframeUrl;
    case 'preview': return videoPreviewIframeUrl;
  }
}
