<script lang="ts" setup>
import ConvertInBackgroundAlert from '@/areas/editor/pages/components/ConvertInBackgroundAlert.vue'
import features from '@/areas/editor/pages/data/features.json'
import { cn } from '@/lib/utils'
import { useRoute, useRouter } from 'vue-router'
import { IframeMessageBus } from '@/areas/editor/pages/api/IframeMessageBus'
import { ref } from 'vue'
import { Skeleton } from '@/components/ui/skeleton'
import { onUserInfoReadyAsync } from '@/store/user/userInfo'
import { upgradeProjectToEditorProject } from '@/data/versions'
import {
  replaceBrokenTwitchSourceUrlsIfNeeded,
  replaceBrokenTwitchUrlsIfNeeded
} from '@/areas/editor/pages/config/replaceBrokenTwitchUrlsIfNeeded'
import { useQuery } from '@tanstack/vue-query'
import { fetchProjectJson } from '@/queries/projects/projectsApi'
import { useVideoEditorTheme } from '@/areas/editor/pages/config/theme'
import settings from '@/data/settings'
import { Button } from '@/components/ui/button'
import DiscordLogo from '@/components/Icons/DiscordLogo.vue'
import IconSaxRefresh2 from '@/components/Icons/iconsax/IconSaxRefresh2.vue'
import IconSaxExportCurve from '@/components/Icons/iconsax/IconSaxExportCurve.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import uploadService from '@/services/uploadService'
import axios from 'axios'
import { useToast } from '@/Hooks/useToast'
import toastEvents from '@/events/toastEvents'
import { tiers } from '@/enums/tiers'
import { v4 as uuid } from 'uuid'
import { supabase } from '@/authentication/supabase'
import { preflight } from '../api/exportApi'
import { type Feature, findFeature } from '@/data/features'
import unwrap from '@/helpers/unwrap'
import merge from 'lodash-es/merge'
import { getClipSlug } from '../../startup/repairTwitchClip'
import logging from '@/logging'
import type { EditorProject } from '@/areas/editor/@type/editor-project'
import { getSession } from '@/authentication/supabase'
import { requestUserSignInAsync } from '@/authentication/supabase'

const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
const isChrome = /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor);


const serverSideWebhook = 'https://auth.streamladder.com/functions/v1/render-webhook';


function random<T>(array: T[]): T {
  return array[Math.floor(Math.random() * array.length)];
}

const feature = random(features);
const route = useRoute();
const projectId = route.params.projectId as string;


const videoHasErrors = ref(false);
const errorMessage = ref('');
const showRetryButton = ref(false);
const fixToolDialogOpen = ref(false);
const renderingServerSide = ref(false);
const { showToast } = useToast();
const router = useRouter();

const progress = ref(-1);
let videoDuration = 0;

const iframe = ref<HTMLIFrameElement | null>(null);
const previewIframe = ref<HTMLIFrameElement | null>(null);
const iframeMessageBus = new IframeMessageBus(iframe, 'renderer', () => start())

const renderFinished = ref(false)

const previewMessageBus = new IframeMessageBus(previewIframe, 'preview', () => startPreview())

const featureVideoLoaded = ref(false)

const { suspense, data: instructions } = useQuery({
  queryKey: ['render-instructions', projectId],
  queryFn: () => fetchProjectJson(projectId),
});

const theme = useVideoEditorTheme();
const hasStarted = ref(false);

const isOnRenderPage = ref(true);

onMounted(() => {
  isOnRenderPage.value = true;
})

onUnmounted(() => {
  if (!renderFinished.value && !renderingServerSide.value) {
    supabase.functions.invoke('render-webhook', {
      body: {
        renderReferenceId,
        status: 'cancelled',
        message: 'Cancelled rendering',
      },
    })
  }

  isOnRenderPage.value = false;
  iframeMessageBus.destroy();
  previewMessageBus.destroy();
})

const error = ref<string | null>(null);


const renderReferenceId = uuid();

async function start() {

  let isLoggedIn = !!(await onUserInfoReadyAsync());

  if (!isLoggedIn) {
    isLoggedIn = await requestUserSignInAsync('Please sign in to export your video');
  }

  if (!isLoggedIn) {
    showToast({
      type: toastEvents.TOAST_ERROR,
      title: 'You need to be signed in to export 🧐',
      subtitle: 'If you are signed in, but you see this message, please refresh the page or contact support!',
      view: () => {
        router.push({
          name: dashboardRouteNames.support,
        })
      },
      viewTitle: 'Support',
    });
    return;
  }


  const [response, user] = await Promise.all([suspense(), onUserInfoReadyAsync()]);
  let editorProject: EditorProject = JSON.parse(JSON.stringify(response.data));

  editorProject = await upgradeProjectToEditorProject(editorProject);
  if (editorProject.sources?.some((s: { url: string }) => s.url?.includes('twitch-clips-v2.b-cdn.net') && getClipSlug(s.url))) {
    editorProject = await replaceBrokenTwitchUrlsIfNeeded(editorProject);
  }
  if (editorProject.segments.some((s) => s.sourceUrl?.includes('twitch-clips-v2.b-cdn.net') && getClipSlug(s.sourceUrl))) {
    editorProject = await replaceBrokenTwitchSourceUrlsIfNeeded(editorProject);
  }

  const preflightResult = await preflight({ project: editorProject });

  const isUsedFeature = (feature: Feature) => preflightResult.usages[feature] > 0;
  const userCannotUseFeature = (feature: Feature) => (findFeature(feature)?.tier ?? 0) > preflightResult.tier;

  const features = unwrap.keys(preflightResult.usages)
    .filter(isUsedFeature)
    .filter(userCannotUseFeature);

  // Ensure watermark is added if the user manually adds `/export` to the URL to bypass the watermark check.
  if (features.length > 0) {
    const projectWithWatermark = merge(editorProject, { watermarkUrl: 'https://app.streamladder.com/images/watermark.png' });
    editorProject = projectWithWatermark;
  }

  const renderServerSide = !!route.query.fallback || isMobile || !isChrome || user.tier === tiers.GOLD;

  renderingServerSide.value = renderServerSide;

  videoDuration = editorProject.durationMs ?? 0;
  const { data, error } = await supabase.functions.invoke('render-webhook', {
    body: {
      userId: user.userId,
      renderReferenceId,
      ProjectId: editorProject.id,
      renderServerSide,
    },
  })

  console.log(data, error);

  const session = await getSession();
  const authToken = session.data.session?.access_token;

  iframeMessageBus.emit('EXPORT_VIDEO', {
    editorProject,
    renderServerSide,
    authToken: authToken,
    serverSideWebhook,
    baseUrl: window.location.origin,
    renderReferenceId: renderReferenceId,
    project: 'streamladder',


    // can be removed later, is used to make the old function still work
    userId: user.userId,
  });
  hasStarted.value = true;
}

iframeMessageBus.on('VIDEO_PREVIEW_EXPORT_PROGRESS', (payload: { progress: number, serverSide?: boolean }) => {
  progress.value = Math.round(payload.progress * 100);
  renderingServerSide.value = !!payload.serverSide;


  if (videoDuration) {
    if (progress.value < 100) {
      previewMessageBus?.emit('SEEK_VIDEO', { timeMs: payload.progress * videoDuration });
    }
    else {
      previewMessageBus?.emit('SEEK_VIDEO', { timeMs: videoDuration });
      previewMessageBus?.emit('PAUSE_VIDEO', {});
    }
  }
});

const uploadVideo = async (blob: Blob, renderFps?: string, renderSpeed?: string, fileName?: string, videoTitle?: string) => {
  const user = await onUserInfoReadyAsync();
  const cancelSource = axios.CancelToken.source()

  // Start uploading the file.
  const result = await uploadService.getUploadResultSignedUrl()
  const res = await uploadService.uploadFileS3(
    result.signedUrl,
    blob,
    () => {
      // console.log(p);
    },
    'video/mp4',
    `streamladder-${fileName}.mp4`,
    { cancelToken: cancelSource.token }
  )

  console.log('RESULT URL:', result.resultUrl);

  const { data, error } = await supabase.functions.invoke('render-webhook', {
    body: {
      userId: user.userId,
      renderReferenceId,
      resultUrl: result.resultUrl,
      status: 'finished',
      message: 'Finished rendering',
      title: videoTitle,
    },
  })

  console.log(data, error);

  if (res && res.status === 200) {


    renderingProgressMessage.value = 'Redirecting..'

    console.log({ renderFps, renderSpeed})

    const redirectToResultPage = async () => {
      await onUserInfoReadyAsync();
      if (user.tier === tiers.FREE) {
        await router.replace({ name: dashboardRouteNames.editorResult, query: { resultId: renderReferenceId } })
      } else {
        await router.replace({ name: dashboardRouteNames.contentPublisher.video, params: { videoId: renderReferenceId }, query: { isRedirectedFromRenderPage: 'true' } })
      }
    }

    if (isOnRenderPage.value) {
      await redirectToResultPage()
    } else {
      showToast({
        type: toastEvents.TOAST_SUCCESS,
        title: 'Video finished rendering!',
        subtitle: `"${videoTitle}" has finished rendering.`,
        timeout: 10000,
        view: () => redirectToResultPage(),
        viewTitle: 'View video',
      });
    }

  } else {
    Sentry.captureException(new Error('Failed to upload video.'))
    videoHasErrors.value = true
    console.error({
      message: 'Failed to upload video',
      response: res,
    })
  }

  if (videoHasErrors.value) {
    Sentry.captureException(new Error('Error rendering video.'))
  }
}

iframeMessageBus.on('EXPORT_VIDEO_FINISHED', async (payload: { videoUrl?: string, buffer?: ArrayBuffer }) => {

  renderFinished.value = true;
  if (payload.buffer) {
    try {
      const project = await suspense();
      await uploadVideo(new Blob([payload.buffer]), '0', '0',  project.data?.title, project.data?.title);
    } catch(e) {
      console.error(e);
      // blocked by S3 probably...
      const path = route.path;
      router.replace({ path, query: { fallback: 'true' } });

    }
  } else if (payload.videoUrl) {
    // video has been serverside renderered
    const user = await onUserInfoReadyAsync();
    if (user.tier === tiers.FREE) {
      await router.replace({ name: dashboardRouteNames.editorResult, query: { resultId: renderReferenceId } })
    } else {
      await router.replace({ name: dashboardRouteNames.contentPublisher.video, params: { videoId: renderReferenceId }, query: { isRedirectedFromRenderPage: 'true' } })
    }

  }
  else {
    console.error('No output video buffer or videoUrl');
  }
});

iframeMessageBus.on('EXPORT_VIDEO_FAILED', (payload: { error: string }) => {
  renderFinished.value = true;
  // errorMessage.value = payload.error;
  errorMessage.value = 'Error exporting video.'
  videoHasErrors.value = true;
  error.value = payload.error;
});

const renderingProgressMessage = ref<string | null>(null);
const downloadProgressMessage = ref<string | null>(null);

async function attemptRender() {
  console.log('attemptRender');
}

async function startPreview() {

  const [response, user] = await Promise.all([suspense(), onUserInfoReadyAsync()]);
  let editorProject = JSON.parse(JSON.stringify(response.data));
  editorProject = await upgradeProjectToEditorProject(editorProject);
  if (editorProject.sources?.some((s: { url: string }) => s.url?.includes('twitch-clips-v2.b-cdn.net') && getClipSlug(s.url))) {
    editorProject = await replaceBrokenTwitchUrlsIfNeeded(editorProject);
  }
  if (editorProject.segments.some((s) => s.sourceUrl?.includes('twitch-clips-v2.b-cdn.net') && getClipSlug(s.sourceUrl))) {
    editorProject = await replaceBrokenTwitchSourceUrlsIfNeeded(editorProject);
  }

  const config = {
    appId: 'b70d6ef4-7c85-44ef-adf5-41107d032417',
    baseUrl: window.location.origin,
    editorProject: editorProject,
    theme: theme.value,
    user: { tier: user.tier }
  };

  previewMessageBus.emit('MOUNT_VIDEO_PREVIEW', { config: config });
}

useHead({
  title: computed(() => {

    if (downloadProgressMessage.value) {
      return 'Collecting all the pixels for your video!';
    }

    if (renderingProgressMessage.value) {
      return 'Rendering your video at lightning speed!';
    }

    return 'Exporting your video!'
  }),
})

const randomGradient = random([
  'bg-gradient-to-br from-red-400 to-purple-500', 'bg-gradient-to-br from-red-400 to-amber-400',
  'bg-gradient-to-br from-blue-600 to-emerald-300', 'bg-gradient-to-br from-violet-300 to-blue-700',
  'bg-gradient-to-br from-cyan-300 to-purple-600', 'bg-gradient-to-br from-emerald-700 to-lime-300',
  'bg-gradient-to-br from-pink-400 to-yellow-400'
]);
</script>

<template>
  <article :class="cn('layer-0 grid h-full min-h-screen grid-cols-10 text-pretty', randomGradient)">
    <div class="col-span-10 h-full bg-white p-16 md:p-8 lg:col-span-4 xl:col-span-3 2xl:px-20 2xl:pb-20">
      <div class="flex h-full flex-col justify-center gap-8">
        <template v-if="!error">
          <div class="flex w-full items-center justify-center">
            <div class="w-3/5 md:w-1/3 lg:w-3/5">
              <lottie-animation url="/lottie/race-car.json" :loop="true" :autoPlay="true" />
            </div>
          </div>

          <div class="flex w-full flex-col text-center">
            <template v-if="renderingProgressMessage">
              <h1 class="text-2xl font-bold">
                <template v-if="renderingProgressMessage === 'Uploading..'"> Almost there.. </template>
                <template v-else-if="renderingProgressMessage === 'Redirecting..'"> Redirecting.. </template>
                <template v-else> Rendering your video </template>
              </h1>
              <p class="font-light text-muted-foreground">
                <template v-if="renderingProgressMessage === 'Uploading..'">
                  Your video is being uploaded to the cloud!
                </template>
                <template v-else-if="renderingProgressMessage === 'Redirecting..'">
                  Finishing up your awesome video!
                </template>
                <template v-else> Your video is being rendered. This might take a while. </template>
              </p>
            </template>
            <template v-else>
              <h1 class="text-2xl font-bold">Rendering your video</h1>
              <p v-if="downloadProgressMessage" class="font-light text-muted-foreground">
                We're preparing your render {{ downloadProgressMessage }} ..
              </p>
              <p v-else class="font-light text-muted-foreground">We'll start your awesome video as soon as possible.</p>
            </template>
          </div>

          <div class="relative flex flex-col items-center justify-center">
            <div
              class="relative grid aspect-[9/16] max-h-[420px] overflow-hidden rounded-xl border-2 border-zinc-1000 bg-zinc-1000"
            >
              <iframe
                ref="iframe"
                :class="
                  cn('col-start-1 row-start-1 size-full overflow-hidden', {
                    hidden: !instructions,
                    'opacity-0': !hasStarted,
                  })
                "
                crossorigin="anonymous"
              />

              <iframe
                ref="previewIframe"
                :class="
                  cn('pointer-events-none absolute left-0 top-0 col-start-1 row-start-1 size-full overflow-hidden', {
                    hidden: !instructions,
                  })
                "
                crossorigin="anonymous"
                v-if="renderingServerSide"
              />

              <div
                class="absolute left-1/2 top-1/2 w-max -translate-x-1/2 -translate-y-1/2 transform rounded-full bg-twitch-50 pb-1 pl-2 pr-2 pt-1"
              >
                {{ progress === -1 ? 'Queued...' : progress < 100 ? `${progress}%` : `Uploading video...` }}
              </div>
            </div>
            <div
              v-if="renderingProgressMessage"
              class="opacity absolute flex items-center justify-center rounded-lg bg-slate-200 p-2 text-center font-light text-black"
            >
              {{ renderingProgressMessage }}
            </div>
          </div>

          <div class="flex w-full flex-col items-center justify-center">
            <p
              class="flex w-full max-w-[300px] flex-col items-center justify-center gap-2 rounded-lg border border-blue-200 bg-blue-50 px-16 pb-3 pt-4 text-center font-semibold"
            >
              Got any questions or feedback?
              <Button as="a" :href="settings.discordInviteUrl" variant="discord" size="sm">
                <DiscordLogo class="h-3 w-3 fill-current" />
                <p class="text-sm">Join our Discord</p>
              </Button>
            </p>
          </div>
        </template>

        <template v-else-if="videoHasErrors">
          <div class="flex w-full items-center justify-center">
            <div class="w-3/5 md:w-1/3 lg:w-3/5">
              <lottie-animation url="./lottie/race-car-crash.json" :loop="true" :autoPlay="true" />
            </div>
          </div>

          <div class="flex w-full flex-col text-center">
            <h1 class="text-2xl font-bold">
              {{ errorMessage }}
            </h1>
            <p class="font-light text-muted-foreground">
              Our new rendering engine has crashed and burned while rendering your video.
            </p>
          </div>

          <p
            class="flex w-full flex-col items-center justify-center gap-2 rounded-lg bg-zinc-400 px-4 pb-3 pt-4 text-center text-sm font-light"
          >
            <LottieAnimation url="/lottie/error.json" class="h-10 w-10" />
            <template v-if="showRetryButton"> Please try again or contact support. </template>
            <template v-else> Please contact support or create a new project. </template>
            <RouterLink v-if="!showRetryButton" :to="{ name: 'editor' }" class="flex">
              <Button size="sm" @click="attemptRender">
                <IconSaxRefresh2 class="h-3 w-3" />
                Create new project
              </Button>
            </RouterLink>
            <Button v-if="showRetryButton" size="sm" @click="attemptRender">
              <IconSaxRefresh2 class="h-3 w-3" />
              Try again
            </Button>
            <Button as="a" :href="settings.discordInviteUrl" variant="discord" size="sm">
              <DiscordLogo class="h-3 w-3 fill-current" />
              <p class="text-sm">Join our Discord</p>
            </Button>
            <Button
              v-if="showRetryButton"
              size="sm"
              class="font-light"
              variant="link"
              @click="fixToolDialogOpen = true"
            >
              <IconSaxExportCurve class="h-3 w-3" />
              Use Video Repair Tool
            </Button>
          </p>
        </template>

        <ConvertInBackgroundAlert />
      </div>
    </div>
    <div class="col-span-10 p-6 md:p-12 lg:col-span-6 xl:col-span-7 2xl:p-36">
      <div class="flex h-full flex-col items-center justify-center gap-16">
        <header class="flex flex-col items-center justify-start gap-6">
          <div
            class="rounded-full bg-opacity-20 bg-gradient-to-br from-stone-600/20 via-stone-500/20 to-stone-600/20 px-6 py-3"
          >
            <span class="text-lg font-medium leading-none text-white">Feature spotlight</span>
          </div>
          <h1 class="text-center text-6xl font-bold !text-white">{{ feature.title }}</h1>
          <p class="text-balance text-center text-xl font-normal leading-snug text-white">
            {{ feature.subtitle }}
          </p>
          <a
            v-if="'href' in feature"
            :href="feature.href"
            class="btn btn-outline btn-accent btn-xl uppercase"
            target="_blank"
          >
            Discover more
          </a>
        </header>

        <div class="grid h-full max-h-[35vh] w-full max-w-[calc(35vh*calc(16/9))] place-items-center">
          <div
            :style="{ paddingBottom: (feature.video.height / feature.video.width) * 100 + '%' }"
            class="relative h-0 w-full"
          >
            <Skeleton
              :class="[feature.video.class, { 'opacity-0': featureVideoLoaded }]"
              class="absolute inset-0 h-full w-full rounded-lg transition-opacity"
            />
            <video
              :class="{ 'opacity-0': !featureVideoLoaded }"
              :height="feature.video.height"
              :src="'/' + feature.video.src"
              :width="feature.video.width"
              autoplay
              class="absolute inset-0 max-w-full rounded-lg shadow-2xl transition-opacity"
              disablePictureInPicture
              disableRemotePlayback
              loop
              muted
              playsinline
              @canplay.once="featureVideoLoaded = true"
            />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style lang="scss" scoped></style>
