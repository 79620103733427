<script lang="ts" setup>
import WithUpgradeOverlay from '@/areas/dashboard/components/WithUpgradeOverlay.vue'
import MontageMakerVideosList from '@/areas/dashboard/components/MontageMaker/MontageMakerVideosList.vue'
import { computed, onBeforeUnmount, onMounted, ref, watch } from 'vue'
import GradientButton from '@/components-v2/data-display/GradientButton.vue'
import IconSaxArrowRight from '@/components/Icons/iconsax/IconSaxArrowRight.vue'
import LottieAnimation from '@/components/LottieAnimation.vue'
import EventBus from '@/eventBus'
import mainEvents from '@/events/mainEvents'
import { type MontageDto, type VideoResultDto } from '@/apis/streamladder-api/model'
import IconSaxArrowLeft from '@/components/Icons/iconsax/IconSaxArrowLeft.vue'
import Pusher from 'pusher-js'
import { dashboardRouteNames } from '@/areas/dashboard/routeNames'
import { useRoute } from 'vue-router'
import { getApiMontagesId } from '@/apis/streamladder-api/montages/montages'
import IconSaxInfoCircle from '@/components/Icons/iconsax/IconSaxInfoCircle.vue'
import { postApiVideos, useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import settings from '@/data/settings'
import { Button } from '@/components/ui/button'
import { VideoWorkerManager } from '@/webcodec-renderer/VideoWorkerManager'
import { runWebcodecTest } from '@/webcodec-renderer/worker/webcodec-test'
import { createMontageFFmpegWasm } from '@/webcodec-renderer/create-montage-ffmpeg'
import axios from 'axios'
import uploadService from '@/services/uploadService'
import * as Sentry from '@sentry/browser'
import { posthog } from 'posthog-js'
import UAParser from 'ua-parser-js'
import logging from '@/logging'

const route = useRoute()
const currentRouteTask = computed(() => route.query?.task || null)

watch(currentRouteTask, async (currentRouteTask) => {
  if (currentRouteTask) {
    await showProgressOnWatchOrMount(currentRouteTask as string)
  }
})

onMounted(async () => {
  if (currentRouteTask.value) {
    await showProgressOnWatchOrMount(currentRouteTask.value as string)
  }
})

const showProgressOnWatchOrMount = async (currentRouteTask: string) => {
  progressPercentage.value = 0
  activeProgressTextIndex.value = 0
  removePusherListeners()
  showProgressOfMontage.value = currentRouteTask as string

  const montage = (await getApiMontagesId(route.query?.task as string)) as MontageDto

  if (montage.status === 'success') {
    progressPercentage.value = 100
  } else if (montage.status === 'processing') {
    bindProgressFromPusher(currentRouteTask as string)
  } else if (montage.status === 'failed') {
    returnToOverview()
    EventBus.$emit(
      mainEvents.ERROR,
      `We couldn't create your montage with ID:<pre class="py-1 px-2 rounded bg-zinc-200 my-2 select-all text-sm">${montage.id}</pre> Please contact our <a href="${settings.discordInviteUrl}" target="_blank" class="link text-indigo-500">support</a>!`
    )
  }
}

const showProgressOfMontage = ref<MontageDto['id']>(null)
const selectedVideoUrls = ref<string[]>([])

const isAwaitingResponseFromNewMontageEndpoint = ref(false)

const progressPercentage = ref(0)
const pusherClient = ref<Pusher | null>(null)

const uploadProgress = ref(0);

const progressionTexts = ref<string[]>([
  "Hold tight while we’re creating your masterpiece! The duration may vary based on the number of clips you've included in your montage.",
  'Our hamster is warming up! Your montage is in the making.',
  'Taking it step by step! Your montage is gradually coming together.',
  'Progress is picking up! Your montage masterpiece is evolving nicely.',
  'Getting closer! Your montage is shaping up!',
  'Our hamster is running as fast as he can! Your montage is almost done!',
  "Just a little longer - we're adding the final touches to your montage gem!",
])

const activeProgressTextIndex = ref(0)

const removeClipFromMontage = (url: string) => {
  selectedVideoUrls.value = selectedVideoUrls.value.filter((v) => v !== url)
}

const addClipToMontage = (url: string) => {
  selectedVideoUrls.value = [...selectedVideoUrls.value, url]
}


const uploadVideo = async (file: Blob): Promise<null | string> => {

  // Creating a cancellation token
  const cancelSource = axios.CancelToken.source()

  try {

    // Start uploading the file.
    const result = await uploadService.getUploadResultSignedUrl()

    const response = await uploadService.uploadFileS3(
      result.signedUrl,
      file,
      (p) => (uploadProgress.value = p),
      'video/mp4',
      '',
      { cancelToken: cancelSource.token }
    );

    if (response.status !== 200) {
      console.error('Error uploading video');
      EventBus.$emit(mainEvents.ERROR, "Error uploading your montage. Please try again later.");
      return null;
    }


    uploadProgress.value = 100;

    await postApiVideos({
        videoUrl: result.resultUrl,
        title: 'StreamLadder montage',
      });



      logging.trackEvent('Montage uploaded', { videoUrl: result.resultUrl })
      
      return result.resultUrl;

  } catch (e) {
    console.error('Error uploading video');
    EventBus.$emit(mainEvents.ERROR, "Error uploading your montage. Please try again later.");
    Sentry.captureException(e);
    return null;
  }
}

const startNewMontage = async (forceFfmpeg = false) => {
  showProgressOfMontage.value = 'montage-clientside'
  const webcodecTestResults = await runWebcodecTest();

  const parser = new UAParser();
  const result = parser.getResult();


  console.log(selectedVideoUrls.value.map(str => str));
  const version = result.browser.version ? parseInt(result.browser.version.split('.')[0]) : 0;
  if (!forceFfmpeg && (webcodecTestResults.hardwareAccelerationWorking || webcodecTestResults.softwareEncoderWorking) && result.browser.name === 'Chrome' && version >= 130) {
    const workerManager = new VideoWorkerManager({
        id: "montage",
        width: 0,
        height: 0,
        onProgress: (progress) => {
          progressPercentage.value = Number(progress.replace('%', ''))
        },
        onDownloadFinishedRaw: async (arrayBuffer) => {
          progressPercentage.value = 100;
          console.log('OUTPUT FILE SIZE: ' + (arrayBuffer.byteLength / 1e6) + 'mb')

          await uploadVideo(new Blob([arrayBuffer]))
        },
        onError: (error) => {
          startNewMontage(true);
          console.error(error);
          Sentry.captureException(new Error(error));
        }
    });

    workerManager.createMontage(selectedVideoUrls.value.map(str => str), !!webcodecTestResults.hardwareAccelerationWorking);
  } 
  else {
    const blob = await createMontageFFmpegWasm(selectedVideoUrls.value.map(str => str), (progress) => {
      progressPercentage.value = Math.min(100, Math.round(progress * 100));
    });

    progressPercentage.value = 100;

    uploadVideo(blob);
  }
}

const returnToOverview = () => {
  isAwaitingResponseFromNewMontageEndpoint.value = false
  showProgressOfMontage.value = null
  selectedVideoUrls.value = []

  removePusherListeners()
  progressPercentage.value = 0
  activeProgressTextIndex.value = 0
}

const bindProgressFromPusher = (montageId: string) => {
  if (pusherClient.value) {
    removePusherListeners()
  }

  pusherClient.value = new Pusher('ef0a10b651ed4adf46eb', {
    cluster: 'us3',
  })

  const channelName = `montage_${montageId}`
  const channel = pusherClient.value.subscribe(channelName)

  channel.bind('progress', async (data: { videoRender: { percentage: number } }) => {
    progressPercentage.value = Math.max(progressPercentage.value, data?.videoRender?.percentage || 0)

    activeProgressTextIndex.value = Math.min(
      Math.floor(progressPercentage.value / (100 / progressionTexts.value.length)),
      progressionTexts.value.length - 1
    )
  })

  channel.bind('error', () => {
    removePusherListeners()
    returnToOverview()
    EventBus.$emit(mainEvents.ERROR, "We couldn't create your montage. Please contact our support!")
  })
}

const removePusherListeners = () => {
  if (pusherClient.value) {
    pusherClient.value.disconnect()
    pusherClient.value = null
  }
}

onBeforeUnmount(() => {
  removePusherListeners()
})

const { data, isLoading: isLoadingVideos, dataUpdatedAt } = useGetApiVideos({ fromDays: 30 })
const videos = computed(() => (data.value ?? []) as unknown as VideoResultDto[])

const hideCreateMontageButton = computed(() => {
  return videos.value.length < 2
})
</script>

<template>
  <WithUpgradeOverlay
    class="max-h-[calc(100vh-3.1rem)] overflow-hidden rounded-2xl"
    feature="montage-maker"
    subtitle="Combine your favorite video clips into a montage"
    title="Montage Maker"
  >
    <main
      v-if="!showProgressOfMontage && !isAwaitingResponseFromNewMontageEndpoint"
      class="min-h-32 flex flex-col gap-4 p-4 lg:p-12"
    >
      <div class="flex flex-row flex-wrap justify-between gap-2">
        <header class="flex w-full flex-col gap-2 lg:w-auto">
          <div>
            <h1 class="text-2xl lg:text-3xl">Select Your Clips</h1>
            <p class="font-light text-opacity-20">
              Ready to roll? Select 2 to 10 clips in sequential order. Let's get started!
            </p>
          </div>
          <Button
            v-if="!hideCreateMontageButton"
            variant="gradient"
            size="lg"
            :disabled="selectedVideoUrls.length < 2"
            @click="startNewMontage()"
            class="mt-2 block w-full transition-all lg:hidden"
          >
            <span>Create Montage</span>
            <IconSaxArrowRight class="h-6 w-6" />
          </Button>
        </header>
        <div class="hidden flex-row justify-center gap-2 lg:flex">
          <Button
            v-if="!hideCreateMontageButton"
            variant="gradient"
            size="lg"
            :disabled="selectedVideoUrls.length < 2"
            @click="startNewMontage()"
            class="right-0 top-0 mt-0 flex w-[250px] transition-all"
          >
            <span>Create Montage</span>
            <IconSaxArrowRight class="h-6 w-6" />
          </Button>
        </div>
      </div>

      <MontageMakerVideosList
        @removeFromMontage="removeClipFromMontage"
        @addToMontage="addClipToMontage"
        :filteredVideos="videos"
        :selectedVideoUrls="selectedVideoUrls"
        :dataUpdatedAt="dataUpdatedAt"
        :isLoadingVideos="isLoadingVideos"
      />
    </main>

    <RouterLink v-if="showProgressOfMontage" :to="{ name: dashboardRouteNames.montageMaker }">
      <div
        class="absolute m-4 flex cursor-pointer items-center gap-2 p-2 transition-all hover:text-gray-400"
        @click="returnToOverview"
      >
        <IconSaxArrowLeft class="h-4 w-4" />
        <p class="text-sm font-extralight">Go back</p>
      </div>
    </RouterLink>

    <Transition name="bounce" key="progress">
      <main
        v-if="showProgressOfMontage || isAwaitingResponseFromNewMontageEndpoint"
        class="h-full"
        id="montage-maker-survey"
      >
        <div class="flex h-full flex-col items-center justify-center gap-4 p-4 transition-all lg:p-12">
          <span class="mb-10 flex items-center justify-center" v-if="progressPercentage !== 100 || uploadProgress !== 100">
            <span v-if="progressPercentage" class="absolute mt-1">{{ progressPercentage + '%' }}</span>
            <lottie-animation class="w-36" url="/lottie/montage-maker-loader.json" :auto-play="true" :loop="true" />
          </span>

          <div v-if="progressPercentage !== 100 || uploadProgress !== 100">
            <h1 class="cursor-default text-center text-6xl font-bold">
              <span class="gradient-top">Creating your</span> <br />
              <span class="montage-color">Montage</span>
            </h1>
            <p class="mt-4 max-w-xl cursor-default text-center font-extralight text-gray-400">
              <span v-if="progressPercentage === 99">Hang tight!</span>
              <span v-else-if="uploadProgress > 0 && uploadProgress < 100">Uploading video..</span> 
              <span v-else>{{ progressionTexts[activeProgressTextIndex] }}</span>
            </p>
          </div>
          <div v-else>
            <span class="mb-8 flex items-center justify-center">
              <lottie-animation class="w-36" url="/lottie/success-montage.json" :auto-play="true" :loop="false" />
            </span>
            <h1 class="mb-3 cursor-default text-center text-5xl font-bold">
              <span class="gradient-top mb-3 text-center">Your Montage is Ready!</span>
            </h1>
            <p class="mb-8 cursor-default text-center font-extralight text-gray-400">
              Your masterpiece has been created and added to your videos.
            </p>
            <RouterLink :to="{ name: dashboardRouteNames.videos }" class="flex w-full justify-center rounded-lg">
              <GradientButton
                class="gap-4 rounded-lg !px-4 !font-semibold tracking-wide transition-all hover:overflow-visible"
              >
                <span>Go to My Videos</span>
              </GradientButton>
            </RouterLink>
          </div>
        </div>
      </main>
    </Transition>
  </WithUpgradeOverlay>
</template>

<style lang="scss" scoped>
.gradient-top {
  background: linear-gradient(295.67deg, #e37c74 29.3%, #ef57ef 55.59%, #49bcbd 92.74%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.montage-color {
  color: #dbd0ff;
}

.bounce-enter-active {
  animation: bounce-in 0.75s;
}

.bounce-leave-active {
  animation: none;
}

@keyframes bounce-in {
  0% {
    opacity: 0;
    transform: scale(0.75);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
