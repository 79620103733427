<script setup lang="ts">
import type { VideoResultDto } from '@/apis/streamladder-api/model'
import { useGetApiVideos } from '@/apis/streamladder-api/videos/videos'
import NoContentClipsSection from '@/areas/dashboard/components/NoContentClipsSection.vue'
import SelectDropdown from '@/components-v2/data-input/SelectDropdown.vue'
import Clip from '@/components/Scheduler/Clips/Clip.vue'
import { Button } from '@/components/ui/button'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/dialog'
import { useFilterVideos, videosFilter, videosFilterOptions } from '@/Hooks/useFilterVideos'
import { useUserInfoStore } from '@/store/user/userInfo'
import { computed } from 'vue'

const open = defineModel<boolean>({ required: true });

const emit = defineEmits<{
  (event: 'clipSelected', clip: VideoResultDto): void,
}>();

const userInfo = useUserInfoStore()
const isAuthenticated = computed(() => userInfo.isAuthenticated)
const { isLoading: isLoadingVideos, data } = useGetApiVideos({ fromDays: 30 }, {
  query: {
    enabled: isAuthenticated,
  },
})

const allVideos = computed<VideoResultDto[]>(() => data.value ?? [])

const { filteredVideos: userClips, isLoadingPosts } = useFilterVideos(computed<VideoResultDto[]>(() => data.value ?? []))

const isLoading = computed(() => isLoadingPosts.value || isLoadingVideos.value);
</script>

<template>
  <Dialog v-model:open="open">
    <DialogContent class="max-w-5xl">
      <DialogHeader>
        <DialogTitle>Choose which video you want to post</DialogTitle>
        <DialogDescription>Select a video to start scheduling</DialogDescription>
      </DialogHeader>
      <hr class="mb-1" />
      <div class="flex w-full flex-col lg:flex-row">
        <div class="flex w-full flex-col">
          <div class="main-content flex flex-1 flex-col">
            <SelectDropdown :options="videosFilterOptions" v-model="videosFilter" class="self-end" align="end" />
            <div class="grid w-full grid-cols-2 gap-4 py-4 md:grid-cols-3 lg:grid-cols-5">
              <template v-if="isLoading">
                <div v-for="i in 6" :key="i"
                  class="min-h-32 flex aspect-[9/16] w-full animate-pulse select-none flex-col items-center justify-center rounded-xl bg-gray-300">
                  &nbsp;
                </div>
              </template>
              <template v-else-if="userClips.length > 0">
                <div v-for="clip in userClips" :key="clip.id">
                  <Clip :clip="clip" @clipClicked="(e) => emit('clipSelected', e)" />
                </div>
              </template>
            </div>
            <NoContentClipsSection v-if="userClips.length === 0" :totalAmountOfVideos="allVideos.length"
              :hideFileUpload="true" :hideScheduleButton="true" />
          </div>
        </div>
      </div>
      <DialogFooter>
        <DialogClose as-child>
          <Button variant="outline">
            Cancel
          </Button>
        </DialogClose>
      </DialogFooter>
    </DialogContent>
  </Dialog>
</template>

<style scoped></style>
