import { posthog } from 'posthog-js';
import { type Ref, reactive, computed } from 'vue';

export const FEATURE_FLAGS = {
  'multiple-layouts-card': {
    variants: ['control', 'split-clip', 'add-layout'],
  },
  'watermark-export-for-silver': {
    variants: ['control', 'show-watermark'],
  },
  'max-5-premium-exports': {
    variants: ['control', 'premium'],
  },
  'with-and-without-gold-cards': {
    variants: ['control', 'cards'],
  },
  'automatically-add-captions-at-startup': {
    variants: ['control', 'add-captions']
  },
  'clip-downloader-login-page': {
    variants: ['control', 'clip-downloader']
  },
} as const satisfies Record<string, { variants: Readonly<['control', ...string[]]> }>

const featureFlags = reactive<Record<string, boolean | string | undefined>>({})

const featureFlagsPromise = new Promise(resolve => posthog.onFeatureFlags(resolve));

export function useFeatureFlagEnabled(key: string): Ref<boolean | string | undefined> {

  const exists = key in featureFlags
  if (!exists) {
    // Settings the key to 'undefined' to avoid re-fetching the feature flag.
    featureFlags[key] = undefined;
    featureFlagsPromise.then(() => {
      featureFlags[key] = posthog.getFeatureFlag(key);
    })
  }

  return computed({
    get() {
      return featureFlags[key]
    },
    set(value) {
      featureFlags[key] = value
    }
  })
}

export function useAsyncFeatureFlagEnabled(key: string) {
  const flag = useFeatureFlagEnabled(key);
  return {
    isLoading: computed(() => flag.value === undefined || flag.value === null),
    flag: flag,
  }
}

export function useFeatureFlagVariantEnabled<TKey extends keyof typeof FEATURE_FLAGS>(
  key: TKey,
  variant: (typeof FEATURE_FLAGS)[TKey]['variants'][number]
): Ref<boolean | string | undefined> {
  const flag = useFeatureFlagEnabled(key)
  return computed(() => flag.value === variant)
}

export function useFeatureFlag<TKey extends keyof typeof FEATURE_FLAGS>(key: TKey) {
  return computed(() => featureFlags[key] as (typeof FEATURE_FLAGS)[TKey]['variants'][number])
}

export function useFeatureFlags() {
  return featureFlags
}

export async function awaitFeatureFlagsPromise()  {
  await featureFlagsPromise;
}